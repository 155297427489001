import React, { Component } from 'react';
import Index from "views/Index.jsx";
// import Profile from "views/examples/Profile.jsx";
import Maps from "views/examples/Maps.jsx";
import Register from "views/Auth/Register.jsx";
import Login from "views/Auth/Login.jsx";
import Tables from "views/examples/Tables.jsx";
import Icons from "views/examples/Icons.jsx";
import Customers from 'views/Customers/Customers.jsx';
import Delivery from "./views/Delivery/Delivery";
import ItemTypes from "./views/ItemTypes/ItemTypes";
import Items from "./views/Items/Items";
import Orders from "./views/Orders/Orders";
import ExpenseTypes from "./views/ExpenseTypes/ExpenseTypes";
import Expenses from "./views/Expenses/Expenses";
import ProductDetail from "./views/Shop/ProductDetail";
import Home from './views/Shop/Home';
import Debts from './views/Debts/Debts';
import ItemColors from './views/ItemColor/ItemColor';
import ItemNicotines from './views/ItemNicotine/ItemNicotine';
import ItemFlavors from './views/ItemFlavor/ItemFlavor';
import ItemBrands from './views/ItemBrand/ItemBrand';
import ItemCapacities from './views/ItemCapacity/ItemCapacity';
import Category from './views/Category/Category';
import Chain from './views/Chain/Chain';
import Post from './views/Post/Post';
import { RankCategory } from './views/Category/RankCategory';
import Folder from './views/Folder/Folder';
import Profile from './views/Shop/Profile';
import FolderPosts from './views/Folder/FolderPosts';
import UserPosts from './views/Shop/UserPosts';
import QuoteCategory from './views/QuoteCategory/Category';
import Quote from './views/Quote/Quote';
import Quotes from './views/Shop/Quotes';
import { RankQuote } from './views/Quote/RankQuote';
import FloatingTitle from './views/FloatingTitle/FloatingTitle';
import TwitterUsers from './views/Shop/TwitterUsers';
import CreatorCategory from './views/CreatorCategory/Category';
import Creator from './views/Creator/Creator';
import Tweet from './views/Tweet/Tweet';
import Tweets from './views/Shop/Tweets';
import Submissions from './views/Shop/Submissions';
import SubmittedPost from './views/Post/SubmittedPost';
import Playlists from './views/Shop/Playlists';
import Faq from './views/Shop/Faq';

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Home,
    layout: "/user",
    roles: []
  },
  {
    path: "/quotes",
    name: "Quotes",
    icon: "ni ni-tv-2 text-primary",
    component: Quotes,
    layout: "/user",
    roles: []
  },
  {
    path: "/tweets",
    name: "Tweets",
    icon: "ni ni-tv-2 text-primary",
    component: Tweets,
    layout: "/user",
    roles: []
  },
  {
    path: "/creators",
    name: "Twitter Users",
    icon: "ni ni-tv-2 text-primary",
    component: TwitterUsers,
    layout: "/user",
    roles: []
  },
  {
    path: "/playlists",
    name: "Playlists",
    icon: "ni ni-tv-2 text-primary",
    component: Playlists,
    layout: "/user",
    roles: []
  },
  {
    path: "/post",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: ProductDetail,
    layout: "/user",
    roles: []
  },
  {
    path: "/twitterUser",
    name: "User",
    icon: "ni ni-tv-2 text-primary",
    component: UserPosts,
    layout: "/user",
    roles: []
  },
  {
    path: "/folder/posts",
    name: "Folder",
    icon: "ni ni-tv-2 text-primary",
    component: FolderPosts,
    layout: "/user",
    roles: []
  },
  {
    path: "/folder",
    name: "Folder",
    icon: "ni ni-tv-2 text-primary",
    component: Folder,
    layout: "/user",
    roles: ["user"]
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-tv-2 text-primary",
    component: Profile,
    layout: "/user",
    roles: ["user"]
  },
  {
    path: "/submission",
    name: "Submission",
    icon: "ni ni-tv-2 text-primary",
    component: Submissions,
    layout: "/user",
    roles: ["user"]
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: "ni ni-tv-2 text-primary",
    component: Faq,
    layout: "/user",
    roles: ["user"]
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    roles: ['admin']
  },
  // {
  //   path: "/customers",
  //   name: "Customers",
  //   icon: "ni ni-single-02 text-blue",
  //   component: Customers,
  //   layout: "/admin",
  //   roles: ['admin']
  // },
  // {
  //   path: "/delivery",
  //   name: "Delivery",
  //   icon: "ni ni-delivery-fast text-orange",
  //   component: Delivery,
  //   layout: "/admin",
  //   roles: ['admin, staff']
  // },
  // {
  //   path: "/items",
  //   name: "Items",
  //   icon: "ni ni-bag-17 text-yellow",
  //   component: Items,
  //   layout: "/admin",
  //   roles: ['admin']
  // },
  // {
  //   path: "/itemtypes",
  //   name: "Item Types",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: ItemTypes,
  //   layout: "/admin",
  //   roles: ['admin']
  // },
  // {
  //   path: "/itembrands",
  //   name: "Item Brands",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: ItemBrands,
  //   layout: "/admin",
  //   roles: ['admin']
  // },
  {
    path: "/categories",
    name: "Post Tag",
    icon: "ni ni-bullet-list-67 text-red",
    component: Category,
    layout: "/admin",
    roles: ['Admin']
  },
  {
    path: "/ranks",
    name: "Rank Tags",
    icon: "ni ni-ui-04 text-red",
    component: RankCategory,
    layout: "/admin",
    roles: ['Admin']
  },
  {
    path: "/posts",
    name: "Post",
    icon: "ni ni-single-copy-04 text-blue",
    component: Post,
    layout: "/admin",
    roles: ['Admin']
  },
  {
    path: "/tweets",
    name: "Post v1.1",
    icon: "ni ni-single-copy-04 text-blue",
    component: Tweet,
    layout: "/admin",
    roles: ['Admin']
  },
  {
    path: "/submits",
    name: "Submissions",
    icon: "ni ni-single-copy-04 text-blue",
    component: SubmittedPost,
    layout: "/admin",
    roles: ['Admin']
  },
  {
    path: "/quoteCategories",
    name: "Quote Category",
    icon: "ni ni-bullet-list-67 text-red",
    component: QuoteCategory,
    layout: "/admin",
    roles: ['Admin']
  },
  {
    path: "/categoryRanks",
    name: "Rank Categories",
    icon: "ni ni-ui-04 text-red",
    component: RankQuote,
    layout: "/admin",
    roles: ['Admin']
  },
  // {
  //   path: "/chains",
  //   name: "Chain",
  //   icon: "ni ni-tag text-red",
  //   component: Chain,
  //   layout: "/admin",
  //   roles: ['Admin']
  // },
  
  {
    path: "/quotes",
    name: "Quote",
    icon: "ni ni-chat-round text-blue",
    component: Quote,
    layout: "/admin",
    roles: ['Admin']
  },
  {
    path: "/creatorCategories",
    name: "Creator Tag",
    icon: "ni ni-bullet-list-67 text-red",
    component: CreatorCategory,
    layout: "/admin",
    roles: ['Admin']
  },
  {
    path: "/creators",
    name: "Creator",
    icon: "ni ni-single-02 text-blue",
    component: Creator,
    layout: "/admin",
    roles: ['Admin']
  },
  {
    path: "/floatingTitles",
    name: "Floating Titles",
    icon: "ni ni-collection text-blue",
    component: FloatingTitle,
    layout: "/admin",
    roles: ['Admin']
  },
  // {
  //   path: "/itemcolors",
  //   name: "Item Colors",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: ItemColors,
  //   layout: "/admin",
  //   roles: ['admin']
  // },
  // {
  //   path: "/itemnicotines",
  //   name: "Item Nicotines",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: ItemNicotines,
  //   layout: "/admin",
  //   roles: ['admin']
  // },
  // {
  //   path: "/itemcapacities",
  //   name: "Item Sizes",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: ItemCapacities,
  //   layout: "/admin",
  //   roles: ['admin']
  // },
  // {
  //   path: "/itemflavors",
  //   name: "Item Flavors",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: ItemFlavors,
  //   layout: "/admin",
  //   roles: ['admin']
  // },
  // {
  //   path: "/orders",
  //   name: "Orders",
  //   icon: "ni ni-basket text-info",
  //   component: Orders,
  //   layout: "/admin",
  //   roles: ['admin', 'staff']
  // },
  // {
  //   path: "/expenses",
  //   name: "Expenses",
  //   icon: "ni ni-money-coins text-pink",
  //   component: Expenses,
  //   layout: "/admin",
  //   roles: ['admin', 'staff']
  // },
  // {
  //   path: "/expensetypes",
  //   name: "Expense Type",
  //   icon: "ni ni-tag text-red",
  //   component: ExpenseTypes,
  //   layout: "/admin",
  //   roles: ['admin', 'staff']
  // },
  // {
  //   path: "/debts",
  //   name: "Debts",
  //   icon: "ni ni-money-coins text-pink",
  //   component: Debts,
  //   layout: "/admin",
  //   roles: ['admin, staff']
  // },
  // {
  //   path: "/debts",
  //   name: "Debts",
  //   icon: "ni ni-planet text-blue",
  //   component: Debts,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin"
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    roles: []
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    roles: []
  }
];
export default routes;
