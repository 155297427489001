import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { getData } from 'services/fetch';
import MaterialTable from "material-table";
import DetailPanel from "../../components/Tables/DetailPanel";
import SimpleModal, { ConfirmModal } from "../../components/Tables/Modal.jsx";
import {
  Redirect,
} from "react-router-dom";
import { defaultModel } from "../../components/DefaultModel";
import { deleteData } from "../../services/fetch";
import { getDateForInput } from "../../services/date";
import { apiurl } from "../../services/common";
import CreatorCategoryForm from "../CreatorCategory/CategoryForm";
import CreatorForm from "./CreatorForm";

class Creator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: null,
      modal: false,
      model: defaultModel.creator,
      action: '',
      redirectToLogin: false,
      disableEditButton: true,
      selectedIds: [],
      sort: 0,
      sortBys: [{ label: 'Tweet Date', value: 0 }, { label: 'List Date', value: 1 }],
      descOrder: true
    };

    this.tableRef = React.createRef();
    this.delete = this.delete.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: false,
      deleteModal: false,
      model: defaultModel.creator
    });
  }

  refreshTable = () => {
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  delete = async () => {
    var response = await deleteData('/api/Category/DeleteManyCategories', this.state.selectedIds);
    if (response.ok) {
      this.toggle();
      this.refreshTable();
    }
  }

  render() {
    const thisprops = this;
    if (this.state.redirectToLogin) return <Redirect to={{
      pathname: "/auth/login",
      state: { from: this.props.location }
    }} />
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--9" fluid>

          <MaterialTable
            title="Creators"
            tableRef={this.tableRef}
            columns={[
              { title: 'Username', field: 'contentCreatorUsername' },
              { title: 'Name', field: 'contentCreatorName' },
              { title: 'Thread Count', field: 'threadCount' },
              { title: 'Quote Count', field: 'quoteCount' },
              { title: 'View Count', field: 'viewCount' },
              { title: 'Like Count', field: 'likeCount' }
              // { title: 'Rank', field: 'rank'}
            ]}
            data={async function (query) {
              if(query.orderBy){
                console.log(query.orderBy.field + " " + query.orderDirection)
                if(query.orderBy.field=='threadCount'){
                  thisprops.state.sort = 0;
                  thisprops.setState({sort: thisprops.state.sort});
                }else if(query.orderBy.field ='quoteCount'){
                  thisprops.state.sort = 1;
                  thisprops.setState({sort: thisprops.state.sort});
                }else{
                  thisprops.state.sort = 2;
                  thisprops.setState({sort: thisprops.state.sort});
                }
                thisprops.state.descOrder = query.orderDirection=='desc'
                thisprops.setState({descOrder: thisprops.state.descOrder})
              }
              let url = apiurl+'/api/ContentCreator/GetContentCreators?'
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              url += '&search=' + query.search
              url += '&sort=' + thisprops.state.sort
              url += '&isDescending=' + thisprops.state.descOrder
              var result = await getData(url);
              if (result.ok) {
                var data = result.data;
                return {
                  data: data.data,
                  page: data.page - 1,
                  totalCount: data.total
                }
              } else if (result.status === 401) {
                thisprops.setState({ redirectToLogin: true });
              } else {
                return {
                  data: [],
                  page: 1,
                  totalCount: 0
                }
              }
            }}
            options={{
              search: true,
              // selection: true,
              debounceInterval: 500,
              actionsColumnIndex : 0,
              pageSize : 20,
              pageSizeOptions : [20,50,100]
            }}
            onSelectionChange={(rows) => {
              this.setState(state => ({
                disableEditButton: rows.length > 1,
                selectedIds: rows.map(row => row.id)
              }));
            }}
            onRowClick={async (evt, data)=>{
              this.setState({ modal: true, model: data, action: 'edit' });
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: this.refreshTable
              },
              // {
              //   tooltip: 'Edit Tag',
              //   icon: 'edit',
              //   // disabled: this.state.disableEditButton,
              //   onClick: async (evt, data) => {
              //     this.setState({ modal: true, model: data, action: 'edit' });
              //   }
              // },
              {
                tooltip: 'Remove Category',
                icon: 'delete',
                iconProps: {color:'error'},
                onClick: (evt, data) => this.setState({ deleteModal: true, selectedIds : [data.id] })
              }
            ]}
          />

          <SimpleModal toggle={this.toggle} modal={this.state.modal} title={'Category'}>
            <CreatorForm
              model={this.state.model}
              action={this.state.action}
              toggle={this.toggle}
              refreshTable={this.refreshTable}
            />
          </SimpleModal>
          <ConfirmModal
            toggle={this.toggle}
            modal={this.state.deleteModal}
            title={'Delete Confirmation'}
            confirmText={'Are you sure you want to delete Tag?'}
            buttonText={'Delete'}
            onConfirm={this.delete}
          />
        </Container>
      </>
    );
  }
}

export default Creator;
