import React, { Component } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { apiurl } from "../../services/common";
import Header from "components/Headers/Header.jsx";
import { Container } from "reactstrap";
import { putData } from "../../services/fetch";

// fake data generator
const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "white" : "lightgrey",
    padding: grid,
    width: 250
});

export class RankQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
        console.log(items);
    }

    componentDidMount = async()=>{
        await this.fetchCategories();
    }

    fetchCategories = async () => {
        fetch(apiurl + "/api/Setting/GetAllQuoteCategories", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache'
            },
        }).then(async (response) => {
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                var data = [];
                if (response.status !== 204) {
                    var promise = response.json();
                    await promise.then(x => data = x);
                }
                this.state.items = data;
                this.setState({ items: this.state.items });
            }
        })
    }

    updateRank = async () =>{
        this.state.items.map((item, index)=>{item.rank = parseInt(index+1);});
        console.log(this.state.items);

        var response = await putData('/api/Category/UpdateRanks', this.state.items);
        if(response.ok){
            alert("Ranks Updated Successfully!")
            await this.fetchCategories();
        }
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        return (
            <>
            {/* <Header/> */}
                {/* Page content */}
                <Container fluid>
                <div className="App">
                    <br/><br/><br/>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.state.items.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                {item.name + " (" +parseInt(index+1) + ")"}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <br/>
                <button className="btn btn-primary" onClick={()=>this.updateRank()}>Update Rankings</button>
                </div>
                </Container>
                    
                
            </>

        );
    }
}

