import React, { Component } from "react";
import { defaultModel } from "../../components/DefaultModel";
import { getData, postData } from "../../services/fetch";
import { TwitterCardIndex } from "../../components/TwitterCard";
import { apiurl } from "../../services/common";
import CreatableSelect from 'react-select/creatable';
import SimpleModal, { ConfirmModal, FolderSaveModal } from "../../components/Tables/Modal";
import { getToken } from "../../services/storage";
import { SavePost } from "../Folder/Folder";

export default class Submissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            savedPostIds: [],
            firstColumnPosts: [],
            secondColumnPosts: [],
            thirdColumnPosts: [],
            addModal: false,
            keywords: [],
            selectedUnPopularCategories: [],
            modal: false,
            unbookmarkModal: false
        }
        // this.fetchPosts();
    }

    componentDidMount = async () => {
        await this.fetchPosts();
        await this.fetchSavedPostIds();
    }

    updateSearch = async (e) => {
        var categories = [];
        var keywords = [];
        var lastSearch = {};
        console.log(e);
        if (e != null) {
            e.map((selected, index) => {
                if (selected.value == selected.label) {
                    keywords.push(selected.value);
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: false }
                    }
                } else {
                    categories.push(selected.value)
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: true }
                    }
                }
            })
        }

        // if ((categories.length + keywords.length) > this.state.selectedUnPopularCategories.length) {
        //     console.log("triggered")
        //     this.updateRecentSearches(lastSearch)
        // }

        await this.setState({ keywords, selectedUnPopularCategories: categories });
        await this.fetchPosts()

    }

    fetchPosts = async (search, page = 1, per_page = 30) => {
        var model = {};
        model.page = page;
        model.per_page = per_page;
        model.search = this.state.keywords;
        model.isDescending = this.state.descOrder;
        model.isSubmittor = true;
        model.sorts = [0]
        var searchCategories = [];
        this.state.selectedUnPopularCategories.map((cat, index) => searchCategories.push(cat))
        // this.state.selectedCategories.map((cat, index) => searchCategories.push(cat));
        model.categoryIds = searchCategories;
        var url = '/api/Post/SearchPosts';
        var response = await postData(url, model);
        if (response.ok) {
            this.state.posts = response.data.data;
            this.setState({ posts: this.state.posts });
        }
        this.diversifyPostColumns();
    }

    fetchSavedPostIds = async () => {
        var url = apiurl + '/api/User/GetAllUserSavedPostIds'
        var response = await getData(url);
        if (response.ok) {
            this.state.savedPostIds = response.data.data;
            this.state.posts.map((post, i) => {
                post.isBookMarked = this.state.savedPostIds.map((savePost, n) => { return savePost.postID }).includes(post.id);
                if (post.isBookMarked) {
                    post.bookMarkId = this.state.savedPostIds.find(x => x.postID == post.id).id;
                    post.bookMarkDate = this.state.savedPostIds.find(x => x.postID == post.id).createdDate;
                }
            })
            this.setState({ posts: this.state.posts });
            this.setState({ savedPostIds: this.state.savedPostIds });
        }
    }

    diversifyPostColumns = async () => {
        var posts = this.state.posts;
        var firstColumnPosts = [];
        var secondColumnPosts = [];
        var thirdColumnPosts = [];
        var fourthColumnPosts = [];
        posts.map(async (post, index) => {
            // var embedCode = await this.getEmbedPost(post.contentSourceLink);
            // post.embedCode = embedCode;
            if (index % 3 == 0) {
                firstColumnPosts.push(post);
            } else if (index % 3 == 1) {
                secondColumnPosts.push(post);
            } else {
                thirdColumnPosts.push(post);
            }
        })
        this.setState({ firstColumnPosts, secondColumnPosts, thirdColumnPosts });
    }

    selectCustomStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '40px',
            borderRadius: '10px',
            textAlign: 'center',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            textAlign: 'center',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'center',
        }),
        option: (provided, state) => ({
            ...provided,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            align: 'center',
            color: 'black',
            fontSize: '13px',
            marginLeft: '5px'
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            display: 'none'
        }),
    };

    toggle = async () => {
        this.setState({ addModal: false, modal: false, unbookmarkModal: false })
        await this.fetchPosts();
        await this.fetchSavedPostIds();
    }
    bookmark = async (id) => {
        var token = await getToken();
        if (token == null) {
            window.location.href = '/auth/login'
        } else {
            this.setState({ selectedPostId: id, modal: true })
        }

    }

    unbookmark = async (id) => {
        var url = '/api/User/UnSavePost?id=' + this.state.selectedUnbookmarkId;
        var response = await postData(url, {});
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.toggle();
            }
        }
    }

    render() {
        return (<>
            <div className="container">
                <div className="row">
                    <div className="col-md-10">
                        <h3>Submissions</h3>
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-block" style={{ backgroundColor: 'white', borderColor: 'black', borderStyle: 'solid', borderWidth: 0.5, borderRadius: 10 }} onClick={() => { this.setState({ addModal: true }) }}><i className="fa fa-plus-square"></i> Submit a tweet</button>
                    </div>
                </div>

                <br />
                <div className="row">
                    <div className="col-lg-4 col-10">
                        <CreatableSelect
                            closeMenuOnSelect={false}
                            isMulti
                            options={this.state.categoryList}
                            onChange={(e) => this.updateSearch(e)}
                            placeholder={<div><><i class="fa fa-search"></i> Search in your submissions</></div>}
                            styles={this.selectCustomStyle}
                        />
                    </div>
                    <div className="col-lg-1 col-2" style={{ padding: 3, cursor: 'pointer' }} onClick={() => this.changeSortOrder()}>
                        <b style={{ fontSize: 20 }}><i className={this.state.descOrder ? "fa fa-sort-amount-desc" : "fa fa-sort-amount-asc"}></i></b>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        {
                            this.state.firstColumnPosts.map((post, index) =>
                                <div key={index}>
                                    <TwitterCardIndex status={post.status} bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} contentCreator={post.contentCreator} contentSourceLink={post.contentSourceLink} bookmarkCount={post.bookmarkCount} images={post.postImages} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={post.isBookMarked} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                    <br />
                                    {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                </div>
                            )
                        }
                    </div>
                    <div className="col-lg-4 col-md-6">
                        {
                            this.state.secondColumnPosts.map((post, index) =>
                                <div key={index}>
                                    <TwitterCardIndex status={post.status} bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} contentCreator={post.contentCreator} contentSourceLink={post.contentSourceLink} bookmarkCount={post.bookmarkCount} images={post.postImages} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={post.isBookMarked} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                    <br />
                                    {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                </div>
                            )
                        }
                    </div>
                    <div className="col-lg-4 col-md-6">
                        {
                            this.state.thirdColumnPosts.map((post, index) =>
                                <div key={index}>
                                    <TwitterCardIndex status={post.status} bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} contentCreator={post.contentCreator} contentSourceLink={post.contentSourceLink} bookmarkCount={post.bookmarkCount} images={post.postImages} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={post.isBookMarked} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                    <br />
                                    {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                </div>
                            )
                        }
                    </div>
                </div>
                <FolderSaveModal toggle={this.toggle} modal={this.state.modal} title={'Bookmark To'}>
                    <SavePost toggle={this.toggle} postId={this.state.selectedPostId}></SavePost>
                </FolderSaveModal>
                <ConfirmModal
                    toggle={this.toggle}
                    modal={this.state.unbookmarkModal}
                    title={'UnBookmark Confirmation'}
                    confirmText={'Are you sure you want to unbookmark post?'}
                    buttonText={'Unbookmark'}
                    onConfirm={this.unbookmark}
                />
                <SimpleModal toggle={this.toggle} modal={this.state.addModal} title={'Submit Tweet'}>
                    <SubmitPost toggle={this.toggle} refresh={this.fetchPosts}></SubmitPost>
                </SimpleModal>
            </div>
        </>)
    }
}

export class SubmitPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: null,
            url: '',
            isAlreadySubmitted: false,
            label: ''
        }
    }

    getTwitterData = async (e) => {
        this.state.url = e.target.value;
        this.setState({ isLoading: true, url: this.state.url })
        var url = apiurl + "/api/User/GetTweet?url=" + this.state.url;
        var response = await getData(url);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.state.model = response.data.data;
                this.state.label = response.data.isAlreadySubmitted ? response.data.data.status == 1 ? 'This Tweet is already Submmited and waiting to be approved!' : 'This Tweet is already Curated and please click the Tweet to follow the link!' : '';
                this.setState({ model: this.state.model, isAlreadySubmitted: response.data.isAlreadySubmitted });
            }
        }
    }

    submitTweet = async () => {
        var url = "/api/User/SubmitTweet";
        var post = this.state.model;
        post.postChains = [];
        post.postThreads = [];
        post.contentSourceLink = this.state.url;
        var response = await postData(url, post);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.props.toggle();
                this.props.refresh();
            } else {
                this.state.label = response.data.text;
                this.setState({ label: this.state.label });
            }
        }
    }

    render() {
        var post = this.state.model;
        return (<>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <label>Enter Tweet URL</label>
                        <input className="form-control" value={this.state.url} onChange={(e) => this.getTwitterData(e)} />
                    </div>
                </div>
                <br />
                {post != null ?
                    <>
                        <div className="row">
                            <div className="col-md-6">
                                <TwitterCardIndex status={post.status} bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} contentCreator={post.contentCreator} contentSourceLink={post.contentSourceLink} bookmarkCount={post.bookmarkCount} images={post.postImages} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={true} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                            </div>
                            <div className="col-md-6">
                                <label>{this.state.label}</label>
                            </div>

                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6" align='right'>
                                <button className="btn btn-default" disabled={this.state.isAlreadySubmitted} onClick={() => this.submitTweet()}>Submit</button>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>
                    </>
                    : <></>
                }

            </div>
        </>)
    }
}