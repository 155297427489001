import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
//import AuthNavbar from "components/Navbars/AuthNavbar.jsx";

import "../assets/shop/css/linearicons.css";
import "../assets/shop/css/font-awesome.min.css";
import "../assets/shop/css/themify-icons.css";
import "../assets/shop/css/nice-select.css";
import "../assets/shop/css/nouislider.min.css";

import routes from "routes.js";
import { Header } from "../views/Shop/Products";
import { connect } from "react-redux";
import { getTab, getToken, removeLoginProvider, removeRole, removeToken, setTab } from "../services/storage";
import { getData } from "../services/fetch";
import { apiurl, googleClientId, s3HostURL } from "../services/common";
import { GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
import { defaultModel } from "../components/DefaultModel";
import threadLogo from '../assets/img/icons/thread.png'
import quoteLogo from '../assets/img/icons/quote.png'
import creatorLogo from '../assets/img/icons/creator.png'
import ReactTooltip from 'react-tooltip'


class User extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: null,
      user: defaultModel.user,
      modal: false,
      infoModal: false,
      isMobile: false,
      tab: 'thread'
    }

  }

  handleResize = () => {
    this.setState({
      isMobile: window.innerWidth <= 768
    });
  }
  async componentDidMount() {
    //responsive
    window.location.href.includes("quotes") ? this.setCurrentTab("quote") : window.location.href.includes("creators") ? this.setCurrentTab("creator") : window.location.href.includes("playlists") ? this.setCurrentTab("playlist") : this.setCurrentTab("thread");
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    // await this.getCurrentTab();

    gapi.load('client:auth2', this.initClient);
    var token = getToken();
    this.setState({ token: token });
    if (token != null) {
      await this.getUserData();
    }
  }

  getUserData = async () => {
    var url = apiurl + '/api/user/getUserData'
    var result = await getData(url);
    if (result.ok) {
      var data = result.data;
      if (data.statusCode == 0) {
        this.state.user = data.data;
        this.setState({ user: this.state.user })
      }
    }
  }

  initClient = () => {
    gapi.client.init({
      clientId: googleClientId,
      scope: 'https://www.googleapis.com/auth/drive'
    });
  };

  createScript(src) {
    const script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    script.async = true;

    document.body.appendChild(script);
  }

  createLink(href) {
    const script = document.createElement("link");
    script.href = href;
    script.rel = "stylesheet";
    script.async = true;

    document.body.appendChild(script);
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/user") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  setCurrentTab = async (value) => {
    await this.props.store.dispatch({ type: 'UPDATE_TAB', payload: { tab: value } });
    // this.getCurrentTab();
  }

  getCurrentTab = async () => {
    var tab = this.props.store.getState().tab;
    this.setState({ tab: tab });
    if (tab == 'thread') {
      this.setState({ isThreads: true })
    } else if (tab == 'quote') {
      this.setState({ isThreads: false })
    }

  }

  logout = () => {
    removeToken();
    removeRole();
    removeLoginProvider();
    this.setState({ token: null });
    console.log("logged out")
    // window.location.href("/user/index")
  }
  render() {
    return (
      <div id="category" style={{ backgroundColor: "#F6F6F6" }}>
        {/* <Header onSearched={(searchString) => this.props.onSearched(searchString)} /> */}
        <br />
        <div className="container-fluid">
          <div className="row">
            <div className={this.state.token == null ? "col-lg-10 col-md-6 col-7" : "col-lg-10 col-md-6 col-7"} style={{ marginTop: 10 }}>
              <div className="row" >
                <a href="/user/index">
                  <div style={{ borderStyle: this.props.store.getState().tab == 'thread' ? 'solid' : 'none', borderColor: 'black', borderRadius: 20, borderWidth: 0.8, paddingLeft: 10, paddingRight: 10, paddingTop: 3, marginLeft: 20, display: 'inline-block', cursor: 'pointer' }} onClick={() => this.setCurrentTab('thread')}>
                    <b style={{ color: 'black' }}>{"Threads"}</b>
                  </div>
                </a>
                <a href="/user/quotes">
                  <div style={{ borderStyle: this.props.store.getState().tab == 'quote' ? 'solid' : 'none', borderColor: 'black', borderRadius: 20, borderWidth: 0.8, paddingLeft: 10, paddingRight: 10, paddingTop: 3, marginLeft: 10, display: 'inline-block' }} onClick={() => this.setCurrentTab('quote')}>
                    <b style={{ color: 'black' }}>{"Quotes"}</b>
                  </div>
                </a>
                <a href="/user/creators">
                  <div style={{ borderStyle: this.props.store.getState().tab == 'creator' ? 'solid' : 'none', borderColor: 'black', borderRadius: 20, borderWidth: 0.8, paddingLeft: 10, paddingRight: 10, paddingTop: 3, marginLeft: 10, display: 'inline-block' }} onClick={() => this.setCurrentTab('creator')}>
                    <b style={{ color: 'black' }}>{"Creators"}</b>
                  </div>
                </a>
                <a href="/user/playlists">
                  <div style={{ borderStyle: this.props.store.getState().tab == 'playlist' ? 'solid' : 'none', borderColor: 'black', borderRadius: 20, borderWidth: 0.8, paddingLeft: 10, paddingRight: 10, paddingTop: 3, marginLeft: 10, display: 'inline-block' }} onClick={() => this.setCurrentTab('playlist')}>
                    <b style={{ color: 'black' }}>{"Playlists"}</b>
                  </div>
                </a>
              </div>

            </div>

            <div className={"col-lg-1 col-md-1 col-1"} style={{ align: 'right' }}>
              <div className="row">

                <a href="/user/folder" style={{ color: 'black', fontSize: 30, marginRight: 30 }}><i class="fa fa-bookmark" data-tip data-for="bookmarkTip"></i></a>
                <ReactTooltip id="bookmarkTip" place="bottom" effect="solid">
                  Bookmarks
                </ReactTooltip>
                <div className={"col-lg-1 col-md-1 col-1"} style={{ marginTop: -10 }} onMouseEnter={() => this.setState({ infoModal: true })} onMouseLeave={() => this.setState({ infoModal: false })}>
                  <b style={{ color: 'black', fontSize: 33, cursor: 'pointer' }}>...</b>
                  <br /><br />
                  <div style={{ width: 170, zIndex: 90, marginLeft: -140, backgroundColor: 'white', borderRadius: 10, padding: 10, display: this.state.infoModal ? 'block' : 'none', position: 'absolute' }}>
                    <a href="/user/faq" style={{ color: 'black' }} data-tip data-for="faqTip"><i className="fa fa-question"></i> FAQ</a>
                    {/* <ReactTooltip id="faqTip" place="right" effect="solid">
                      Coming Soon!
                    </ReactTooltip> */}
                    <br /><br />
                    <a href="#" style={{ color: 'black' }} data-tip data-for="termTip"><i className="fa fa-book"></i> Terms Of Use</a>
                    <ReactTooltip id="termTip" place="right" effect="solid">
                      Coming Soon!
                    </ReactTooltip>
                    <br /><br />
                    <a href="#" style={{ color: 'black' }} data-tip data-for="twitterTip"><i className="fa fa-twitter"></i> Twitter</a>
                    <ReactTooltip id="twitterTip" place="right" effect="solid">
                      Coming Soon!
                    </ReactTooltip>
                  </div>
                </div>
              </div>

            </div>


            <div className={this.state.token == null ? "col-lg-1 col-md-1 col-3" : "col-lg-1 col-md-1 col-3"} align='left' onMouseEnter={() => this.setState({ modal: this.state.token != null ? true : false })} onMouseLeave={() => this.setState({ modal: false })}>
              {
                this.state.token == null ?
                  <><a className="btn btn-outline-default" href="/auth/login">Sign In</a></> :
                  this.state.user.imageURL != null && this.state.user.imageURL != '' ? <>
                    <span  ><img style={{ width: 50, height: 50, borderRadius: 50, cursor: 'pointer' }} src={s3HostURL + "profile-images/" + this.state.user.imageURL}></img></span></>
                    : <>
                      <span ><div style={{ width: 50, height: 50, borderRadius: 50, cursor: 'pointer', backgroundColor: '#172b4d', color: 'white', paddingTop: 13, fontWeight: 'bold' }} align='center'>{this.state.user.email.length > 0 ? this.state.user.email.charAt(0).toUpperCase() : "?"}</div></span>
                    </>

              }
              <br /><br />
              <div style={{ width: 170, zIndex: 90, marginLeft: -120, backgroundColor: 'white', borderRadius: 10, padding: 10, display: this.state.modal ? 'block' : 'none', position: 'absolute' }} align="left">
                <a href="/user/profile" style={{ color: 'black' }}><i className="fa fa-gear"></i> Profile Settings</a>
                <br /><br />
                <a href="/user/submission" style={{color:'black'}}><i class="fa fa-upload"></i> Submissions</a>
                <br/><br/>
                <a href="#" style={{ color: 'black' }} data-tip data-for="pointsTip"><i className="fa fa-bullseye"></i> User Points</a>
                <ReactTooltip id="pointsTip" place="right" effect="solid">
                  Coming Soon!
                </ReactTooltip>
                <br /><br />
                <a href="#" onClick={() => this.logout()} style={{ color: 'black' }}><i className="fa fa-sign-out"></i> Logout</a>
              </div>
            </div>
          </div>

        </div>

        <Switch>{this.getRoutes(routes)}</Switch>
        <footer className="footer-area section_gap" style={{ padding: '0px 0px 50px 0px', marginTop: 100 }}>
          <div className="container">
            {/* <div className="footer-bottom d-flex justify-content-center align-items-center flex-wrap">
              <p className="footer-text m-0">
                &copy;<script>document.write(new Date().getFullYear());</script> Developed By
                <a
                  className="font-weight-bold ml-1"
                  href="http://trifinitymyanmar.com/"
                  target="_blank"
                >
                  NYA
                </a> &nbsp;
                | &nbsp;
                Designed by <a href="https://colorlib.com" target="_blank">Colorlib</a>
              </p>
            </div> */}
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    search: state.search
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSearched: (value) => {
      const action = { type: 'UPDATE_SEARCH', value };
      dispatch(action);
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
