import React from "react";

// reactstrap components
import {
    Container,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { getData } from 'services/fetch';
import MaterialTable from "material-table";
import DetailPanel from "../../components/Tables/DetailPanel";
import SimpleModal, { ConfirmModal } from "../../components/Tables/Modal.jsx";
import {
    Redirect,
} from "react-router-dom";
import { defaultModel } from "../../components/DefaultModel";
import { deleteData, postData } from "../../services/fetch";
import { getDateForInput } from "../../services/date";
import { apiurl } from "../../services/common";
import PostForm from "./PostForm";
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

class SubmittedPost extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRow: null,
            modal: false,
            model: defaultModel.post,
            action: '',
            redirectToLogin: false,
            disableEditButton: true,
            selectedIds: [],
            selectedId: '',
            deleteModal: false,
            hideModal: false,
            sorts: [],
            descOrder: true,
            postStatuses: ['Curated', 'Pending', 'Approved', 'Rejected']
        };

        this.tableRef = React.createRef();
        // this.delete = this.delete.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
    }

    toggle = () => {
        this.setState({
            modal: false,
            deleteModal: false,
            hideModal: false,
            model: defaultModel.chain
        });
    }

    refreshTable = () => {
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    reject = async () => {
        var response = await postData('/api/Post/UpdatePostStatus', {id:this.state.selectedId, status: 3});
        if (response.ok) {
            this.toggle();
            this.refreshTable();
        }
    }

    approve = async () => {
        var response = await postData('/api/Post/UpdatePostStatus', {id:this.state.selectedId, status: 2});
        if (response.ok) {
            this.toggle();
            this.refreshTable();
        }
    }

    render() {
        const thisprops = this;
        if (this.state.redirectToLogin) return <Redirect to={{
            pathname: "/auth/login",
            state: { from: this.props.location }
        }} />
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--9" fluid>

                    <MaterialTable
                        title="Posts"
                        tableRef={this.tableRef}
                        columns={[
                            { title: 'Status', field: 'status', render: rowData => <label>{this.state.postStatuses[rowData.status]}</label> },
                            { title: 'Title', field: 'title' },
                            { title: 'Submittor', field: 'contentCreatorName' },
                            { title: 'Tweet Date', field: 'contentCreatedDate' },
                            { title: 'Submitted Date', field: 'submittedDate' },
                            { title: 'Source Link', field: 'contentSourceLink' }
                        ]}
                        data={async function (query) {
                            let url = apiurl + '/api/Post/GetSubmittedPosts?'
                            url += 'per_page=' + query.pageSize
                            url += '&page=' + (query.page + 1)
                            url += '&search=' + query.search
                            url += '&isDescending=' + thisprops.state.descOrder
                            url += '&sorts=' + JSON.stringify(thisprops.state.sorts)
                            var result = await getData(url);
                            if (result.ok) {
                                var data = result.data;
                                return {
                                    data: data.data,
                                    page: data.page - 1,
                                    totalCount: data.total
                                }
                            } else if (result.status === 401) {
                                thisprops.setState({ redirectToLogin: true });
                            } else {
                                return {
                                    data: [],
                                    page: 1,
                                    totalCount: 0
                                }
                            }
                        }}
                        options={{
                            search: true,
                            // selection: true,
                            debounceInterval: 500,
                            actionsColumnIndex: 0,
                            pageSize: 20,
                            pageSizeOptions: [20, 50, 100]
                        }}
                        onSelectionChange={(rows) => {
                            this.setState(state => ({
                                disableEditButton: rows.length > 1,
                                selectedIds: rows.map(row => row.id)
                            }));
                        }}
                        onRowClick={async (evt, data) => {
                            this.setState({ modal: true, model: data, action: 'edit' });
                        }}
                        actions={[
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: this.refreshTable
                            },
                            // {
                            //     icon: 'add_circle',
                            //     tooltip: 'Add Post',
                            //     isFreeAction: true,
                            //     onClick: () => { this.setState({ action: 'create', modal: true }); },
                            // },
                            // {
                            //   tooltip: 'Edit A Selected Post',
                            //   icon: 'edit',
                            //   disabled: this.state.disableEditButton,
                            //   onClick: async (evt, data) => {
                            //     this.setState({ modal: true, model: data[0], action: 'edit' });
                            //   }
                            // },
                            {
                                tooltip: 'Reject Tweet',
                                icon: 'dangerous',
                                iconProps: { color: 'error' },
                                onClick: (evt, data) => this.setState({ deleteModal: true, selectedId: data.id })
                            },
                            {
                                tooltip: 'Approve Tweet',
                                icon: 'check',
                                iconProps: { color: 'primary' },
                                onClick: (evt, data) => this.setState({ hideModal: true, selectedId: data.id })
                            },
                            // rowData => ({
                            //     tooltip: rowData.isHidden ? 'Show Post' : 'Hide Post',
                            //     icon: () => rowData.isHidden ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>,
                            //     iconProps: { color: 'default' },
                            //     onClick: (evt, data) => this.setState({ hideModal: true, selectedId: data.id })
                            // })
                        ]}
                    />

                    <SimpleModal toggle={this.toggle} modal={this.state.modal} title={'Post'}>
                        <PostForm
                            model={this.state.model}
                            action={this.state.action}
                            toggle={this.toggle}
                            refreshTable={this.refreshTable}
                        />
                    </SimpleModal>
                    <ConfirmModal
                        toggle={this.toggle}
                        modal={this.state.deleteModal}
                        title={'Reject Confirmation'}
                        confirmText={'Are you sure you want to reject selected Tweet?'}
                        buttonText={'Reject'}
                        onConfirm={this.reject}
                    />
                    <ConfirmModal
                        toggle={this.toggle}
                        modal={this.state.hideModal}
                        title={'Approve Confirmation'}
                        confirmText={'Are you sure you want to approve selected Tweet?'}
                        buttonText={'Approve'}
                        onConfirm={this.approve}
                    />
                </Container>
            </>
        );
    }
}

export default SubmittedPost;
