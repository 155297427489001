import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { getData } from 'services/fetch';
import MaterialTable from "material-table";
import DetailPanel from "../../components/Tables/DetailPanel";
import SimpleModal, { ConfirmModal } from "../../components/Tables/Modal.jsx";
import ItemForm from "./ItemForm";
import {
  Redirect,
} from "react-router-dom";
import { defaultModel } from "../../components/DefaultModel";
import { deleteData, putData } from "../../services/fetch";
import { sort } from "../../services/common";
import exportExcel, { excelType } from "../../services/excel";
import { isInRole, getRole } from "../../services/storage";

class Items extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: null,
      modal: false,
      model: defaultModel.item,
      action: '',
      redirectToStaff: false,
      redirectToLogin: false,
      selectedIds: [],
      idToDelete: '',
      qtyEditableID: '',
    };

    this.tableRef = React.createRef();
    this.delete = this.delete.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
  }

  toggle = () => {
    var item = defaultModel.item;
    item.itemPictures = [];
    this.setState({
      modal: false,
      deleteModal: false,
      model: JSON.parse(JSON.stringify(item)),
      idToDelete: '',
    });
  }

  refreshTable = () => {
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  delete = async () => {
    var response = await deleteData('/api/Item/' + this.state.idToDelete);
    if (response.ok) {
      this.toggle();
      this.refreshTable();
    }
  }

  componentDidMount = () => {
    var role = getRole()[0];
    if (role == "staff") {
      this.setState({ redirectToStaff: true });
    }
  }

  render() {
    const thisprops = this;
    if (this.state.redirectToLogin) return <Redirect to={{
      pathname: "/auth/login",
      state: { from: this.props.location }
    }} />
    if (this.state.redirectToStaff) {
      return <Redirect to={{
        pathname: "/admin/orders",
        state: { from: this.props.location }
      }} />
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--9" fluid>

          <MaterialTable
            title="Items"
            tableRef={this.tableRef}
            columns={[
              { title: 'Name', field: 'itemName' },
              { title: 'Type', field: 'type' },
              // {
              //   title: 'Preorder',
              //   field: 'isPreOrderItem',
              //   render: rowData =>
              //     rowData.isPreOrderItem ?
              //       (<input type='checkbox' checked disabled />) :
              //       (<input type='checkbox' disabled />)
              // },
              {
                title: 'Qty',
                field: 'quantityInStock',
                render: rowData => {
                  var qty = parseInt(JSON.stringify(rowData.quantityInStock));
                  return (
                    this.state.qtyEditableID == rowData.id ?
                      (
                        <div className='qtyEditable' style={{ width: 110, float: 'right' }}>
                          <input type='number' className='form-control qtyEditable' defaultValue={qty} onChange={e => qty = e.target.value} style={{ width: 80, display: 'inline' }} />
                          <i className='ni ni-check-bold text-blue qtyEditable'
                            onClick={async () => {
                              var response = await putData('/api/Item/ChangeQty/' + rowData.id + '/' + qty, {});
                              if (response.ok) {
                                this.setState({ qtyEditableID: '' });
                                this.refreshTable();
                              } else {
                                alert(response.data);
                              }
                            }}
                            style={{ border: '1px solid', borderRadius: 50, padding: 5, marginLeft: 2 }}></i>
                        </div>
                      ) :
                      (
                        <a className={'qtyEditable'}
                          onClick={() => {
                            this.setState({ qtyEditableID: rowData.id });
                          }}>
                          {rowData.quantityInStock}
                        </a>
                      )
                  )
                }
              },
              { title: 'Cost', field: 'perItemCost' },
              { title: 'Purchase', field: 'perItemPurchasePrice' },
            ]}
            onRowClick={(event, rowData, togglePanel) => { if (!event.target.className.includes('qtyEditable')) { togglePanel(); this.setState({ qtyEditableID: '' }); } }}
            data={async function (query) {
              let url = '/api/Item?'
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              url += '&search=' + query.search
              var result = await getData(url);
              if (result.ok) {
                var data = result.data;
                return {
                  data: data.data,
                  page: data.page - 1,
                  totalCount: data.total
                }
              } else if (result.status === 401) {
                thisprops.setState({ redirectToLogin: true });
              } else {
                return {
                  data: [],
                  page: 1,
                  totalCount: 0
                }
              }
            }}
            detailPanel={rowData => {
              return (
                <DetailPanel
                  images={sort(rowData.itemPictures, 'orderNo')}
                  data={[
                    { label: 'Description', value: rowData.description, full: true },
                    { label: 'Color', value: rowData.color },
                    { label: 'Flavor', value: rowData.flavor },
                    { label: 'Brand', value: rowData.brand },
                    { label: 'Nicotine', value: rowData.percentage },
                    // { label: 'Url (Preorder)', value: rowData.url, href: rowData.url },
                    { label: 'Remark', value: rowData.remark },
                  ]} />
              )
            }}
            options={{
              search: true,
              selection: false,
              debounceInterval: 500,
              pageSizeOptions: [5, 10, 20, 50]
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: this.refreshTable
              },
              {
                icon: 'add_circle',
                tooltip: 'Add Item',
                isFreeAction: true,
                onClick: () => { this.setState({ action: 'create', modal: true }); },
              },
              {
                icon: 'save_alt',
                tooltip: 'Export Stock',
                isFreeAction: true,
                onClick: () => { exportExcel(excelType.stock) },
                disabled: !isInRole('admin')
              },
              {
                tooltip: 'Edit Item',
                icon: 'edit',
                onClick: async (evt, data) => {
                  data.itemPictures = sort(data.itemPictures, 'orderNo');
                  this.setState({ modal: true, model: JSON.parse(JSON.stringify(data)), action: 'edit' });
                }
              },
              {
                tooltip: 'Remove Item',
                icon: 'delete',
                onClick: (evt, data) => this.setState({ deleteModal: true, idToDelete: data.id })
              }
            ]}
          />

          <SimpleModal toggle={this.toggle} modal={this.state.modal} title={'Item'}>
            <ItemForm
              model={this.state.model}
              action={this.state.action}
              toggle={this.toggle}
              refreshTable={this.refreshTable}
            />
          </SimpleModal>
          <ConfirmModal
            toggle={this.toggle}
            modal={this.state.deleteModal}
            title={'Delete Confirmation'}
            confirmText={'Are you sure you want to delete this item?'}
            buttonText={'Delete'}
            onConfirm={this.delete}
          />
        </Container>
      </>
    );
  }
}

export default Items;
