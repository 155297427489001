import React, { Component } from "react";
import { OFontSizeChangeCard, RFontSizeChangeCard } from "../../components/TwitterCard";
import CreatableSelect from 'react-select/creatable';
import { apiurl, s3Folder, s3HostURL } from "../../services/common";
import { getData, postData } from "../../services/fetch";
import { Link } from "react-router-dom";

export default class Playlists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recommendedFolders: [],
            recentFolders: [],
            searchedFolders: [],
            recommendedFolderColors: ["#e74645", "#fb7756", "#facd60", "#1ac0c6", "#454d66", "#309975", "#dad873", "#58b368"],
            recentFolderColors: ["#12492f", "#0a2f35", "#f56038", "#3e4491", "#1a1b4b", "#2c698d", "#8f3b76", "#f5487f"],
            isSearchMode: false,
            searchValues: []
        }
    }

    componentDidMount = async () => {
        this.state.recommendedFolderColors = this.shuffleColors(this.state.recommendedFolderColors);
        this.setState({ recommendedFolderColors: this.state.recommendedFolderColors });

        this.state.recentFolderColors = this.shuffleColors(this.state.recentFolderColors);
        this.setState({ recentFolderColors: this.state.recentFolderColors });

        await this.fetchRecentFolders();
        await this.fetchRecommendedFolders();
    }

    fetchRecommendedFolders = async () => {
        var url = apiurl + "/api/setting/GetRecommendedPublicFolders";
        var response = await getData(url);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.state.recommendedFolders = response.data.data;
                this.setState({ recommendedFolders: this.state.recommendedFolders });
            }
        }
    }

    fetchRecentFolders = async () => {
        var url = apiurl + "/api/setting/GetRecentPublicFolders";
        var response = await getData(url);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.state.recentFolders = response.data.data;
                this.setState({ recentFolders: this.state.recentFolders });
            }
        }
    }


    shuffleColors = (array) => {

        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex > 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    selectCustomStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '60px',
            borderRadius: '10px',
            textAlign: 'center',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            textAlign: 'center',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'center',
        }),
        option: (provided, state) => ({
            ...provided,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            align: 'center',
            color: 'black',
            fontSize: '18px',
            marginLeft: this.state.isMobile || this.state.isTablet ? '10px' : '150px'
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            display: 'none'
        }),
    };

    searchFolders = async (e) => {
        console.log(e);
        this.state.searchValues = [];
        if (e != null && e.length > 0) {
            e.map((search, index) => {
                this.state.searchValues.push(search.value);
            })
        }
        this.setState({ searchValues: this.state.searchValues });
        if (this.state.searchValues.length > 0) {
            var url = '/api/user/searchFolders';
            var response = await postData(url, { names: this.state.searchValues })
            if (response.ok) {
                if (response.data.statusCode == 0) {
                    this.state.searchedFolders = response.data.data;
                    this.setState({ searchedFolders: this.state.searchedFolders });
                }
            }
        } else {
            this.state.searchedFolders = [];
            this.setState({ searchedFolders: this.state.searchedFolders });
        }
    }

    exitSearch = () => {
        if (this.state.searchValues.length > 0) {
            this.state.isSearchMode = true;
        } else {
            this.state.isSearchMode = false;
        }
        this.setState({ isSearchMode: this.state.isSearchMode });
    }

    render() {
        return (
            <div className="container" style={{ minHeight: 1000 }}>

                {
                    <>
                        <>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <section className="lattest-product-area pb-40 category-list">
                                        <div className='row'>
                                            <div className='col-md-3'></div>
                                            <div className='col-md-6'>
                                                <div align="center" onMouseEnter={() => this.setState({ titleChanged: true })} onMouseLeave={() => this.setState({ titleChanged: false })} style={{ height: 150 }}>
                                                    <b style={{ fontSize: 50, color: 'black' }} className="title-font">{this.state.titleChanged ? "thread" : "threadoor"}</b>
                                                    {
                                                        this.state.titleChanged ? <>
                                                            <OFontSizeChangeCard />
                                                            <OFontSizeChangeCard />
                                                            <OFontSizeChangeCard />
                                                            <OFontSizeChangeCard />
                                                            <RFontSizeChangeCard />
                                                            <RFontSizeChangeCard />
                                                            <RFontSizeChangeCard />
                                                        </>
                                                            : <></>
                                                    }
                                                </div>
                                                <CreatableSelect
                                                    closeMenuOnSelect={false}
                                                    isMulti
                                                    options={this.state.categoryList}
                                                    onChange={(e) => this.searchFolders(e)}
                                                    onFocus={() => this.setState({ isSearchMode: true })}
                                                    onBlur={() => this.exitSearch()}
                                                    placeholder={<div><><i class="fa fa-search"></i> What are you looking for?</></div>}
                                                    styles={this.selectCustomStyle}
                                                />
                                                <br />

                                            </div>
                                            <div className='col-md-3'></div>
                                        </div>
                                        <br /><br />
                                        {this.state.isSearchMode ? <>
                                            <div className="container-fluid">
                                                <div className="row">
                                                    {this.state.searchedFolders.map((folder, index) =>
                                                        <div className="col-md-3" style={{ padding: 20 }}>
                                                            <Link to={{ pathname: "/user/folder/Posts", search: "?id=" + folder.id + "&name=" + folder.name }}>
                                                                <div style={{ backgroundColor: this.state.recommendedFolderColors[index], height: 200, paddingLeft: 20 }}>
                                                                    <div style={{ height: 170 }}></div>
                                                                    <div style={{ borderLeftStyle: 'solid', borderLeftColor: 'white', borderLeftWidth: 5, height: 20, paddingLeft: 5 }}>
                                                                        <label style={{ color: 'white', fontWeight: 'bold' }}>{folder.name}</label>
                                                                    </div>

                                                                </div>
                                                            </Link>

                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </>
                                            : <div className="container-fluid">
                                                <h2 style={{ fontWeight: 'bolder' }}>Discover</h2>
                                                <div className="row">
                                                    {this.state.recommendedFolders.map((folder, index) =>
                                                        <div className="col-md-3" style={{ padding: 20 }}>
                                                            <Link to={{ pathname: "/user/folder/Posts", search: "?id=" + folder.id + "&name=" + folder.name + "&color="+this.state.recentFolderColors[index].replace("#","") }}>
                                                                <div style={{ backgroundColor: "#333333", paddingLeft: 20, paddingRight: 20, borderRadius: 10, paddingBottom: 15 }}>
                                                                    <br />
                                                                    <div style={{ height: 190, backgroundImage: folder.coverPhoto != "" ? 'url("'+s3HostURL + "folder-images/" + folder.coverPhoto+'")' : '', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: 10, backgroundColor: this.state.recentFolderColors[index], paddingTop: 50 }} align='center'>
                                                                        {(folder.coverPhoto != "" && folder.coverPhoto != null) ? <></> : <label style={{ color: 'white', fontWeight: 'bold', fontSize: 50 }}>{(folder.name != null && folder.name != "") ? folder.name.charAt(0) : ""}</label>}
                                                                    </div>
                                                                    <br />
                                                                    <div style={{ borderLeftStyle: 'solid', borderLeftColor: 'white', borderLeftWidth: 5, height: 20, paddingLeft: 5 }}>
                                                                        <label style={{ color: '#999999', fontWeight: 'bold' }}>{folder.name}</label>

                                                                    </div>
                                                                    <div style={{  height: 20, paddingLeft: 10 }}>
                                                                        <label style={{ color: '#888888', fontSize: 12, }}>Recommended Playlist</label>

                                                                    </div>


                                                                </div>
                                                            </Link>

                                                        </div>
                                                    )}

                                                </div>
                                                <br /><br />
                                                <h2 style={{ fontWeight: 'bolder' }}>Recent</h2>
                                                <div className="row">
                                                    {this.state.recentFolders.map((folder, index) =>
                                                        <div className="col-md-3" style={{ padding: 20 }}>
                                                            <Link to={{ pathname: "/user/folder/Posts", search: "?id=" + folder.id + "&name=" + folder.name +"&color=" + this.state.recommendedFolderColors[index].replace("#", "")}}>
                                                                <div style={{ backgroundColor: "#333333", paddingLeft: 20, paddingRight: 20, borderRadius: 10, paddingBottom: 15 }}>
                                                                    <br />
                                                                    <div style={{ height: 190, backgroundImage: folder.coverPhoto != "" ? 'url("'+s3HostURL + "folder-images/" + folder.coverPhoto+'")' : '', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: 10, backgroundColor: this.state.recommendedFolderColors[index], paddingTop: 50 }} align='center'>
                                                                        {(folder.coverPhoto != "" && folder.coverPhoto != null) ? <></> : <label style={{ color: 'white', fontWeight: 'bold', fontSize: 50 }}>{(folder.name != null && folder.name != "") ? folder.name.charAt(0) : ""}</label>}
                                                                    </div>
                                                                    <br />
                                                                    <div style={{ borderLeftStyle: 'solid', borderLeftColor: 'white', borderLeftWidth: 5, height: 20, paddingLeft: 5 }}>
                                                                        <label style={{ color: '#999999', fontWeight: 'bold' }}>{folder.name}</label>
                                                                    </div>
                                                                    <div style={{  height: 20, paddingLeft: 10 }}>
                                                                        <label style={{ color: '#888888', fontSize: 12, }}>Naing Ye Aung</label>

                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>}

                                        <br />
                                    </section>
                                </div>
                            </div>
                        </></>
                }
            </div>

        )
    }
}