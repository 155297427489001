import React from "react";
import { Link } from "react-router-dom";
import SimpleModal, { ConfirmModal, FolderSaveModal } from "../../components/Tables/Modal";
import { FolderCard, QuoteCardIndex, TwitterCardIndex } from "../../components/TwitterCard";
import { apiurl, s3HostURL } from "../../services/common";
import { getData, postData } from "../../services/fetch";
import CreatableSelect from 'react-select/creatable';
import { defaultModel } from "../../components/DefaultModel";
import { SavePost } from "../Folder/Folder";
import { getToken } from "../../services/storage";

export default class UserPosts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            creator: defaultModel.creator,
            posts: [],
            folderName: "",
            firstColumnPosts: [],
            secondColumnPosts: [],
            thirdColumnPosts: [],
            allCategories: [],
            categoryList: [],
            descOrder: true,
            keywords: [],
            selectedUnPopularCategories: [],
            threadCount: 0,
            totalLikeCount: 0,
            totalViewCount: 0,
            savedPostIds: [],
            modal: false,
            unbookmarkModal: false
        }
    }

    componentDidMount = async () => {
        await this.fetchAllCategories();
        await this.fetchPosts();
        this.diversifyPostColumns();
        await this.getTwitterUserInfo();
        await this.fetchSavedPostIds();
    }

    changeSortOrder = async () => {
        await this.setState({ descOrder: !this.state.descOrder });
        await this.fetchPosts();

    }

    // fetchPosts = async () => {
    //     let params = new URLSearchParams(this.props.location.search);
    //     var id = params.get('id');
    //     var name = params.get('name')
    //     this.setState({ folderName: name });
    //     var url = apiurl + '/api/User/GetPostsByFolder?folderId=' + id;
    //     var response = await getData(url);
    //     if (response.ok) {
    //         this.state.posts = response.data.data;
    //         this.setState({ posts: this.state.posts });
    //     }
    // }

    fetchPosts = async (search, page = 1, per_page = 30) => {
        var model = {};
        let params = new URLSearchParams(this.props.location.search);
        var id = params.get('id');
        model.page = page;
        model.per_page = per_page;
        model.search = this.state.keywords;
        model.userId = id;
        model.isDescending = this.state.descOrder;
        model.sorts = [0]
        var searchCategories = [];
        this.state.selectedUnPopularCategories.map((cat, index) => searchCategories.push(cat))
        // this.state.selectedCategories.map((cat, index) => searchCategories.push(cat));
        model.categoryIds = searchCategories;
        var url = '/api/Post/SearchPosts';
        var response = await postData(url, model);
        if (response.ok) {

            this.state.posts = response.data.data;
            this.setState({ posts: this.state.posts });

            var urlQuote = '/api/Quote/SearchQuotes';
            var responseQuote = await postData(urlQuote, model);
            if (responseQuote.ok) {
                var quotes = responseQuote.data.data;
                quotes.map((quote, i) => {
                    quote.postCategories = quote.quoteCategories;
                    quote.postImages = quote.quoteImages;
                    quote.isQuote = true;
                    this.state.posts.push(quote);
                })
                this.setState({ posts: this.state.posts })
                // this.diversifyPostColumns();
            }
        }
        this.diversifyPostColumns();
    }

    diversifyPostColumns = () => {
        var posts = this.state.posts;
        var firstColumnPosts = [];
        var secondColumnPosts = [];
        var thirdColumnPosts = [];
        if (posts != null) {
            posts.map((post, index) => {
                if (index % 3 == 0) {
                    firstColumnPosts.push(post);
                } else if (index % 3 == 1) {
                    secondColumnPosts.push(post);
                } else {
                    thirdColumnPosts.push(post);
                }
            })
            this.setState({ firstColumnPosts, secondColumnPosts, thirdColumnPosts });

        }
        console.log(this.state.firstColumnPosts)
    }

    fetchAllCategories = async () => {
        var url = apiurl + '/api/Setting/GetAllCategories'
        var response = await getData(url);
        if (response.ok) {
            this.state.allCategories = response.data;
            this.setState({ allCategories: this.state.allCategories });
            var categoryList = [];
            response.data.map((category, index) => {
                categoryList.push({ label: category.name, value: category.id });
            })
            this.setState({ categoryList });
        }
    }

    updateSearch = async (e) => {
        var categories = [];
        var keywords = [];
        var lastSearch = {};
        console.log(e);
        if (e != null) {
            e.map((selected, index) => {
                if (selected.value == selected.label) {
                    keywords.push(selected.value);
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: false }
                    }
                } else {
                    categories.push(selected.value)
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: true }
                    }
                }
            })
        }

        // if ((categories.length + keywords.length) > this.state.selectedUnPopularCategories.length) {
        //     console.log("triggered")
        //     this.updateRecentSearches(lastSearch)
        // }

        await this.setState({ keywords, selectedUnPopularCategories: categories });
        await this.fetchPosts()

    }

    toggle = async () => {
        this.setState({
            modal: false,
            unbookmarkModal: false
        })
        await this.fetchPosts();
        await this.fetchSavedPostIds();
    }

    async getTwitterUserInfo() {
        let params = new URLSearchParams(this.props.location.search);
        var id = params.get('id');
        var response = await getData(apiurl + '/api/Setting/GetTwitterUserProfile?userId=' + id);
        if (response.ok) {
            this.state.creator = response.data;
            this.setState({ creator: this.state.creator });

        }
    }

    fetchSavedPostIds = async () => {
        var url = apiurl + '/api/User/GetAllUserSavedPostIds'
        var response = await getData(url);
        if (response.ok) {
            this.state.savedPostIds = response.data.data;
            this.state.posts.map((post, i) => {
                post.isBookMarked = this.state.savedPostIds.map((savePost, n) => { return savePost.postID }).includes(post.id);
                if (post.isBookMarked) {
                    post.bookMarkId = this.state.savedPostIds.find(x => x.postID == post.id).id;
                    post.bookMarkDate = this.state.savedPostIds.find(x => x.postID == post.id).createdDate;
                }
            })
            this.setState({ posts: this.state.posts });
            this.setState({ savedPostIds: this.state.savedPostIds });
        }
    }

    bookmark = (id) => {
        var token = getToken();
        if (token == null) {
            window.location.href = '/auth/login'
        } else {
            this.setState({ selectedPostId: id, modal: true })
        }

    }

    unbookmark = async (id) => {
        var url = '/api/User/UnSavePost?id=' + this.state.selectedUnbookmarkId;
        var response = await postData(url, {});
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.toggle();
            }
        }
    }

    selectCustomStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '40px',
            borderRadius: '10px',
            textAlign: 'center',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            textAlign: 'center',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'center',
        }),
        option: (provided, state) => ({
            ...provided,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            align: 'center',
            color: 'black',
            fontSize: '13px',
            marginLeft: '5px'
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            display: 'none'
        }),
    };

    render() {
        // const userPost = this.state.posts[0];
        const creator = this.state.creator;
        return (<>
            <div className="container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1">

                        </div>
                        <div className="col-md-10">

                            <br />
                            <div align='center'>
                                <img src={creator.contentCreatorProfileURL.startsWith("https") ? creator.contentCreatorProfileURL : s3HostURL + "twitter-profile-images/" + creator.contentCreatorProfileURL} width="200" height="200" style={{ borderRadius: 100 }} />
                                <h3>{"@" + creator.contentCreatorUsername}</h3>
                                <p><b style={{ fontWeight: 1000 }}>{creator.threadCount}</b> Threads &nbsp; <i class="fa fa-circle" style={{ fontSize: 6 }}></i> &nbsp; <b style={{ fontWeight: 1000 }}>{creator.quoteCount}</b> Quotes &nbsp; <i class="fa fa-circle" style={{ fontSize: 6 }}></i> &nbsp; <b style={{ fontWeight: 1000 }}>{creator.viewCount}</b> Views &nbsp; <i class="fa fa-circle" style={{ fontSize: 6 }}></i> &nbsp; <b style={{ fontWeight: 1000 }}>{creator.bookmarkCount}</b> Bookmarks</p>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-lg-4 col-10">
                                    <CreatableSelect
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={this.state.categoryList}
                                        onChange={(e) => this.updateSearch(e)}
                                        placeholder={<div><><i class="fa fa-search"></i> Search in {this.state.posts.length > 0 ? "@" + creator.contentCreatorUsername : null}</></div>}
                                        styles={this.selectCustomStyle}
                                    />
                                </div>
                                <div className="col-lg-1 col-2" style={{ padding: 3, cursor: 'pointer' }} onClick={() => this.changeSortOrder()}>
                                    <b style={{ fontSize: 20 }}><i className={this.state.descOrder ? "fa fa-sort-amount-desc" : "fa fa-sort-amount-asc"}></i></b>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    {
                                        this.state.firstColumnPosts.map((post, index) =>
                                            <div key={index}>
                                                {
                                                    post.isQuote == true ? <QuoteCardIndex urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} images={post.postImages} contentSourceLink={post.contentSourceLink} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} /> :
                                                        <TwitterCardIndex bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} isBookMarked={post.isBookMarked} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmark={() => this.bookmark(post.id)} bookmarkCount={post.bookmarkCount} urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} images={post.postImages} contentSourceLink={post.contentSourceLink} categories={post.postCategories.map((category, index) => { return category.category.name })} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                                }
                                                <br />
                                                {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    {
                                        this.state.secondColumnPosts.map((post, index) =>
                                            <div key={index}>
                                                {
                                                    post.isQuote == true ? <QuoteCardIndex urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} images={post.postImages} contentSourceLink={post.contentSourceLink} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} /> :
                                                        <TwitterCardIndex bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} isBookMarked={post.isBookMarked} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmarkCount={post.bookmarkCount} urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} images={post.postImages} contentSourceLink={post.contentSourceLink} categories={post.postCategories.map((category, index) => { return category.category.name })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                                }

                                                <br />
                                                {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    {
                                        this.state.thirdColumnPosts.map((post, index) =>
                                            <div key={index}>
                                                {
                                                    post.isQuote == true ? <QuoteCardIndex urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} images={post.postImages} contentSourceLink={post.contentSourceLink} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} /> :
                                                        <TwitterCardIndex bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} isBookMarked={post.isBookMarked} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmarkCount={post.bookmarkCount} urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} images={post.postImages} contentSourceLink={post.contentSourceLink} categories={post.postCategories.map((category, index) => { return category.category.name })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                                }
                                                <br />
                                                {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FolderSaveModal toggle={this.toggle} modal={this.state.modal} title={'Bookmark To'}>
                    <SavePost toggle={this.toggle} postId={this.state.selectedPostId}></SavePost>
                </FolderSaveModal>

                <ConfirmModal
                    toggle={this.toggle}
                    modal={this.state.unbookmarkModal}
                    title={'UnBookmark Confirmation'}
                    confirmText={'Are you sure you want to unbookmark post?'}
                    buttonText={'Unbookmark'}
                    onConfirm={this.unbookmark}
                />
            </div></>)
    }
}
