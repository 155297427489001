import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { getData } from '../../services/fetch';
import NumberFormat from 'react-number-format';
import '../../components/Shop/styles.css';
import { apiurl, arraySum } from '../../services/common';
import { defaultModel } from '../../components/DefaultModel';
import { connect } from 'react-redux';
import { inherits } from 'util';
import { TagCard, TwitterCardIndex } from '../../components/TwitterCard';

const fetchProducts = async (search, page, per_page, category) => {
    var url = apiurl + '/api/Post?page=' + page + '&per_page=' + per_page;
    url += search ? '&search=' + search : '';
    url += category ? '&category=' + category : '';
    var response = await getData(url);
    return response;
};

const fetchCategories = async () => {
    var url = apiurl + '/api/Setting/GetAllCategories';
    var response = await getData(url);

}

function useFetchProducts(searchVal, pageVal, per_pageVal, categoryVal, hasMoreItemsVal, loadmore) {
    const [per_page, setper_page] = useState(per_pageVal);
    const [hasMoreItems, sethasMoreItems] = useState(hasMoreItemsVal);
    const [category, setcategory] = useState(categoryVal);
    const [products, setproducts] = useState([]);
    const [init, setinit] = useState(true);

    const doingFetch = async (loadmore, isReset = false) => {
        var response = await fetchProducts(searchVal, isReset ? 1 : pageVal, per_page, isReset ? categoryVal : category);
        if (response.ok) {
            if (loadmore) {
                setproducts(prevProducts => ([...prevProducts, ...response.data.data]));
            } else {
                setproducts(response.data.data);
            }
            sethasMoreItems(response.data.data.length < per_page ? false : true);
            setcategory(categoryVal);
        }
    }

    useEffect(() => {
        if (init) {
            setinit(false);
            doingFetch(true);
        }
    }, [])

    useEffect(() => {
        if (!init && pageVal != 1) {
            doingFetch(true);
        }
    }, [pageVal]);

    useEffect(() => {
        if (!init) {
            doingFetch(false, true);
        }
    }, [searchVal, categoryVal]);

    return { per_page, category, hasMoreItems, products };
}

function ShopProducts(props) {
    const [categoryVal, setcategoryVal] = useState('');
    const [categoryName, setcategoryName] = useState('All');
    const [pageVal, setpageVal] = useState(1);
    const per_page_limit = 12;
    const { search, page, per_page, category, hasMoreItems, products } = useFetchProducts(props.search, pageVal, per_page_limit, categoryVal, true);

    useEffect(() => {
        setpageVal(1);
    }, [props.search, categoryVal])

    return (
        <div className="container">
            <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-5">
                    {/* <Categories onSelected={(id, name) => { setcategoryVal(id); setcategoryName(name) }} /> */}
                </div>
                <div className="col-xl-9 col-lg-8 col-md-7">
                    <div className="sidebar-categories mb-5">
                        {/* <div className="head">
                            {categoryName}
                        </div> */}
                    </div>
                    <section className="lattest-product-area pb-40 category-list">
                        <div className='row'>
                            <TagCard icon="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/ethereum-eth-icon.png" tag="Ethereum"></TagCard>
                            <TagCard selected={true} icon="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Binance-Coin-BNB-icon.png" tag="Binance"></TagCard>
                        </div>
                        <hr />
                        {/* <div className="row">
                            {
                                products.map((post, index) =>
                                    <div className="col-lg-4 col-md-6" key={index}>
                                        <Link to={{ pathname: "/User/Product", search: "?id=" + post.id }}>
                                            <TwitterCardIndex profileURL={post.contentCreatorProfileURL} username={post.contentCreatorUsername} content={post.content} viewCount={post.viewCount} />
                                        </Link>
                                    </div>
                                )
                            }
                        </div> */}
                        <div className='row'>
                            <div className='col-lg4 col-md6'>
                            {
                                    products.map((post, index) => 
                                        index / 3 == 0? 
                                            <div className='row' key={index}>
                                                <Link to={{ pathname: "/User/Product", search: "?id=" + post.id }}>
                                                    <TwitterCardIndex profileURL={post.contentCreatorProfileURL} username={post.contentCreatorUsername} content={post.content} viewCount={post.viewCount} />
                                                </Link>
                                            </div>
                                        :<></>
                                    )
                                }
                            </div>
                            <div className='col-lg4 col-md6'>
                            {
                                    products.map((post, index) => 
                                        index / 3 == 0? 
                                            <div key={index}>
                                                <Link to={{ pathname: "/User/Product", search: "?id=" + post.id }}>
                                                    <TwitterCardIndex profileURL={post.contentCreatorProfileURL} username={post.contentCreatorUsername} content={post.content} viewCount={post.viewCount} />
                                                </Link>
                                            </div>
                                        :<></>
                                    )
                                }
                            </div>
                            <div className='col-lg4 col-md6'>
                            {
                                    products.map((post, index) => 
                                        index / 3 == 0? 
                                            <div key={index}>
                                                <Link to={{ pathname: "/User/Product", search: "?id=" + post.id }}>
                                                    <TwitterCardIndex profileURL={post.contentCreatorProfileURL} username={post.contentCreatorUsername} content={post.content} viewCount={post.viewCount} />
                                                </Link>
                                            </div>
                                        :<></>
                                    )
                                }
                            </div>
                        </div>
                        {
                            hasMoreItems ? (
                                <div className='row'>
                                    <div className='offset-4 col-4'>
                                        <a className="btn btn-loadmore w-100" onClick={() => {
                                            setpageVal(pageVal + 1);
                                        }}>Load More</a>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        search: state.search
    }
};

export default connect(mapStateToProps)(ShopProducts);

export function Header(props) {
    const [search, setsearch] = useState('');
    const [shop, setshop] = useState(defaultModel.shop);

    useEffect(() => {
        const getLogo = async () => {
            // var response = await getData('/api/Shops/GetCurrentShopInfo');
            // if (response.ok) {
            //     setshop(response.data);
            // }
        };

        getLogo();
    }, [])

    return (
        <>
            <div className='sticky-wrapper'>
                <header className="header_area sticky-header">
                    <div className="main_menu">
                        <nav className="navbar navbar-expand-lg navbar-light main_box">
                            <div className="container">
                                <Link className="navbar-brand logo_h" to="/shop/index"><img src={"/img/shops/logos/" + shop.logo} alt="" height={100} /></Link>
                                <ul className="nav navbar-nav menu_nav ml-auto">
                                    <li className="nav-item">
                                        <button className="search" style={{
                                            background: 'transparent',
                                            border: 0,
                                            cursor: 'pointer',
                                            padding: 0,
                                        }}><span className="lnr lnr-magnifier" id="search"></span></button>
                                    </li>
                                </ul>
                                {/*                                 
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                                    <ul className="nav navbar-nav menu_nav ml-auto">
                                        <li className="nav-item"><a className="nav-link" href="index.html">Home</a></li>
                                        <li className="nav-item submenu dropdown active">
                                            <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                                aria-expanded="false">Shop</a>
                                            <ul className="dropdown-menu">
                                                <li className="nav-item active"><a className="nav-link" href="category.html">Shop Category</a></li>
                                                <li className="nav-item"><a className="nav-link" href="single-product.html">Product Details</a></li>
                                                <li className="nav-item"><a className="nav-link" href="checkout.html">Product Checkout</a></li>
                                                <li className="nav-item"><a className="nav-link" href="cart.html">Shopping Cart</a></li>
                                                <li className="nav-item"><a className="nav-link" href="confirmation.html">Confirmation</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item submenu dropdown">
                                            <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                                aria-expanded="false">Blog</a>
                                            <ul className="dropdown-menu">
                                                <li className="nav-item"><a className="nav-link" href="blog.html">Blog</a></li>
                                                <li className="nav-item"><a className="nav-link" href="single-blog.html">Blog Details</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item submenu dropdown">
                                            <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                                aria-expanded="false">Pages</a>
                                            <ul className="dropdown-menu">
                                                <li className="nav-item"><a className="nav-link" href="login.html">Login</a></li>
                                                <li className="nav-item"><a className="nav-link" href="tracking.html">Tracking</a></li>
                                                <li className="nav-item"><a className="nav-link" href="elements.html">Elements</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item"><a className="nav-link" href="contact.html">Contact</a></li>
                                    </ul>
                                    <ul className="nav navbar-nav navbar-right">
                                        <li className="nav-item"><a href="#" className="cart"><span className="ti-bag"></span></a></li>
                                        <li className="nav-item">
                                            <button className="search"><span className="lnr lnr-magnifier" id="search"></span></button>
                                        </li>
                                    </ul>
                                </div>
                             */}
                            </div>
                        </nav>
                    </div>
                    <div className="search_input" id="search_input_box">
                        <div className="container">
                            <form className="d-flex justify-content-between" onSubmit={(e) => {
                                e.preventDefault();
                                props.onSearched(search);
                            }}>
                                <input type="text" className="form-control" id="search_input" placeholder="Search Here" value={search} onChange={(e) => {
                                    setsearch(e.target.value);
                                }} />
                                <button type="submit" className="btn"></button>
                                <span className="lnr lnr-cross" id="close_search" title="Close Search" onClick={() => { setsearch(''); props.onSearched(''); }}></span>
                            </form>
                        </div>
                    </div>
                </header>
            </div>
            <section className="banner-area organic-breadcrumb">
                <div className="container">
                    <div className="breadcrumb-banner d-flex flex-wrap align-items-center justify-content-end">
                        <div className="col-first" style={{ width: 400 }}>
                            <h1>{shop.name}</h1>
                            <nav className="d-flex align-items-center">
                                <p>{shop.address}</p>
                            </nav>
                            <nav className="d-flex align-items-center">
                                <p>{shop.phone}</p>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

// export function Categories(props) {

//     const [categories, setcategories] = useState([]);

//     useEffect(() => {
//         const fetchCategories = async () => {
//             var response = await getData('/api/Setting/GetCategories');
//             if (response.ok) {
//                 setcategories(response.data);
//             }
//         };

//         fetchCategories();
//     }, []);

//     return (
//         <div className="sidebar-categories">
//             <div className="head">Browse Categories</div>
//             <ul className="main-categories">
//                 <li className="main-nav-list"><a data-toggle="collapse" href={'#all'}
//                     onClick={() => {
//                         props.onSelected('', 'All');
//                     }} aria-expanded="false" aria-controls=""><span
//                         className="lnr lnr-arrow-right"></span>All<span className="number">({arraySum(categories, 'itemCount')})</span></a>
//                 </li>
//                 {
//                     categories.filter(x => x.itemCount > 0).map((category, index) =>
//                         <li key={index} className="main-nav-list"><a data-toggle="collapse" href={'#' + category.value}
//                             onClick={() => {
//                                 props.onSelected(category.value, category.label);
//                             }} aria-expanded="false" aria-controls=""><span
//                                 className="lnr lnr-arrow-right"></span>{category.label}<span className="number">({category.itemCount})</span></a>
//                         </li>
//                     )
//                 }

//                 {/* <li className="main-nav-list"><a data-toggle="collapse" href="#meatFish" aria-expanded="false" aria-controls="meatFish"><span
//                     className="lnr lnr-arrow-right"></span>Meat and Fish<span className="number">(53)</span></a>
//                     <ul className="collapse" id="meatFish" data-toggle="collapse" aria-expanded="false" aria-controls="meatFish">
//                         <li className="main-nav-list child"><a href="#">Frozen Fish<span className="number">(13)</span></a></li>
//                         <li className="main-nav-list child"><a href="#">Dried Fish<span className="number">(09)</span></a></li>
//                         <li className="main-nav-list child"><a href="#">Fresh Fish<span className="number">(17)</span></a></li>
//                         <li className="main-nav-list child"><a href="#">Meat Alternatives<span className="number">(01)</span></a></li>
//                         <li className="main-nav-list child"><a href="#">Meat<span className="number">(11)</span></a></li>
//                     </ul>
//                 </li> */}
//             </ul>
//         </div>
//     )
// }