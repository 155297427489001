import React, { Component } from 'react';
import {
    Button,
    Form,
    Row,
    Col
} from "reactstrap";
import ColInput from '../../components/Form/Form.jsx';
import { postData, putData } from '../../services/fetch.js';
import { MessageModal } from '../../components/Tables/Modal.jsx';
import { defaultModel } from '../../components/DefaultModel.js';
import { apiurl, twitterbearer } from '../../services/common.js';
import TwitterCard, { EmbeddedCard } from '../../components/TwitterCard.js';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

export default class TweetForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model ? this.props.model : defaultModel.post,
            postId: '',
            messageModal: false,
            message: '',
            categoryList: [],
            chainList: [],
            selectedCategories: [],
            selectedCategoryItems: [],
            selectedChains: [],
            sourceLink: "",
            title: ""
        }
        this.updateModel = this.updateModel.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount = () => {
        this.getCategoryData();
        this.getChainData();
        if (this.props.action == "edit") {
            this.getPost();
        }
    }

    updateModel = (e) => {
        let { name, value } = e.target;
        var key = name;
        this.setState(state => ({
            model: Object.assign({}, state.model, { [key]: value })
        }));
    }

    changePostId = (e) => {
        var postId = e.target.value;
        this.setState({ postId: postId })
        if (this.props.action === 'create') {
            this.getTwitterData(postId);
        }
    }

    changeSelectedCategories = (e) => {
        var selectedCategories = [];
        var selectedCategoryItems = [];
        if (e != null) {
            e.map((item, i) => {
                selectedCategories.push({ categoryID: item.value });
                selectedCategoryItems.push(item);
            })
        }

        this.state.selectedCategories = selectedCategories;
        this.state.selectedCategoryItems = selectedCategoryItems;
        this.setState({ selectedCategories: this.state.selectedCategories, selectedCategoryItems: this.state.selectedCategoryItems });
    }

    changeSelectedChains = (e) => {
        var selectedChains = [];
        e.map((item, i) => {
            selectedChains.push({ chainID: item.value });
        })
        this.state.selectedChains = selectedChains;
        this.setState({ selectedChains: this.state.selectedChains });
    }

    chageContentSource = (e) => {
        this.state.sourceLink = e.target.value;
        this.setState({ sourceLink: this.state.sourceLink });
        var soureLinkSplits = this.state.sourceLink.split("/");
        if (soureLinkSplits.length > 1) {
            this.state.postId = soureLinkSplits[soureLinkSplits.length - 1]
            this.setState({ postId: this.state.postId });
            if (this.props.action === 'create') {
                this.getTwitterData(this.state.postId);
            }
        }

    }

    changeTitle = (e) => {
        this.state.title = e.target.value;
        this.setState({ title: this.state.title });
    }

    getCategoryData = () => {
        fetch(apiurl + "/api/Setting/GetAllCategories", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache'
            },
        }).then(async (response) => {
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                var data = [];
                if (response.status !== 204) {
                    var promise = response.json();
                    await promise.then(x => data = x);
                }
                var categoryList = [];
                data.map((category, i) => {
                    categoryList.push({ value: category.id, label: category.name });
                    console.log(category)
                })
                this.state.categoryList = categoryList;
                this.setState({ categoryList: this.state.categoryList });
            }
        })
    }

    getChainData = () => {
        fetch(apiurl + "/api/Setting/GetAllChains", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache'
            },
        }).then(async (response) => {
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                var data = [];
                if (response.status !== 204) {
                    var promise = response.json();
                    await promise.then(x => data = x);
                }
                var chainList = [];
                data.map((chain, i) => {
                    chainList.push({ value: chain.id, label: chain.name });
                })
                this.state.chainList = chainList;
                this.setState({ chainList: this.state.chainList });
            }
        })
    }

    getTwitterData = (postId) => {

        fetch(apiurl + "/api/Twitter/GetVxTweet?postId=" + postId + "&url="+this.state.sourceLink, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache'
            },
        }).then(async (response) => {
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                var data = [];
                if (response.status !== 204) {
                    var promise = response.json();
                    await promise.then(x => data = x);
                }
                if (data.contentSourceLink == null) {
                    data.contentSourceLink = this.state.model.contentSourceLink;
                }
                this.state.model = data;

                this.setState({ model: this.state.model });
                this.state.title = data.title;
                this.setState({ title: this.state.title });
                console.log(this.state.model);
            }
        })

    }

    getPost() {
        fetch(apiurl + "/api/Post/" + this.state.model.id, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache'
            },
        }).then(async (response) => {
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                var data = [];
                if (response.status !== 204) {
                    var promise = response.json();
                    await promise.then(x => data = x);
                }

                var selectedCategoryItems = [];
                this.state.model = data;
                this.state.title = data.title;
                var soureLinkSplits = data.contentSourceLink.split("/");
                this.state.postId = soureLinkSplits[soureLinkSplits.length - 1]
                this.state.sourceLink = data.contentSourceLink;
                this.state.selectedCategories = data.postCategories;
                data.postCategories.map((category, i) => {
                    selectedCategoryItems.push({ value: category.category.id, label: category.category.name, postId: this.state.postId });
                })
                this.state.selectedCategoryItems = selectedCategoryItems;
                this.setState({ model: this.state.model, title: this.state.title, selectedCategoryItems: this.state.selectedCategoryItems, sourceLink: this.state.sourceLink, selectedCategories: this.state.selectedCategories });
                console.log(this.state.model);
            }
        })
    }

    removeThread = (key) => {
        var postModel = this.state.model;
        postModel.postThreads.splice(key, 1);
        this.setState({ model: postModel });
    }

    save = async (e) => {
        e.preventDefault();
        var response = { ok: false, data: 'Something went wrong' };
        var postModel = this.state.model;
        postModel.title = this.state.title;
        postModel.contentSourceLink = this.state.sourceLink;
        postModel.postCategories = this.state.selectedCategories;
        postModel.postChains = this.state.selectedChains;
        postModel.postThreads = [];
        if (this.props.action === 'create') {
            response = await postData('/api/Post', postModel);
        } else if (this.props.action === 'edit') {
            console.log(postModel);
            response = await putData('/api/Post/' + this.state.model.id, postModel);
        }
        if (response.ok) {
            this.props.toggle();
            this.props.refreshTable();
        } else {
            this.setState({ messageModal: true, message: response.data });
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            messageModal: !prevState.messageModal
        }));
    }

    addImage = (imageURL, type = 0) => {
        this.state.model.postImages.push({ imageURL: imageURL, type: type });
        this.setState({ model: this.state.model });
    }

    removeImage = (i) => {
        this.state.model.postImages.splice(i, 1);
        this.setState({ model: this.state.model });
    }

    addEmbeddedUrl = (embeddedURL) => {
        var urls = [];
        if (this.state.model.urLs != null && this.state.model.urLs != "") {
            urls = JSON.parse(this.state.model.urLs);
        }
        urls.push(embeddedURL);
        this.state.model.urLs = JSON.stringify(urls);
        this.setState({ model: this.state.model });
    }

    removeEmbeddedUrl = (i, e) => {
        e.preventDefault();
        var urls = [];
        if (this.state.model.urLs != null && this.state.model.urLs != "") {
            urls = JSON.parse(this.state.model.urLs);
        }
        urls.splice(i, 1);
        this.state.model.urLs = JSON.stringify(urls);
        this.setState({ model: this.state.model });
    }

    render() {
        const post = this.state.model;
        return (
            <div>
                <MessageModal
                    toggle={this.toggle}
                    modal={this.state.messageModal}
                    title={'Error'}
                    message={this.state.message}
                />
                <Form onSubmit={this.save}>
                    <h6 className="heading-small text-muted mb-4">
                        Post information
                    </h6>
                    <div className="pl-lg-4">
                        <Row>
                            <ColInput
                                col={6}
                                label={'PostId'}
                                defaultValue={this.state.model.postId}
                                id={'PostId'}
                                type={'text'}
                                value={this.state.postId}
                                onChange={this.changePostId}
                                required={true}
                            />
                            <ColInput
                                col={6}
                                label={'Content Source Link'}
                                defaultValue={this.state.model.contentSourceLink}
                                name={'contentSourceLink'}
                                id={'ContentSourceLink'}
                                type={'text'}
                                onChange={(e) => this.chageContentSource(e)}
                                required={true}
                            />
                            <ColInput
                                col={6}
                                label={'Title'}
                                value={this.state.title}
                                id={'Title'}
                                type={'textarea'}
                                onChange={(e) => this.changeTitle(e)}
                                required={true}
                            />
                            <Col md={6}>
                                <label className='form-control-label'>Categories</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={this.state.categoryList}
                                    onChange={(e) => this.changeSelectedCategories(e)}
                                    value={this.state.selectedCategoryItems}
                                />
                            </Col>
                            {/* <Col md={6}>
                                <label className='form-control-label'>Chains</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={this.state.chainList}
                                    onChange={(e) => this.changeSelectedChains(e)}
                                />
                            </Col> */}
                        </Row>
                        <br /><br />
                        {
                            post.content != '' ? (<div><h3>Tweet</h3><TwitterCard addImage={this.addImage} addEmbeddedUrl={this.addEmbeddedUrl} removeImage={this.removeImage} removeEmbeddedUrl={this.removeEmbeddedUrl} profileURL={post.contentCreatorProfileURL} username={post.contentCreatorUsername} content={post.content} images={post.postImages} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : []} urls={post.urLs != null ? JSON.parse(post.urLs) : []} />
                            </div>) : (<></>)
                        }
                        {
                            post.postImages != null && post.postImages.length > 0 ? <></> : post.urLs != null ? JSON.parse(post.urLs).map((url, i) =>
                                <a href={url.url}><div>
                                    <EmbeddedCard displayURL={url.display_url} title={url.title} description={url.description} image={url.images != null ? url.images[0].url : null} ></EmbeddedCard>
                                </div></a>
                            ) : <></>
                        }
                    </div>
                    <br /><br />
                    <div className="pl-lg-4 text-right">
                        <Button color="primary" type="submit">Save</Button>
                        <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                    </div>
                </Form>
            </div>
        )
    }
}
