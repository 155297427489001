import React, { Component } from 'react';
import {
    Button,
    Form,
    Row,
    Col
} from "reactstrap";
import ColInput from '../../components/Form/Form.jsx';
import { postData, putData } from '../../services/fetch.js';
import { MessageModal } from '../../components/Tables/Modal.jsx';
import { defaultModel } from '../../components/DefaultModel.js';
import { apiurl, twitterbearer } from '../../services/common.js';
import TwitterCard, { EmbeddedCard, EmbeddedCardAddForm } from '../../components/TwitterCard.js';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

export default class PostForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model ? this.props.model : defaultModel.post,
            postId: '',
            messageModal: false,
            message: '',
            categoryList: [],
            chainList: [],
            selectedCategories: [],
            selectedCategoryItems: [],
            selectedChains: [],
            sourceLink: "",
            title: "",
            errorThreadNo: -1,
            isLoading: false,
            contentCreator:''
        }
        this.updateModel = this.updateModel.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount = () => {
        this.getCategoryData();
        this.getChainData();
        if (this.props.action == "edit") {
            this.getPost();
        }
    }

    updateModel = (e) => {
        let { name, value } = e.target;
        var key = name;
        this.setState(state => ({
            model: Object.assign({}, state.model, { [key]: value })
        }));
    }

    changePostId = (e) => {
        var postId = e.target.value;
        this.state.postId = postId;
        this.setState({ postId: this.state.postId })
        console.log(this.state.postId);
        console.log(this.state.sourceLink)
        if (this.state.postId != "" && this.state.sourceLink != "") {
            this.getTwitterData();
        }

    }

    changeSelectedCategories = (e) => {
        var selectedCategories = [];
        var selectedCategoryItems = [];
        if (e != null) {
            e.map((item, i) => {
                selectedCategories.push({ categoryID: item.value });
                selectedCategoryItems.push(item);
            })
        }

        this.state.selectedCategories = selectedCategories;
        this.state.selectedCategoryItems = selectedCategoryItems;
        this.setState({ selectedCategories: this.state.selectedCategories, selectedCategoryItems: this.state.selectedCategoryItems });
    }

    changeSelectedChains = (e) => {
        var selectedChains = [];
        e.map((item, i) => {
            selectedChains.push({ chainID: item.value });
        })
        this.state.selectedChains = selectedChains;
        this.setState({ selectedChains: this.state.selectedChains });
    }

    chageContentSource = (e) => {
        this.state.sourceLink = e.target.value;
        this.setState({ sourceLink: this.state.sourceLink });
        if (this.state.postId != "" && this.state.sourceLink != "") {
            this.getTwitterData();
        }
    }

    changeTitle = (e) => {
        this.state.title = e.target.value;
        this.setState({ title: this.state.title });
    }

    changeContentCreator = (e) => {
        this.state.contentCreator = e.target.value;
        this.setState({ contentCreator: this.state.contentCreator });
    }

    getCategoryData = () => {
        fetch(apiurl + "/api/Setting/GetAllCategories", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache'
            },
        }).then(async (response) => {
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                var data = [];
                if (response.status !== 204) {
                    var promise = response.json();
                    await promise.then(x => data = x);
                }
                var categoryList = [];
                data.map((category, i) => {
                    categoryList.push({ value: category.id, label: category.name });
                    console.log(category)
                })
                this.state.categoryList = categoryList;
                this.setState({ categoryList: this.state.categoryList });
            }
        })
    }

    getChainData = () => {
        fetch(apiurl + "/api/Setting/GetAllChains", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache'
            },
        }).then(async (response) => {
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                var data = [];
                if (response.status !== 204) {
                    var promise = response.json();
                    await promise.then(x => data = x);
                }
                var chainList = [];
                data.map((chain, i) => {
                    chainList.push({ value: chain.id, label: chain.name });
                })
                this.state.chainList = chainList;
                this.setState({ chainList: this.state.chainList });
            }
        })
    }

    getTwitterData = () => {
        this.setState({ isLoading: true })
        fetch(apiurl + "/api/Twitter/GetTweets?postId=" + this.state.postId + "&url=" + this.state.sourceLink, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache'
            },
        }).then(async (response) => {
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                var data = [];
                if (response.status !== 204) {
                    var promise = response.json();
                    await promise.then(x => data = x);
                }
                if (data.contentSourceLink == null) {
                    data.contentSourceLink = this.state.model.contentSourceLink;
                }
                this.state.model = data;

                this.setState({ model: this.state.model });
                this.state.title = data.title;
                this.setState({ title: this.state.title });
                this.state.contentCreator = data.contentCreator;
                this.setState({contentCreator : this.state.contentCreator});
                this.setState({ isLoading: false });
                console.log(this.state.model);
            }
        })

    }

    getPost() {
        fetch(apiurl + "/api/Post/" + this.state.model.id, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache'
            },
        }).then(async (response) => {
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                var data = [];
                if (response.status !== 204) {
                    var promise = response.json();
                    await promise.then(x => data = x);
                }
                var soureLinkSplits = data.contentSourceLink.split("/");
                var selectedCategoryItems = [];
                this.state.model = data;
                this.state.title = data.title;
                this.state.contentCreator = data.contentCreator;
                this.state.postId = soureLinkSplits[soureLinkSplits.length - 1]
                data.postCategories.map((category, i) => {
                    selectedCategoryItems.push({ value: category.category.id, label: category.category.name, postId: this.state.postId });
                })
                this.state.selectedCategoryItems = selectedCategoryItems;
                this.setState({ model: this.state.model, title: this.state.title, contentCreator: this.state.contentCreator, selectedCategoryItems: this.state.selectedCategoryItems });
                console.log(this.state.model);
            }
        })
    }

    removeThread = (key) => {
        var postModel = this.state.model;
        postModel.postThreads.splice(key, 1);
        this.setState({ model: postModel });
    }

    save = async (e) => {
        e.preventDefault();
        var response = { ok: false, data: 'Something went wrong' };
        var postModel = this.state.model;
        postModel.contentSourceLink = this.state.sourceLink;
        postModel.title = this.state.title;
        postModel.contentCreator = this.state.contentCreator;
        // postModel.contentSourceLink = this.state.sourceLink;
        postModel.postCategories = this.state.selectedCategories;
        postModel.postChains = this.state.selectedChains;
        if (this.props.action === 'create') {
            response = await postData('/api/Post', postModel);
        } else if (this.props.action === 'edit') {
            response = await putData('/api/Post/' + this.state.model.id, postModel);
        }
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.props.toggle();
                this.props.refreshTable();
            } else {
                this.setState({ messageModal: true, message: response.data.text, errorThreadNo: response.data.data?response.data.data.lineNo:0 });
            }

        } else {
            this.setState({ messageModal: true, message: response.data });
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            messageModal: !prevState.messageModal
        }));
    }

    editThread = (i, value) => {
        this.state.model.postThreads[i].content = value;
        console.log("value", value);
        this.setState({ model: this.state.model });
        console.log(this.state.model);
    }

    addImage = (imageURL, type) => {
        this.state.model.postImages.push({ imageURL: imageURL, type: type });
        this.setState({ model: this.state.model });
    }

    addThreadImage = (threadNumber, imageURL, type) => {
        var thread = this.state.model.postThreads[threadNumber];
        thread.postThreadImages.push({ imageURL: imageURL, type: type });
        this.state.model.postThreads[threadNumber] = thread;
        this.setState({ model: this.state.model });
    }

    removeImage = (i) => {
        this.state.model.postImages.splice(i, 1);
        this.setState({ model: this.state.model });
    }

    removeThreadImage = (threadNumber, i) => {
        var thread = this.state.model.postThreads[threadNumber];
        thread.postThreadImages.splice(i, 1);
        this.state.model.postThreads[threadNumber] = thread;
        this.setState({ model: this.state.model });
    }

    addEmbeddedUrl = (embeddedURL) => {
        var urls = [];
        if (this.state.model.urLs != null && this.state.model.urLs != "") {
            urls = JSON.parse(this.state.model.urLs);
        }
        urls.push(embeddedURL);
        this.state.model.urLs = JSON.stringify(urls);
        this.setState({ model: this.state.model });
    }

    addThreadEmbeddedUrl = (threadNumber, embeddedURL) => {
        var urls = [];
        var thread = this.state.model.postThreads[threadNumber];
        if (thread.urLs != null && thread.urLs != "") {
            urls = JSON.parse(thread.urLs);
        }
        urls.push(embeddedURL);
        thread.urLs = JSON.stringify(urls);
        this.state.model.postThreads[threadNumber] = thread;
        this.setState({ model: this.state.model });
    }

    removeEmbeddedUrl = (i, e) => {
        e.preventDefault();
        var urls = [];
        if (this.state.model.urLs != null && this.state.model.urLs != "") {
            urls = JSON.parse(this.state.model.urLs);
        }
        urls.splice(i, 1);
        this.state.model.urLs = JSON.stringify(urls);
        this.setState({ model: this.state.model });
    }

    removeThreadEmbeddedUrl = (threadNumber, i, e) => {
        e.preventDefault();
        var urls = [];
        var thread = this.state.model.postThreads[threadNumber];
        if (thread.urLs != null && thread.urLs != "") {
            urls = JSON.parse(thread.urLs);
        }
        urls.splice(i, 1);
        thread.urLs = JSON.stringify(urls);
        this.state.model.postThreads[threadNumber] = thread;
        this.setState({ model: this.state.model });
    }

    addThread = (e) => {
        e.preventDefault();
        var newThread = { contentCreatorProfileURL: this.state.model.contentCreatorProfileURL, contentCreatorUsername: this.state.model.contentCreatorUsername, postThreadImages: [] };
        var threads = this.state.model.postThreads;
        if (threads == null) {
            threads = [newThread];
        } else {
            threads.push(newThread);
        }
        this.state.model.postThreads = threads;
        this.setState({ model: this.state.model });
    }



    render() {
        const post = this.state.model;
        return (
            <div>
                
                <div>
                    <MessageModal
                        toggle={this.toggle}
                        modal={this.state.messageModal}
                        title={'Error'}
                        message={this.state.message}
                    />
                    <Form onSubmit={this.save}>
                        <h6 className="heading-small text-muted mb-4">
                            Post information
                        </h6>
                        <div className="pl-lg-4">
                            <Row>
                                <ColInput
                                    col={6}
                                    label={'PostId'}
                                    defaultValue={this.state.model.postId}
                                    id={'PostId'}
                                    type={'text'}
                                    value={this.state.postId}
                                    onChange={this.changePostId}
                                    required={true}
                                />
                                <ColInput
                                    col={6}
                                    label={'Content Source Link'}
                                    defaultValue={this.state.model.contentSourceLink}
                                    name={'contentSourceLink'}
                                    id={'ContentSourceLink'}
                                    type={'text'}
                                    onChange={(e) => { this.updateModel(e); this.chageContentSource(e) }}
                                    required={true}
                                />
                                <ColInput
                                    col={6}
                                    label={'Title'}
                                    value={this.state.title}
                                    id={'Title'}
                                    type={'textarea'}
                                    onChange={(e) => this.changeTitle(e)}
                                    required={true}
                                />
                                <Col md={6}>
                                    <label className='form-control-label'>Tags</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={this.state.categoryList}
                                        onChange={(e) => this.changeSelectedCategories(e)}
                                        value={this.state.selectedCategoryItems}
                                    />
                                </Col>
                                <ColInput
                                    col={6}
                                    label={'Content Creator ID'}
                                    value={this.state.contentCreator}
                                    id={'Content Creator'}
                                    type={'textarea'}
                                    onChange={(e) => this.changeContentCreator(e)}
                                    required={true}
                                />
                                {/* <Col md={6}>
                                <label className='form-control-label'>Chains</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={this.state.chainList}
                                    onChange={(e) => this.changeSelectedChains(e)}
                                />
                            </Col> */}
                            </Row>
                            <br /><br />
                            <div className='col-md-12' align='center'>
                                {
                                    this.state.isLoading? <div className="lds-dual-ring"></div> : <div></div>
                                }
                            </div>
                            {this.state.model.source!=null?(this.state.model.source==0?<h3>Fetched From Twitters API</h3>:<h3>Fetched From Scrapped API</h3>):<></>}
                            {
                                post.content != '' ? (<div><h3>Tweet</h3><TwitterCard isError={this.state.errorThreadNo == 0} addImage={this.addImage} removeImage={this.removeImage} profileURL={post.contentCreatorProfileURL} username={post.contentCreatorUsername} content={post.content} images={post.postImages} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : []} urls={post.urLs != null ? JSON.parse(post.urLs) : []} />
                                </div>) : (<></>)
                            }

                            {
                                post.postImages != null && post.postImages.length > 0 ? <></> : post.urLs != null ? JSON.parse(post.urLs).map((url, i) =>
                                    <div style={{ padding: 10, borderStyle: 'solid', borderRadius: 20, borderWidth: 'thin' }}>
                                        <div className='row'>
                                            <div className='col-md-11'></div>
                                            <div className='col-md-1'><button className='btn'>x</button></div>
                                        </div>
                                        <a href={url.url}><div>
                                            <EmbeddedCard displayURL={url.display_url} title={url.title} description={url.description} image={url.images != null ? url.images[0].url : null} ></EmbeddedCard>
                                        </div></a>
                                    </div>
                                ) : <></>

                            }
                            <br />
                            {
                                (post.postImages != null && post.postImages.lenngth > 0) || post.content == '' ? <></> :
                                    <><EmbeddedCardAddForm addEmbeddedURL={this.addEmbeddedUrl} removeEmbeddedURL={this.removeEmbeddedUrl}></EmbeddedCardAddForm></>
                            }

                            <div className='container'>
                                {
                                    post.postThreads != null ? (
                                        post.postThreads.length > 0 ? (post.postThreads.map((item, i) =>
                                            <div className='container'>
                                                <hr />
                                                {i == 0 ? (<h3>Threads</h3>) : (<></>)}
                                                <div className='row'>
                                                    <div className='col-md-9'></div>
                                                    <div className='col-md-3'>
                                                        <Button color="danger" onClick={() => this.removeThread(i)}>Remove</Button>
                                                    </div>
                                                    <TwitterCard isThread={true} threadNumber={i} isError={this.state.errorThreadNo == i + 1} addImage={this.addThreadImage} removeImage={this.removeThreadImage} editable={true} edit={this.editThread} number={i} profileURL={item.contentCreatorProfileURL} username={item.contentCreatorUsername} content={item.content} images={item.postThreadImages} mentions={item.mentions != undefined ? JSON.parse(item.mentions) : []} />
                                                    {
                                                        item.postThreadImages != null && item.postThreadImages.length > 0 ? <></> : item.urLs != null ? JSON.parse(item.urLs).map((url, j) =>
                                                            <div style={{ padding: 10, borderStyle: 'solid', borderRadius: 20, borderWidth: 'thin' }}>
                                                                <div className='row'>
                                                                    <div className='col-md-11'></div>
                                                                    <div className='col-md-1'><button className='btn' onClick={(e) => this.removeThreadEmbeddedUrl(i, j, e)}>x</button></div>
                                                                </div>
                                                                <a href={url.url}><div>
                                                                    <EmbeddedCard displayURL={url.display_url} title={url.title} description={url.description} image={url.images != null ? url.images[0].url : null} ></EmbeddedCard>
                                                                </div></a>
                                                            </div>
                                                        ) : <></>
                                                    }
                                                    <br />
                                                    {
                                                        item.postThreadImages != null && item.postThreadImages.length > 0 ? <></> : <>
                                                            <EmbeddedCardAddForm addEmbeddedURL={this.addThreadEmbeddedUrl} isThread={true} threadNumber={i}></EmbeddedCardAddForm>
                                                        </>
                                                    }
                                                </div>

                                            </div>
                                        )

                                        ) : (<></>)

                                    ) : (<></>)

                                }
                                <br />
                                <div className='col-md-12' align='center'>
                                    <button className='btn btn-default' align='center' onClick={(e) => this.addThread(e)}>Add New Thread</button>
                                </div>

                            </div>
                        </div>
                        <br /><br />
                        <div className="pl-lg-4 text-right">
                            <Button color="primary" type="submit">Save</Button>
                            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        )
    }
}
