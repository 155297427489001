import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { getData } from 'services/fetch';
import MaterialTable from "material-table";
import DetailPanel from "../../components/Tables/DetailPanel";
import SimpleModal, { ConfirmModal } from "../../components/Tables/Modal.jsx";
import {
  Redirect,
} from "react-router-dom";
import { defaultModel } from "../../components/DefaultModel";
import { deleteData } from "../../services/fetch";
import { getDateForInput } from "../../services/date";
import { apiurl } from "../../services/common";
import ItemFlavorForm from "./ItemFlavorForm";

class ItemFlavors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: null,
      modal: false,
      model: defaultModel.itemFlavor,
      action: '',
      redirectToLogin: false,
      disableEditButton: true,
      selectedIds: [],
    };

    this.tableRef = React.createRef();
    this.delete = this.delete.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: false,
      deleteModal: false,
      model: defaultModel.itemFlavor
    });
  }

  refreshTable = () => {
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  delete = async () => {
    var response = await deleteData('/api/ItemFlavor/DeleteManyItemFlavors', this.state.selectedIds);
    if (response.ok) {
      this.toggle();
      this.refreshTable();
    }
  }

  render() {
    const thisprops = this;
    if (this.state.redirectToLogin) return <Redirect to={{
      pathname: "/auth/login",
      state: { from: this.props.location }
    }} />
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--9" fluid>

          <MaterialTable
            title="Item Flavors"
            tableRef={this.tableRef}
            columns={[
              { title: 'Flavor', field: 'flavor' },
              { title: 'Remark', field: 'remark' },
            ]}
            data={async function (query) {
              let url = '/api/ItemFlavor?'
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              url += '&search=' + query.search
              var result = await getData(url);
              if (result.ok) {
                var data = result.data;
                return {
                  data: data.data,
                  page: data.page - 1,
                  totalCount: data.total
                }
              } else if (result.status === 401) {
                thisprops.setState({ redirectToLogin: true });
              } else {
                return {
                  data: [],
                  page: 1,
                  totalCount: 0
                }
              }
            }}
            options={{
              search: true,
              selection: true,
              debounceInterval: 500
            }}
            onSelectionChange={(rows) => {
              this.setState(state => ({
                disableEditButton: rows.length > 1,
                selectedIds: rows.map(row => row.id)
              }));
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: this.refreshTable
              },
              {
                icon: 'add_circle',
                tooltip: 'Add Item Flavor',
                isFreeAction: true,
                onClick: () => { this.setState({ action: 'create', modal: true }); },
              },
              {
                tooltip: 'Edit A Selected Item Flavor',
                icon: 'edit',
                disabled: this.state.disableEditButton,
                onClick: async (evt, data) => {
                  this.setState({ modal: true, model: data[0], action: 'edit' });
                }
              },
              {
                tooltip: 'Remove All Selected Item Flavors',
                icon: 'delete',
                onClick: (evt, data) => this.setState({ deleteModal: true })
              }
            ]}
          />

          <SimpleModal toggle={this.toggle} modal={this.state.modal} title={'Item Flavor'}>
            <ItemFlavorForm
              model={this.state.model}
              action={this.state.action}
              toggle={this.toggle}
              refreshTable={this.refreshTable}
            />
          </SimpleModal>
          <ConfirmModal
            toggle={this.toggle}
            modal={this.state.deleteModal}
            title={'Delete Confirmation'}
            confirmText={'Are you sure you want to delete all selected item flavors?'}
            buttonText={'Delete'}
            onConfirm={this.delete}
          />
        </Container>
      </>
    );
  }
}

export default ItemFlavors;
