import React, { Component } from 'react'
import { defaultModel } from '../../components/DefaultModel';
import { getData, postData } from '../../services/fetch';
import NumberFormat from 'react-number-format';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import TwitterCard, { CommentCard, EmbeddedCard, TwitterCardDetail } from '../../components/TwitterCard';
import { apiurl, s3HostURL } from '../../services/common';
import { Row } from 'reactstrap';
import { FolderSaveModal } from '../../components/Tables/Modal';
import { SavePost } from '../Folder/Folder';

const options = {
    items: 1,
    nav: true,
    rewind: true,
    autoplay: false,
    dots: true
};

const events = {
    onDragged: (e) => { },
    onChanged: (e) => { }
};

export default class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: defaultModel.post,
            comments: [],
            comment: '',
            postId: '',
            like: true,
            likeCount: 0,
            commentModal: false,
            threadCount: 0,
            totalLikeCount: 0,
            totalViewCount: 0,
            savedPostIds: [],
            modal: false,
            isMobile: false,
            isTablet: false
        }
    }

    handleResize = () => {
        this.setState({
            isMobile: window.innerWidth <= 768, isTablet: window.innerWidth <= 992 && window.innerWidth > 768
        });
    }

    toogleCommentModal() {
        this.setState({ commentModal: !this.state.commentModal })
    }

    async componentDidMount() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();

        let params = new URLSearchParams(this.props.location.search);
        var id = params.get('id');
        this.setState({ postId: id })
        var response = await getData(apiurl + '/api/Post/' + id);
        if (response.ok) {
            this.setState({ product: response.data });
        }
        console.log(this.state.product);
        await this.getComments();
        await this.getLikeInfo();
        await this.getTwitterUserInfo();
        await this.fetchSavedPostIds();
    }

    fetchSavedPostIds = async () => {
        var url = apiurl + '/api/User/GetAllUserSavedPostIds'
        var response = await getData(url);
        if (response.ok) {
            this.state.savedPostIds = response.data.data;
            this.setState({ savedPostIds: this.state.savedPostIds });
        }
    }

    async comment() {
        if (this.state.comment != '') {
            var data = { postId: this.state.postId, description: this.state.comment }
            var response = await postData('/api/User/Comment', data);
            if (response.ok) {
                this.setState({ comment: '' })
                await this.getComments();
            }
        }

    }

    async getLikeInfo() {
        var response = await getData(apiurl + '/api/User/GetPostLikeInfo?postId=' + this.state.postId);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.setState({ like: response.data.data.isLiked });
                this.setState({ likeCount: response.data.data.likeCount })
            }

        }
    }

    async getTwitterUserInfo() {
        var response = await getData(apiurl + '/api/Setting/GetTwitterUserProfile?userId=' + this.state.product.contentCreator);
        if (response.ok) {
            this.setState({ threadCount: response.data.threadCount, totalLikeCount: response.data.likeCount, totalViewCount: response.data.viewCount });

        }
    }

    async getComments() {
        var response = await getData(apiurl + '/api/User/GetComments?postId=' + this.state.postId);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.setState({ comments: response.data.data });
            }

        }
    }

    async likeUnlike() {
        if (this.state.like) {
            this.setState({ likeCount: this.state.likeCount - 1 })
        } else {
            this.setState({ likeCount: this.state.likeCount + 1 })
        }
        this.setState({ like: !this.state.like })
        var response = await postData('/api/User/LikeUnlikePost?postId=' + this.state.postId);
        if (response.ok) {

        }
    }

    bookmark = (id) => {
        this.setState({ selectedPostId: id, modal: true })
    }

    toggle = async () => {
        this.setState({
            modal: false
        })
        await this.fetchSavedPostIds();
    }

    render() {
        var post = this.state.product;
        return (
            <>
                <div>
                    <div style={{ zIndex: 100, position: 'fixed', top: 0, width: 380, height: 1000, backgroundColor: 'white', padding: 50, paddingBottom: 300, right: this.state.commentModal ? 0 : -380, transition: '1s', overflow: 'auto' }} >
                        <div>
                            <div className='row'>
                                <div className='col-md-10 col-10'>
                                    <h2>Comments ({post.commentCount})</h2>
                                </div>
                                <div className='col-md-2 col-2'>
                                    <i className='fa fa-times' style={{ cursor: 'pointer' }} onClick={() => this.toogleCommentModal()}></i>
                                </div>
                            </div>

                            <br />
                            <div className='row'>
                                <div className='col-md-12'>
                                    <input type='text' placeholder='What are your thoughts?' className='form-control' value={this.state.comment} onChange={(e) => { this.setState({ comment: e.target.value }) }} onKeyDown={(e) => e.key == "Enter" ? this.comment() : null} />
                                </div>
                                {/* <div className='col-md-2'><button className='btn btn-primary' onClick={() => this.comment()}>Comment</button></div> */}
                            </div>
                            <br />
                            {
                                this.state.comments.length > 0 ? <>
                                    {this.state.comments.map((cmt, i) =>
                                        <>

                                            <CommentCard username={cmt.name} comment={cmt.comment} profileURL={s3HostURL + "profile-images/" + cmt.imageURL}></CommentCard>
                                            <hr />
                                        </>

                                    )}
                                </> : <></>
                            }
                            <br />

                        </div>
                    </div>
                </div>

                <div className={this.state.isMobile ? "container-fluid" : "container"}>
                    {this.state.isMobile ? <></> :
                        <div style={{ position: 'fixed', marginTop: window.innerHeight / 3, zIndex: 100 }}>
                            <div style={{ backgroundColor: 'white', borderRadius: 50, width: 50, height: 50, paddingTop: 15, cursor: 'pointer' }} align='center' onClick={() => !this.state.savedPostIds.includes(this.state.product.id) ? this.bookmark(this.state.product.id) : null}>
                                <i className={this.state.savedPostIds.includes(post.id) ? 'fa fa-bookmark' : 'fa fa-bookmark-o'} style={{ fontSize: 20 }}></i>
                            </div>
                            <br />
                            <div style={{ backgroundColor: 'white', borderRadius: 50, width: 50, height: 50, paddingTop: 15, cursor: 'pointer' }} align='center'>
                                <i className='fa fa-share-alt' style={{ fontSize: 20 }}></i>
                            </div>
                            <br />
                            <div onClick={() => this.toogleCommentModal()} style={{ backgroundColor: this.state.commentModal ? 'black' : 'white', color: this.state.commentModal ? 'white' : 'black', borderRadius: 50, width: 50, height: 50, paddingTop: 15, cursor: 'pointer' }} align='center'>
                                <i className='fa fa-commenting-o' style={{ fontSize: 20 }} ></i>
                            </div>
                        </div>
                    }


                    <div className={this.state.isMobile ? "container-fluid" : "container"} style={{ padding: this.state.isMobile ? 5 : 50, paddingLeft: this.state.isMobile ? 5 : 60 }}>

                        {/* <h3>Tweet</h3>
                    <TwitterCard profileURL={post.contentCreatorProfileURL} username={post.contentCreatorUsername} content={post.content} images={post.postImages} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : []} urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} />
                    {
                        post.postImages != null && post.postImages.length > 0 ? <></> : post.urLs != null ? JSON.parse(post.urLs).map((url, i) =>
                            <a href={url.url}><div>
                                <EmbeddedCard displayURL={url.display_url} title={url.title} description={url.description} image={url.images != null ? url.images[0].url : null} ></EmbeddedCard>
                            </div></a>
                        ) : <></>
                    }
                    <div className='container' style={{ marginLeft: 40 }}>
                        {post.postThreads.length > 0 ? (<>
                            {
                                post.postThreads.map((item, i) =>
                                    <>
                                        <hr />
                                        {i == 0 ? (<h3>Threads</h3>) : (<></>)}
                                        <TwitterCard profileURL={item.contentCreatorProfileURL} username={item.contentCreatorUsername} content={item.content} images={item.postThreadImages} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : []} urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} />
                                        {
                                            item.postThreadImages != null && item.postThreadImages.length > 0 ? <></> : item.urLs != null ? JSON.parse(item.urLs).map((url, i) =>
                                                <a href={url.url}><div>
                                                    <EmbeddedCard displayURL={url.display_url} title={url.title} description={url.description} image={url.images != null ? url.images[0].url : null} ></EmbeddedCard>
                                                </div></a>
                                            ) : <></>
                                        }
                                    </>

                                )
                            }
                        </>) : (<></>)}
                    </div> */}
                        <div className={this.state.isMobile ? "container-fluid" : "container"} align='center'>
                            <div className='row'>
                                {this.state.isMobile ? <></> : <div className='col-md-1'></div>}

                                <div style={{ textAlign: 'left' }} className={this.state.isMobile ? "col-12" : "col-md-10"}>
                                    {this.state.isMobile || this.state.isTablet ?
                                        <div>
                                            <div style={{ paddingTop: 10, backgroundColor: 'black', borderRadius: 50, color: 'white' }} className='col-md-10'>
                                                {/* <div className='container-fluid'> */}
                                                <div>
                                                    <img src={post.contentCreatorProfileURL.startsWith("https") ? post.contentCreatorProfileURL : s3HostURL + "twitter-profile-images/" + post.contentCreatorProfileURL} width="60" height="60" style={{ borderRadius: 50, marginLeft: 15 }} />
                                                </div>
                                                <div style={{ paddingBottom: 5 }}><b style={{ fontSize: 16, marginLeft: 15 }}>{"@"+post.contentCreatorUsername}</b><br /><p style={{ fontSize: 14, marginLeft: 15, marginTop: 5 }}><i class="fa fa-circle" style={{ fontSize: 6 }}></i> &nbsp; <b>{this.state.threadCount}</b> Threads on Threadoor <br /> <i class="fa fa-circle" style={{ fontSize: 6 }}></i> &nbsp; <b>{this.state.totalLikeCount}</b> Total Likes <br /> <i class="fa fa-circle" style={{ fontSize: 6 }}></i> &nbsp; <b>{this.state.totalViewCount}</b> Total Views</p></div>

                                                {/* </div> */}

                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div style={{ paddingTop: 10, backgroundColor: 'black', borderRadius: 50, color: 'white' }} className='col-md-10'>
                                                {/* <div className='container-fluid'> */}
                                                <div className='row'>
                                                    <div className='col-md-1'>
                                                        <img src={post.contentCreatorProfileURL.startsWith("https") ? post.contentCreatorProfileURL : s3HostURL + "twitter-profile-images/" + post.contentCreatorProfileURL} width="60" height="60" style={{ borderRadius: 50, marginLeft: -7 }} />
                                                    </div>
                                                    <div className='col-md-11'><b style={{ fontSize: 16, marginLeft: 15 }}>{"@"+post.contentCreatorUsername}</b><br /><p style={{ fontSize: 14, marginLeft: 15, marginTop: 5 }}><i class="fa fa-circle" style={{ fontSize: 6 }}></i> &nbsp; <b>{this.state.threadCount}</b> Threads on Threadoor &nbsp; <i class="fa fa-circle" style={{ fontSize: 6 }}></i> &nbsp; <b>{this.state.totalLikeCount}</b> Total Likes &nbsp; <i class="fa fa-circle" style={{ fontSize: 6 }}></i> &nbsp; <b>{this.state.totalViewCount}</b> Total Views</p></div>
                                                </div>
                                                {/* </div> */}

                                            </div>
                                        </div>
                                    }


                                    <br />
                                    <h1>{post.title ? post.title : post.content}</h1>
                                    <div className='container-fluid'>
                                        <div style={{ color: 'black', fontSize: 11, fontWeight: 'bold' }} className='row'>
                                            {post.postCategories.map((category, index) =>
                                                <span style={{ marginRight: 5 }}><div style={{ borderStyle: 'solid', borderColor: 'grey', borderRadius: 10, borderWidth: 0.8, padding: 4, display: 'inline-block' }}>{category.category.name}</div></span>
                                            )}
                                        </div>
                                    </div>

                                    <br />
                                    <div className='row'>
                                        <div className='col-md-2' style={{ borderRightStyle: this.state.isMobile ? 'none' : 'solid', borderWidth: 0.5 }}>
                                            <span style={{ fontSize: 12 }}>Tweets Long</span><br />
                                            <b style={{ fontSize: 14 }}>{post.postThreads.length + 1}</b>
                                        </div>
                                        <div className='col-md-3' style={{ borderRightStyle: this.state.isMobile ? 'none' : 'solid', borderWidth: 0.5 }}>
                                            <span style={{ fontSize: 12 }}>Date of Original Thread</span><br />
                                            <span style={{ fontSize: 14 }}>{post.contentCreatedDate}</span>
                                        </div>
                                        <div className='col-md-3' style={{ borderRightStyle: this.state.isMobile ? 'none' : 'solid', borderWidth: 0.5 }}>
                                            <span style={{ fontSize: 12 }}>Original Thread on Twitter</span><br />
                                            <a style={{ fontSize: 14 }} href={post.contentSourceLink} target="_blank">View Thread <i className='fa fa-link'></i></a>
                                        </div>
                                    </div>
                                    {
                                        this.state.isMobile ?
                                            <div className='row'>
                                                <div style={{ borderRadius: 50, width: 50, height: 50, paddingTop: 15, cursor: 'pointer' }} align='center' onClick={() => !this.state.savedPostIds.includes(this.state.product.id) ? this.bookmark(this.state.product.id) : null}>
                                                    <i className={this.state.savedPostIds.includes(post.id) ? 'fa fa-bookmark' : 'fa fa-bookmark-o'} style={{ fontSize: 17 }}></i>
                                                </div>
                                                <br />
                                                <div style={{borderRadius: 50, width: 50, height: 50, paddingTop: 15, cursor: 'pointer' }} align='center'>
                                                    <i className='fa fa-share-alt' style={{ fontSize: 17 }}></i>
                                                </div>
                                                <br />
                                                <div onClick={() => this.toogleCommentModal()} style={{color: 'black', borderRadius: 50, width: 50, height: 50, paddingTop: 15, cursor: 'pointer' }} align='center'>
                                                    <i className={this.state.commentModal ? 'fa fa-commenting' : 'fa fa-commenting-o'} style={{ fontSize: 17 }} ></i>
                                                </div>
                                            </div>:
                                            <></>
                                    }
                                    <br />
                                    <div className='container' style={{ borderRadius: 10, backgroundColor: 'white', padding: this.state.isMobile ? 10 : 20 }}>
                                        <div className='container'>
                                            <Row>
                                                <div className='col-md-9'>
                                                    <span style={{ fontSize: 14, padding: 7, fontWeight: 'bold' }}>
                                                        <i class="fa fa-thumbs-o-up" aria-hidden="true"></i> {post.likeCount}
                                                    </span>
                                                    <span style={{ fontSize: 14, padding: 7, fontWeight: 'bold' }}>
                                                        <i class="fa fa-commenting-o" aria-hidden="true"></i> {post.commentCount}
                                                    </span>
                                                    <span style={{ fontSize: 14, padding: 7, fontWeight: 'bold' }}>
                                                        <i class="fa fa-eye" aria-hidden="true"></i> {post.viewCount}
                                                    </span>
                                                </div>
                                            </Row>
                                        </div>
                                        <br />
                                        <TwitterCardDetail isMobile={this.state.isMobile} profileURL={post.contentCreatorProfileURL} username={post.contentCreatorUsername} content={post.content} images={post.postImages} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : []} urls={post.urLs != undefined ? JSON.parse(post.urLs) : []}></TwitterCardDetail>
                                        {
                                            post.postImages != null && post.postImages.length > 0 ? <></> : post.urLs != null ? JSON.parse(post.urLs).map((url, i) =>
                                                <>
                                                    <a href={url.url} target="_blank">
                                                        <div className='container-fluid'>
                                                            <EmbeddedCard isMobile={this.state.isMobile} displayURL={url.display_url} title={url.title} description={url.description} image={url.images != null ? url.images[0].url : null} ></EmbeddedCard>
                                                        </div>
                                                    </a>
                                                    <br />
                                                </>
                                            ) : <></>
                                        }
                                        <br />
                                        {
                                            post.postThreads.map((item, i) =>
                                                <>
                                                    <TwitterCardDetail isMobile={this.state.isMobile} profileURL={item.contentCreatorProfileURL} username={item.contentCreatorUsername} content={item.content} images={item.postThreadImages} mentions={item.mentions != undefined ? JSON.parse(item.mentions) : []} urls={item.urLs != undefined ? JSON.parse(item.urLs) : []}></TwitterCardDetail>
                                                    {
                                                        item.postThreadImages != null && item.postThreadImages.length > 0 ? <></> : item.urLs != null ? JSON.parse(item.urLs).map((url, i) =>
                                                            <a href={url.url} target="_blank">
                                                                <div className='container-fluid'>
                                                                    <EmbeddedCard isMobile={this.state.isMobile} displayURL={url.display_url} title={url.title} description={url.description} image={url.images != null ? url.images[0].url : null} ></EmbeddedCard>
                                                                </div></a>

                                                        ) : <></>
                                                    }
                                                    <br /><br />
                                                </>


                                            )
                                        }
                                        <br />
                                    </div>
                                </div>
                                {this.state.isMobile ? <></> : <div className='col-md-1'></div>}

                            </div>

                        </div>

                        {/* <hr></hr>
                    {this.state.like == true ? <i className='btn fa fa-heart' style={{ fontSize: 30, color: "red" }} onClick={() => this.likeUnlike()}></i> : <i className='btn fa fa-heart-o' style={{ fontSize: 30 }} onClick={() => this.likeUnlike()}></i>}
                    <b>{this.state.likeCount}</b>
                    <br /><br />
                    <div>
                        <h3>Comment Section</h3>
                        <br />
                        {
                            this.state.comments.length > 0 ? <>
                                {this.state.comments.map((cmt, i) =>
                                    <>

                                        <CommentCard username={cmt.username} comment={cmt.comment}></CommentCard>
                                        <hr />
                                    </>

                                )}
                            </> : <></>
                        }
                        <br />
                        <div className='row'>
                            <div className='col-md-5'>
                                <input type='text' className='form-control' value={this.state.comment} onChange={(e) => { this.setState({ comment: e.target.value }) }} />
                            </div>
                            <div className='col-md-2'><button className='btn btn-primary' onClick={() => this.comment()}>Comment</button></div>
                        </div>
                    </div> */}
                    </div>
                </div >
                <FolderSaveModal toggle={this.toggle} modal={this.state.modal} title={'Bookmark To'}>
                    <SavePost toggle={this.toggle} postId={this.state.selectedPostId}></SavePost>
                </FolderSaveModal>
            </>

        )
    }
}
