import React from "react";
import { Link, Redirect } from "react-router-dom";
import SimpleModal, { ConfirmModal } from "../../components/Tables/Modal";
import { FolderCard, TwitterCardIndex } from "../../components/TwitterCard";
import { apiurl } from "../../services/common";
import { deleteData, getData, postData } from "../../services/fetch";
import { isInRole } from "../../services/storage";

export default class Folder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            folders: [],
            addModal: false,
            showModal: false,
            editModal: false,
            deleteModal : false,
            selectedFolderId: "",
            folderId: "",
            folderName: "",
            deleteFolderId: "",
            deleteFolderName : ""
        }
    }

    componentDidMount = async () => {
        await this.fetchFolder();
    }

    fetchFolder = async () => {
        var url = apiurl + '/api/User/GetAllFolders'
        var response = await getData(url);
        if (response.ok) {
            this.state.folders = response.data.data;
            this.setState({ folders: this.state.folders });
        }
    }

    deleteFolderConfirm = (id, name)=>{
        this.setState({ deleteFolderId: id, deleteFolderName: name });
        this.setState({ deleteModal: true });
    }
    deleteFolder = async () => {
        var response = { ok: false, data: 'Something went wrong' };
        response = await deleteData('/api/User/DeleteFolder?folderId=' + this.state.deleteFolderId);
        if (response.ok) {
            this.toggle();
            this.fetchFolder();
            //   this.props.refreshTable();
        } else {
            this.setState({ messageModal: true, message: response.data });
        }
    }

    toggle = () => {
        this.setState({
            addModal: false,
            showModal: false,
            editModal: false,
            deleteModal : false
        })
    }

    editFolder = (id, name) => {
        this.setState({ folderId: id, folderName: name });
        this.setState({ editModal: true });
    }

    render() {
        if (!isInRole('user') && !isInRole('admin')) return <Redirect to={{
            pathname: "/user/index",
            state: { from: this.props.location }
        }} />
        return (<>
            <div className="container">
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <h1>My Bookmarks</h1>
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 col-sm-12 ">
                            <button className="btn btn-block" style={{ backgroundColor: 'white', borderColor: 'black', borderStyle: 'solid', borderWidth: 0.5, borderRadius: 10 }} onClick={() => { this.setState({ addModal: true }) }}><i className="fa fa-plus-square"></i> Add New Bookmark Folder</button>
                        </div>
                    </div>
                    <br />
                    <div style={{ padding: 50, borderRadius: 20, backgroundColor: 'white' }}>
                        {
                            this.state.folders.map((folder, index) =>
                                <div className="row">
                                    <div className="col-md-8">
                                        <Link to={{ pathname: "/user/folder/Posts", search: "?id=" + folder.id + "&name=" + folder.name }}>
                                            <div style={{ cursor: 'pointer' }} >
                                                <span><h3>{folder.name}</h3></span>

                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-2">
                                        <button className="btn" onClick={() => this.editFolder(folder.id, folder.name)}><i className="fa fa-edit"></i></button>
                                        <button className="btn" onClick={()=> this.deleteFolderConfirm(folder.id, folder.name)}><i className="fa fa-trash"></i></button>
                                    </div>
                                    <br /><div className={index == 0 ? 'col-md-8' : 'col-md-8'} style={{ height: 1, backgroundColor: 'black' }}></div><br />
                                </div>

                            )
                        }

                        {/* <button className="btn" onClick={() => { this.setState({ addModal: true }) }}><i className="fa fa-plus-square" style={{ fontSize: 25 }}></i></button> */}
                    </div>
                </div>


                <SimpleModal toggle={this.toggle} modal={this.state.addModal} title={'Add Folder'}>
                    <FolderAdd toggle={this.toggle} refreshFolders={this.fetchFolder}></FolderAdd>
                </SimpleModal>

                <SimpleModal toggle={this.toggle} modal={this.state.editModal} title={'Update Folder'}>
                    <FolderEdit toggle={this.toggle} refreshFolders={this.fetchFolder} id={this.state.folderId} name={this.state.folderName}></FolderEdit>
                </SimpleModal>

                <ConfirmModal
                    toggle={this.toggle}
                    modal={this.state.deleteModal}
                    title={'Delete Folder Confirmation'}
                    confirmText={'Are you sure you want to delete '+ this.state.deleteFolderName + '?'}
                    buttonText={'Delete'}
                    onConfirm={this.deleteFolder}
                />
                {/* <SimpleModal toggle={this.toggle} modal={this.state.showModal} title={'Folder'}>
                    <ShowPosts toggle={this.toggle} folderId={this.state.selectedFolderId}></ShowPosts>
                </SimpleModal> */}
            </div>

        </>)
    }
}

export class FolderAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: ""
        }
    }

    save = async (e) => {
        e.preventDefault();
        var response = { ok: false, data: 'Something went wrong' };
        response = await postData('/api/User/CreateFolder', { name: this.state.name, coverPhoto:"" });
        if (response.ok) {
            this.props.toggle();
            this.props.refreshFolders();
            //   this.props.refreshTable();
        } else {
            this.setState({ messageModal: true, message: response.data });
        }
    }

    render() {
        return (<>
            <div className="container">
                <input type="text" className="form-control" onChange={(e) => { this.setState({ name: e.target.value }) }} ></input>
                <br />
                <button className="btn" onClick={(e) => this.save(e)}>Add Folder</button>
            </div>
        </>)
    }
}


export class FolderEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            name: this.props.name
        }
    }

    save = async (e) => {
        e.preventDefault();
        var response = { ok: false, data: 'Something went wrong' };
        response = await postData('/api/User/EditFolder', { id: this.state.id, name: this.state.name });
        if (response.ok) {
            this.props.toggle();
            this.props.refreshFolders();
            //   this.props.refreshTable();
        } else {
            this.setState({ messageModal: true, message: response.data });
        }
    }

    render() {
        return (<>
            <div className="container">
                <input type="text" className="form-control" onChange={(e) => { this.setState({ name: e.target.value }) }} value={this.state.name} ></input>
                <br />
                <button className="btn" onClick={(e) => this.save(e)}>Update Folder</button>
            </div>
        </>)
    }
}


// export class ShowPosts extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             posts: []
//         }
//     }

//     componentDidMount = async () => {
//         await this.fetchPosts();
//     }

//     fetchPosts = async () => {
//         var url = apiurl + '/api/User/GetPostsByFolder?folderId=' + this.props.folderId
//         var response = await getData(url);
//         if (response.ok) {
//             this.state.posts = response.data.data;
//             this.setState({ posts: this.state.posts });
//         }
//     }

//     render() {
//         return (<>
//             <div className="row">
//                 {
//                     this.state.posts.map((post, index) =>
//                         <div className="col-lg-4 col-md-6" key={index}>
//                             <Link to={{ pathname: "/User/Product", search: "?id=" + post.id }}>
//                                 <TwitterCardIndex categories={post.postCategories.map((category, index) => { return category.category.name })} profileURL={post.contentCreatorProfileURL} username={post.contentCreatorUsername} content={post.content} viewCount={post.viewCount} />
//                             </Link>
//                             <br />
//                             {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
//                         </div>
//                     )
//                 }
//             </div></>)
//     }
// }

export class SavePost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            folders: [],
            selectedFolderId: ''
        }
    }

    componentDidMount = async () => {
        await this.fetchFolder();
    }

    fetchFolder = async () => {
        var url = apiurl + '/api/User/GetAllFolders'
        var response = await getData(url);
        if (response.ok) {
            this.state.folders = response.data.data;
            this.setState({ folders: this.state.folders });
        }
    }

    toggle = () => {
        this.setState({
            addModal: false,
            showModal: false
        })
    }

    save = async () => {

        var model = { postId: this.props.postId, folderId: this.state.selectedFolderId };
        var response = { ok: false, data: 'Something went wrong' };
        response = await postData('/api/User/SavePost', model);
        if (response.ok) {
            this.props.toggle();
            // this.props.refreshFolders();
            //   this.props.refreshTable();
        } else {
            this.setState({ messageModal: true, message: response.data });
        }
    }

    render() {

        return (<>
            <div className="container">
                <div className="col-md-12">
                    <button className="btn" style={{ backgroundColor: 'white', borderColor: 'black', borderStyle: 'solid', borderWidth: 0.5, borderRadius: 10, width: '100%' }} onClick={() => { this.setState({ addModal: true }) }}><i className="fa fa-plus-square"></i> Add New Bookmark Folder</button>
                </div>
                <br />
                <div className="col-md-12">
                    {
                        this.state.folders.map((folder, index) =>
                            <div style={{ cursor: 'pointer' }} onClick={async () => { await this.setState({ selectedFolderId: folder.id }); this.save() }}>
                                <span><h4>{folder.name}</h4></span>
                                <br /><div className={'col-md-12'} style={{ height: 1, backgroundColor: 'black' }}></div><br />
                            </div>




                        )
                    }

                    {/* <button className="btn" onClick={()=>{this.setState({addModal:true})}}><i className="fa fa-plus-square" style={{fontSize:25}}></i></button> */}
                    <br />
                    {/* <button className="btn btn-pirmary" onClick={(e) => this.save(e)}>Save Post</button> */}
                </div>
                <SimpleModal toggle={this.toggle} modal={this.state.addModal} title={'Add Folder'}>
                    <FolderAdd toggle={this.toggle} refreshFolders={this.fetchFolder}></FolderAdd>
                </SimpleModal>
            </div>

        </>)
    }
}
