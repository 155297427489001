import React, { Component } from 'react';
import {
  Button,
  Form,
  Row,
} from "reactstrap";
import ColInput from '../../components/Form/Form.jsx';
import { postData, putData } from '../../services/fetch.js';
import { MessageModal } from '../../components/Tables/Modal.jsx';
import { defaultModel } from '../../components/DefaultModel.js';

export default class ItemTypeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: this.props.model ? this.props.model : defaultModel.itemType,
      messageModal: false,
      message: '',
    }
    this.updateModel = this.updateModel.bind(this);
    this.save = this.save.bind(this);
  }

  updateModel = (e) => {
    let { name, value } = e.target;
    var key = name;
    this.setState(state => ({
      model: Object.assign({}, state.model, { [key]: value })
    }));
  }

  save = async (e) => {
    e.preventDefault();
    var response = { ok: false, data: 'Something went wrong' };
    if (this.props.action === 'create') {
      response = await postData('/api/ItemType', this.state.model);
    } else if (this.props.action === 'edit') {
      response = await putData('/api/ItemType/' + this.state.model.id, this.state.model);
    }
    if (response.ok) {
      this.props.toggle();
      this.props.refreshTable();
    } else {
      this.setState({ messageModal: true, message: response.data });
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      messageModal: !prevState.messageModal
    }));
  }

  render() {
    return (
      <div>
        <MessageModal
          toggle={this.toggle}
          modal={this.state.messageModal}
          title={'Error'}
          message={this.state.message}
        />
        <Form onSubmit={this.save}>
          <h6 className="heading-small text-muted mb-4">
            Item Type information
        </h6>
          <div className="pl-lg-4">
            <Row>
              <ColInput
                col={12}
                label={'Type'}
                defaultValue={this.state.model.type}
                id={'type'}
                type={'text'}
                onChange={this.updateModel}
                required={true}
              />
              <ColInput
                col={12}
                label={'Remark'}
                defaultValue={this.state.model.remark}
                id={'remark'}
                type={'textarea'}
                rows={4}
                onChange={this.updateModel}
              />
            </Row>
          </div>
          <div className="pl-lg-4 text-right">
            <Button color="primary" type="submit">Save</Button>
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          </div>
        </Form>
      </div>
    )
  }
}
