import React from "react";
import {
  Redirect,
} from "react-router-dom";
import { postData } from "../../services/fetch";
import { setToken, removeToken, getToken, setRole, removeRole, getRole } from "../../services/storage";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        username: '',
        password: '',
        confirmpassword:''
      },
      error: '',
      path: '/admin/index',
      redirectToIndex: false,
    }

    this.updateData = this.updateData.bind(this);
    this.register = this.register.bind(this);
  }

  async componentDidMount() {
    var token = await getToken();
    var role = await getRole();
    if (role == "User") {
      this.state.path = "/user/index"
      this.setState({ path: this.state.path });
    }
    if (token) {
      this.setState({ redirectToIndex: true })
    }
  }


  updateData = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((state) => ({
      user: Object.assign({}, state.user, { [name]: value })
    }));
  }

  register = async (e) => {
    e.preventDefault();
    var response = await postData('/api/Authentication/register', this.state.user);
    console.log(response)
    if (response.ok) {
      var data = response.data;
      if (data.statusCode==0) { // token is return
        setToken(data.token);
        setRole(data.role);
        if (data.role == "User") {
          this.state.path = "/user/index"
          this.setState({ path: this.state.path });
        }
        this.setState({ error: '', redirectToIndex: true });
      } else { // Invalid Login Attempt
        this.setState({ error: data });
        removeToken();
        removeRole();
      }
    }
  }
  render() {
    let { from } = this.props.location.state || { from: { pathname: this.state.path } };
    if (this.state.redirectToIndex) return <Redirect to={from} />
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign up with credentials</small>
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" name="username" onChange={this.updateData} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" name="password" onChange={this.updateData}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Confirm Password" type="password" name="confirmpassword" onChange={this.updateData}/>
                  </InputGroup>
                </FormGroup>
                {/* <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    <span className="text-success font-weight-700">strong</span>
                  </small>
                </div> */}
                {/* <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I agree with the{" "}
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row> */}
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="button" onClick={(e)=>this.register(e)}>
                    Create account
                  </Button>
                  <br/><br/>
                  <a href="/auth/login"><h3>Already have an account? Sign In</h3></a>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Register;
