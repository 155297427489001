import React, { Component } from 'react';
import {
  Button,
  Form,
  Row,
} from "reactstrap";
import ColInput from '../../components/Form/Form.jsx';
import { postData, putData, getData } from '../../services/fetch.js';
import { MessageModal } from '../../components/Tables/Modal.jsx';
import { defaultModel } from '../../components/DefaultModel.js';
import Thumbnail, { SortableThumbnailContainer } from '../../components/Images.js';

const maxPhoto = 3;

export default class ItemForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: this.props.model ? this.props.model : defaultModel.item,
      messageModal: false,
      message: '',
      itemTypes: [],
      itemColors: [],
      itemFlavors: [],
      itemBrands: [],
      itemNicotines: [],
      itemCapacities: [],
      loading: false
    }
    this.updateModel = this.updateModel.bind(this);
    this.addItemPicture = this.addItemPicture.bind(this);
    this.save = this.save.bind(this);
    this.onSortItems = this.onSortItems.bind(this);
  }

  componentDidMount = async () => {
    var response = await getData('/api/ItemType/GetItemTypeSelectList');
    if (response.ok) {
      this.setState({ itemTypes: response.data });
    }
    await this.getItemFlavors();
    await this.getItemBrands();
    await this.getItemColors();
    await this.getItemNicotines();
    await this.getItemCapacities();
  }

  getItemFlavors = async () => {
    var response = await getData('/api/ItemFlavor/GetItemFlavorSelectList');
    if (response.ok) {
      this.setState({ itemFlavors: response.data });
    }
  }

  getItemBrands = async () => {
    var response = await getData('/api/ItemBrand/GetItemBrandSelectList');
    if (response.ok) {
      this.setState({ itemBrands: response.data });
    }
  }

  getItemColors = async () => {
    var response = await getData('/api/ItemColor/GetItemColorSelectList');
    if (response.ok) {
      this.setState({ itemColors: response.data });
    }
  }

  getItemNicotines = async () => {
    var response = await getData('/api/ItemNicotine/GetItemNicotineSelectList');
    if (response.ok) {
      this.setState({ itemNicotines: response.data });
    }
  }

  getItemCapacities = async () => {
    var response = await getData('/api/ItemCapacity/GetItemCapacitySelectList');
    if (response.ok) {
      this.setState({ itemCapacities: response.data });
    }
  }


  updateModel = (e) => {
    let { name, value } = e.target;
    var key = name;
    this.setState(state => ({
      model: Object.assign({}, state.model, { [key]: value })
    }));
  }

  updateCheckbox = (key, value) => {
    this.setState(state => ({
      model: Object.assign({}, state.model, { [key]: value })
    }));
  }

  addItemPicture = (input) => {
    var thisprops = this;
    if (input.target.files && input.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var newPic = JSON.parse(JSON.stringify(defaultModel.itemPictures));
        newPic.name = e.target.result;
        thisprops.state.model.itemPictures.push(newPic);
        thisprops.setState({ model: thisprops.state.model });
      };
      reader.readAsDataURL(input.target.files[0]);
    }
  }

  save = async (e) => {
    e.preventDefault();
    this.setState({ loading: true })
    var response = { ok: false, data: 'Something went wrong' };
    if (this.props.action === 'create') {
      response = await postData('/api/Item', this.state.model);
    } else if (this.props.action === 'edit') {
      response = await putData('/api/Item/' + this.state.model.id, this.state.model);
    }
    if (response.ok) {
      this.setState({ loading: false })
      this.props.toggle();
      this.props.refreshTable();
    } else {
      this.setState({ messageModal: true, message: response.data });
      this.setState({ loading: false })
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      messageModal: !prevState.messageModal
    }));
  }

  onSortItems(itemPictures) {
    document.getElementById('imageUploadForm').value = '';
    this.setState(state => ({
      model: Object.assign({}, state.model, { ['itemPictures']: itemPictures })
    }));
  }

  render() {
    return (
      <div>
        <MessageModal
          toggle={this.toggle}
          modal={this.state.messageModal}
          title={'Error'}
          message={this.state.message}
        />
        <Form onSubmit={this.save}>
          <h6 className="heading-small text-muted mb-4">
            Item Photos (max: {maxPhoto})
        </h6>
          <div className="pl-lg-4">
            <Row>
              <div className="col-12">
                <SortableThumbnailContainer images={this.state.model.itemPictures} onSortItems={this.onSortItems} />
                <input hidden type="file" id="imageUploadForm" accept="image/gif, image/jpeg, image/png" onChange={this.addItemPicture} />
                {
                  this.state.model.itemPictures.length < maxPhoto ? (
                    <Thumbnail customPath={true} image={'/img/add.png'} onClick={() => document.getElementById('imageUploadForm').click()} />
                  ) : (<></>)
                }
              </div>
            </Row>
          </div>
          <hr className="my-4" />
          {/* Address */}
          <h6 className="heading-small text-muted mb-4">
            Item information
          </h6>
          <div className="pl-lg-4">
            <Row>
              <ColInput
                col={12}
                label={'Name'}
                defaultValue={this.state.model.itemName}
                id={'itemName'}
                type={'text'}
                onChange={this.updateModel}
                required={true}
              />
              <ColInput
                col={6}
                label={'Type'}
                defaultValue={this.state.model.itemTypeID}
                id={'itemTypeID'}
                type={'select'}
                onChange={this.updateModel}
                options={this.state.itemTypes}
                required={true}
              />
              <ColInput
                col={6}
                label={'Brand'}
                defaultValue={this.state.model.itemBrandID}
                id={'itemBrandID'}
                type={'select'}
                onChange={this.updateModel}
                options={this.state.itemBrands}
              />
              <ColInput
                col={6}
                label={'Color'}
                defaultValue={this.state.model.itemColorID}
                id={'itemColorID'}
                type={'select'}
                onChange={this.updateModel}
                options={this.state.itemColors}
              />
              <ColInput
                col={6}
                label={'Flavor'}
                defaultValue={this.state.model.itemFlavorID}
                id={'itemFlavorID'}
                type={'select'}
                onChange={this.updateModel}
                options={this.state.itemFlavors}
              />
              <ColInput
                col={6}
                label={'Nicotine'}
                defaultValue={this.state.model.itemNicotineID}
                id={'itemNicotineID'}
                type={'select'}
                onChange={this.updateModel}
                options={this.state.itemNicotines}
              />
              <ColInput
                col={6}
                label={'Capacity'}
                defaultValue={this.state.model.itemCapacityID}
                id={'itemCapacityID'}
                type={'select'}
                onChange={this.updateModel}
                options={this.state.itemCapacities}
              />
              {/* <ColInput
                col={6}
                label={'Preorder'}
                defaultValue={this.state.model.isPreOrderItem}
                id={'isPreOrderItem'}
                type={'switch'}
                onChange={(key, value) => this.updateCheckbox(key, value)}
              />
              <ColInput
                col={6}
                label={'Url'}
                defaultValue={this.state.model.url}
                id={'url'}
                type={'text'}
                onChange={this.updateModel}
              /> */}
              <ColInput
                col={6}
                label={'Quantity'}
                defaultValue={this.state.model.quantityInStock}
                id={'quantityInStock'}
                type={'number'}
                onChange={this.updateModel}
                required={true}
                min={this.state.model.isPreOrderItem ? '' : 0}
              />
              <ColInput
                col={6}
                label={'Cost'}
                defaultValue={this.state.model.perItemCost}
                id={'perItemCost'}
                type={'number'}
                onChange={this.updateModel}
                required={true}
                min={0}
              />
              <ColInput
                col={6}
                label={'Purchase Price'}
                defaultValue={this.state.model.perItemPurchasePrice}
                id={'perItemPurchasePrice'}
                type={'number'}
                onChange={this.updateModel}
                required={true}
                min={0}
              />
            </Row>
          </div>
          <hr className="my-4" />
          {/* Description */}
          <h6 className="heading-small text-muted mb-4">About Item</h6>
          <div className="pl-lg-4">
            <Row>
              <ColInput
                col={12}
                label={'Description (Insert <br/> to break new line)'}
                defaultValue={this.state.model.description}
                id={'description'}
                type={'textarea'}
                rows={4}
                onChange={this.updateModel}
              />
              <ColInput
                col={12}
                label={'Remark'}
                defaultValue={this.state.model.remark}
                id={'remark'}
                type={'textarea'}
                rows={4}
                onChange={this.updateModel}
              />
            </Row>
          </div>
          <div className="pl-lg-4 text-right">
            <Button color="primary" type="submit" disabled={this.state.loading}>Save</Button>
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          </div>
        </Form>
      </div>
    )
  }
}
