import React, { Component } from 'react'
import { Col, FormGroup, Input } from "reactstrap";
import Switch from "react-switch";
import '../../assets/css/common.css';
import AsyncSelect from 'react-select/async';

export default class ColInput extends Component {
    render() {
        return (
            <Col lg={this.props.col} style={{ display: this.props.hidden ? 'none' : 'block' }}>
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor={this.props.id}
                        style={this.props.labelStyle}
                    >
                        {this.props.label}
                    </label>
                    <SimpleInput
                        className="form-control-alternative"
                        value={this.props.value}
                        defaultValue={this.props.defaultValue}
                        id={this.props.id}
                        name={this.props.name ? this.props.name : this.props.id}
                        placeholder={this.props.placeholder}
                        type={this.props.type}
                        rows={this.props.rows}
                        min={this.props.min}
                        max={this.props.max}
                        onChange={this.props.onChange}
                        required={this.props.required}
                        options={this.props.options ? this.props.options : []}
                        loadOptions={this.props.loadOptions ? this.props.loadOptions : []}
                        disableDefaultOption={this.props.disableDefaultOption}
                        onBlur={this.props.onBlur}
                    />
                </FormGroup>
            </Col>
        )
    }
}

export function SimpleInput(props) {
    return (
        props.type == 'select' ? (
            <Input
                value={props.defaultValue != null ? props.defaultValue.toUpperCase() : ''}
                id={props.id}
                name={props.name ? props.name : props.id}
                placeholder={props.placeholder}
                type={props.type}
                rows={props.rows}
                onChange={props.onChange}
                required={props.required}
                onBlur={props.onBlur}
                style={Object.assign({ color: '#525f7f' }, props.style)}
            >
                {
                    !props.disableDefaultOption ? (
                        <option value=''>Select Type</option>
                    ) : (<></>)
                }
                {
                    props.options.map((option, i) =>
                        <option value={option.value} key={i}>{option.label}</option>
                    )
                }
            </Input>
        ) : (
                props.type == 'AsyncSelect' ? (
                    <div className="customSelect">
                        <AsyncSelect
                            isClearable
                            style={Object.assign({ width: '100%' }, props.style)}
                            className="basic-single"
                            classNamePrefix="select"
                            name={props.name}
                            defaultValue={props.defaultValue}
                            loadOptions={props.loadOptions}
                            onChange={props.onChange}
                        />
                    </div>
                ) : (
                        props.type == 'switch' ? (
                            <Switch
                                className="form-control-alternative checkbox"
                                onChange={checked => props.onChange(props.id, checked)}
                                checked={props.defaultValue}
                                id={props.id}
                                name={props.name ? props.name : props.id}
                                required={props.required}
                                onBlur={props.onBlur}
                            />
                        ) : (
                                <Input
                                    className="form-control-alternative"
                                    value={props.value}
                                    defaultValue={props.defaultValue}
                                    id={props.id}
                                    name={props.name ? props.name : props.id}
                                    placeholder={props.placeholder}
                                    type={props.type}
                                    rows={props.rows}
                                    min={props.min}
                                    max={props.max}
                                    onChange={props.onChange}
                                    required={props.required}
                                    onBlur={props.onBlur}
                                    style={Object.assign({ color: '#525f7f' }, props.style)}
                                />
                            )
                    )
            )
    )
}