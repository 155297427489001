import React, { Component } from 'react'
import { getData, postData } from '../../services/fetch';
import NumberFormat from 'react-number-format';
import '../../components/Shop/styles.css';
import { apiurl, arraySum, s3Folder, s3HostURL, timeout } from '../../services/common';
import { defaultModel } from '../../components/DefaultModel';
import { connect } from 'react-redux';
import { inherits } from 'util';
import { OFontSizeChangeCard, QuoteCardIndex, RFontSizeChangeCard, TagCard, TagCardSmall, TwitterCardIndex } from '../../components/TwitterCard';
import { Link } from "react-router-dom";
import debounce from "debounce-promise";
import SimpleModal, { FolderSaveModal } from '../../components/Tables/Modal';
import { SavePost } from '../Folder/Folder';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getTab } from '../../services/storage';
import InfiniteScroll from 'react-infinite-scroll-component';

export default class Quotes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            perPage : 10,
            posts: [],
            totalPosts: 0,
            keywords: [],
            selectedCategories: [],
            selectedUnPopularCategories: [],
            popularCategories: [],
            allCategories: [],
            categoryList: [],
            searchedCategories: [],
            savedPostIds: [],
            search: "",
            modal: false,
            selectedPostId: "",
            firstColumnPosts: [],
            secondColumnPosts: [],
            thirdColumnPosts: [],
            fourthColumnPosts: [],
            recentSearches: [],
            width: window.innerWidth,
            height: window.innerHeight,
            isMobile: false,
            isTablet: false,
            tab: 'thread',
            modal: false,
            currentImage: "",
            sorts: [],
            descOrder: true,
            sortBys: [{ label: 'Tweet Date', value: 0 }, { label: 'List Date', value: 1 }],
            titleChanged:false
        }
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();

        await this.fetchPosts();
        await this.fetchCategories();
        await this.fetchSavedPostIds();
        await this.fetchAllCategories();
        await this.fetchRecentSearches();

    }


    handleResize = () => {
        this.setState({
            isMobile: window.innerWidth <= 768, isTablet: window.innerWidth <= 992 && window.innerWidth > 768
        });
    }

    toggle = async () => {
        this.setState({
            modal: false
        })
        await this.fetchSavedPostIds();
    }

    fetchPosts = async (page = 1, per_page = 10) => {
        var model = {};
        model.page = page;
        model.per_page = per_page;
        model.search = this.state.keywords;
        model.sorts = this.state.sorts;
        model.isDescending = this.state.descOrder;
        var searchCategories = [];
        this.state.selectedUnPopularCategories.map((cat, index) => searchCategories.push(cat))
        this.state.selectedCategories.map((cat, index) => searchCategories.push(cat));
        model.categoryIds = searchCategories;
        var url = '/api/Quote/SearchQuotes';
        console.log(url)
        var response = await postData(url, model);
        if (response.ok) {
            if (page == 1) {
                this.state.posts = response.data.data;
                this.setState({ totalPosts: response.data.total })
            } else {
                var posts = this.state.posts;
                response.data.data.map((post, i) => {
                    posts.push(post);
                })
                this.state.posts = posts;
            }
            this.setState({ posts: this.state.posts });
            console.log(this.state.posts.length);
            await this.diversifyPostColumns();
        }
    }

    diversifyPostColumns = () => {
        var posts = this.state.posts;
        var firstColumnPosts = [];
        var secondColumnPosts = [];
        var thirdColumnPosts = [];
        var fourthColumnPosts = [];
        posts.map((post, index) => {
            if (index % 4 == 0) {
                firstColumnPosts.push(post);
            } else if (index % 4 == 1) {
                secondColumnPosts.push(post);
            } else if (index % 4 == 2) {
                thirdColumnPosts.push(post);
            } else {
                fourthColumnPosts.push(post);
            }
        })
        this.setState({ firstColumnPosts, secondColumnPosts, thirdColumnPosts, fourthColumnPosts });
    }

    fetchCategories = async () => {
        var url = apiurl + '/api/Setting/GetPopularQuoteCategories'
        var response = await getData(url);
        if (response.ok) {
            this.state.popularCategories = response.data;
            this.setState({ popularCategories: this.state.popularCategories });
        }
    }

    fetchAllCategories = async () => {
        var url = apiurl + '/api/Setting/GetAllQuoteCategories'
        var response = await getData(url);
        if (response.ok) {
            this.state.allCategories = response.data;
            this.setState({ allCategories: this.state.allCategories });
            var categoryList = [];
            response.data.map((category, index) => {
                categoryList.push({ label: category.name, value: category.id });
            })
            this.setState({ categoryList });
        }
    }

    // updateRecentSearches = async (recentSearch) => {
    //     var url = '/api/User/UpdateRecentSearches'
    //     var response = await postData(url, recentSearch);
    //     if (response.ok) {
    //         if (response.data.statusCode == 0) {
    //             this.state.recentSearches = response.data.data;
    //             this.setState({ recentSearches: this.state.recentSearches });
    //         }
    //     }
    // }

    fetchRecentSearches = async () => {
        var url = apiurl + '/api/user/GetRecentSearches'
        var response = await getData(url);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.state.recentSearches = response.data.data;
                this.setState({ recentSearches: this.state.recentSearches });
            }
        }
    }

    updateSearch = async (e) => {
        var categories = [];
        var keywords = [];
        var lastSearch = {};
        console.log(e);
        if (e != null) {
            e.map((selected, index) => {
                if (selected.value == selected.label) {
                    keywords.push(selected.value);
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: false }
                    }
                } else {
                    categories.push(selected.value)
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: true }
                    }
                }
            })
        }

        if ((categories.length + keywords.length) > this.state.selectedUnPopularCategories.length) {
            console.log("triggered")
            // this.updateRecentSearches(lastSearch)
        }

        await this.setState({ keywords, selectedUnPopularCategories: categories });
        await this.fetchPosts()

    }

    fetchSavedPostIds = async () => {
        var url = apiurl + '/api/User/GetAllUserSavedPostIds'
        var response = await getData(url);
        if (response.ok) {
            this.state.savedPostIds = response.data.data;
            this.setState({ savedPostIds: this.state.savedPostIds });
        }
    }

    fetchSearchCategories = async (input) => {

        var url = apiurl + '/api/Setting/SearchCategories?search=' + input
        var response = await getData(url);
        if (response.ok) {
            console.log(response.data)
            var newSearchedCategories = [];
            var isSearchExisted = response.data.findIndex(x => x.name.toLowerCase() == input.toLowerCase())
            if (isSearchExisted == -1) {
                newSearchedCategories.push({ id: "0", name: input })
            }
            response.data.map((cat, index) => { newSearchedCategories.push({ id: cat.id, name: cat.name, image: cat.image }) });
            this.state.searchedCategories = newSearchedCategories;
            this.setState({ searchedCategories: this.state.searchedCategories });
        }
    }

    debounceSearchCategories = debounce(this.fetchSearchCategories, timeout);

    selectUnselectCategory = (id) => {
        var index = this.state.selectedCategories.findIndex(x => x == id);
        if (index == -1) {
            this.state.selectedCategories.push(id);

        } else {
            this.state.selectedCategories.splice(index, 1);
        }
        this.setState({ selectedCategories: this.state.selectedCategories });
    }

    selectUnselectUnPopularCategory = (category) => {
        if (category.id == "0") {
            var index = this.state.keywords.findIndex(x => x.toLowerCase() == category.name.toLowerCase());
            if (index == -1) {
                this.state.keywords.push(category.name);
            }
            this.setState({ keywords: this.state.keywords });
        } else {
            var index = this.state.selectedUnPopularCategories.findIndex(x => x.id == category.id);
            if (index == -1) {
                this.state.selectedUnPopularCategories.push(category.id);

            } else {
                this.state.selectedUnPopularCategories.splice(index, 1);
            }
            this.setState({ selectedUnPopularCategories: this.state.selectedUnPopularCategories });
        }
    }

    removeKeywords = (key) => {
        var index = this.state.keywords.findIndex(x => x.toLowerCase() == key.toLowerCase());
        if (index == -1) {
            this.state.keywords.push(key);
        } else {
            this.state.keywords.splice(index, 1);
        }
        this.setState({ keywords: this.state.keywords });
    }

    bookmark = (id) => {
        this.setState({ selectedPostId: id, modal: true })
    }

    paginate = async () => {
        this.state.page = this.state.page + 1;
        this.setState({ page: this.state.page });
        this.fetchPosts(this.state.page, this.state.perPage)
    }

    selectCustomStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '60px',
            borderRadius: '10px',
            textAlign: 'center',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            textAlign: 'center',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'center',
        }),
        option: (provided, state) => ({
            ...provided,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            align: 'center',
            color: 'black',
            fontSize: '18px',
            marginLeft: this.state.isMobile || this.state.isTablet ? '10px' : '150px'
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            display: 'none'
        }),
    };

    selectSortCustomStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '40px',
            borderRadius: '10px',
            textAlign: 'center',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            textAlign: 'center',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'center',
        }),
        option: (provided, state) => ({
            ...provided,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            align: 'center',
            color: 'black',
            fontSize: '14px',
            marginLeft: this.state.isMobile || this.state.isTablet ? '10px' : '10px'
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            display: 'none'
        }),
    };


    toggle = () => {
        this.setState({
            modal: false,
            deleteModal: false,
            model: defaultModel.chain
        });
    }

    setImage = async (images) => {
        if (images.length > 0) {
            this.state.currentImage = images[0].imageURL;
            await this.setState({ currentImage: this.state.currentImage });
            await this.setState({ modal: true });
        }
    }

    updateSort = async (e) => {
        var sorts = []
        if (e != null) {
            e.map((selected, index) => {
                sorts.push(selected.value);
            })

        }
        this.state.sorts = sorts;
        await this.setState({ sorts: this.state.sorts });
        await this.fetchPosts();
    }

    changeSortOrder = async () => {
        await this.setState({ descOrder: !this.state.descOrder });
        await this.fetchPosts();

    }

    render() {
        return (
            <div className="container">

                {
                    <>
                        <>
                            <div className="row">
                                {/* <div className="col-xl-3 col-lg-4 col-md-5">
                        <Categories onSelected={(id, name) => { setcategoryVal(id); setcategoryName(name) }} />
                    </div> */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    {/* <div className="sidebar-categories mb-5">
                            <div className="head">
                            {categoryName}
                        </div>
                        </div> */}
                                    <section className="lattest-product-area pb-40 category-list">
                                        <div className='row'>
                                            <div className='col-md-3'></div>
                                            <div className='col-md-6'>
                                            <div align="center" onMouseEnter={() => this.setState({ titleChanged: true })} onMouseLeave={() => this.setState({ titleChanged: false })} style={{ height: 150 }}>
                                                <b style={{ fontSize: 50, color: 'black' }} className="title-font">{this.state.titleChanged ? "thread" : "threadoor"}</b>
                                                {
                                                    this.state.titleChanged ? <>
                                                        <OFontSizeChangeCard />
                                                        <OFontSizeChangeCard />
                                                        <OFontSizeChangeCard />
                                                        <OFontSizeChangeCard />
                                                        <RFontSizeChangeCard />
                                                        <RFontSizeChangeCard />
                                                        <RFontSizeChangeCard />
                                                    </>
                                                        : <></>
                                                }
                                            </div>
                                                <CreatableSelect
                                                    closeMenuOnSelect={false}
                                                    isMulti
                                                    options={this.state.categoryList}
                                                    onChange={(e) => this.updateSearch(e)}
                                                    placeholder={<div><><i class="fa fa-search"></i> What are you looking for?</></div>}
                                                    styles={this.selectCustomStyle}
                                                />
                                                <br />

                                            </div>
                                            <div className='col-md-3'></div>
                                        </div>
                                        <br /><br />
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-md-8'>
                                                    <div className='row'>
                                                        <b style={{ fontSize: 14, marginTop: 7 }}>Popular Categories &nbsp;</b>
                                                        {
                                                            this.state.popularCategories.map((category, index) =>
                                                                <div onClick={() => { this.selectUnselectCategory(category.id); this.fetchPosts() }} style={{ cursor: 'pointer' }}><TagCardSmall selected={this.state.selectedCategories.includes(category.id)} icon={category.image} tag={category.name}></TagCardSmall></div>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                <div className='col-md-3'>
                                                    <CreatableSelect
                                                        closeMenuOnSelect={false}
                                                        isMulti
                                                        options={this.state.sortBys}
                                                        onChange={(e) => this.updateSort(e)}
                                                        placeholder={<div><>Sort By</></div>}
                                                        styles={this.selectSortCustomStyle}
                                                    />
                                                </div>
                                                <div className="col-lg-1 col-1" style={{ padding: 3, cursor: 'pointer' }} onClick={() => this.changeSortOrder()}>
                                                    <b style={{ fontSize: 20 }}><i className={this.state.descOrder ? "fa fa-sort-amount-desc" : "fa fa-sort-amount-asc"}></i></b>
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        {
                                            this.state.isMobile ? <div className='row'>
                                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                                    <InfiniteScroll
                                                        dataLength={this.state.posts.length} //This is important field to render the next data
                                                        next={this.paginate}
                                                        hasMore={this.state.totalPosts != this.state.posts.length}
                                                        loader={<h4>Loading...</h4>}
                                                        endMessage={
                                                            <p style={{ textAlign: 'center' }}>
                                                                <b>You are up to date.</b>
                                                            </p>
                                                        }
                                                    // below props only if you need pull down functionality

                                                    >
                                                        {
                                                            this.state.posts.map((post, index) =>
                                                                <div key={index}>

                                                                    <QuoteCardIndex categories={post.quoteCategories != null ? post.quoteCategories.map((category, index) => { return category.category.name }) : []} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} contentCreator={post.contentCreator} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} images={post.quoteImages} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : []} urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} isMobile={this.state.isMobile} />

                                                                    <br />

                                                                </div>
                                                            )
                                                        }
                                                    </InfiniteScroll>

                                                </div>
                                            </div> :
                                                <>
                                                    <div className='row'>
                                                        <div className='col-lg-3 col-md-4'>
                                                            {
                                                                this.state.firstColumnPosts.map((post, index) =>
                                                                    <div key={index} onClick={() => this.setImage(post.quoteImages)}>

                                                                        <QuoteCardIndex categories={post.quoteCategories != null ? post.quoteCategories.map((category, index) => { return category.category.name }) : []} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} contentCreator={post.contentCreator} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} images={post.quoteImages} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : []} urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} isMobile={this.state.isMobile} />

                                                                        <br />

                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='col-lg-3 col-md-4'>
                                                            {
                                                                this.state.secondColumnPosts.map((post, index) =>
                                                                    <div key={index} onClick={() => this.setImage(post.quoteImages)}>

                                                                        <QuoteCardIndex categories={post.quoteCategories != null ? post.quoteCategories.map((category, index) => { return category.category.name }) : []} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} contentCreator={post.contentCreator} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} images={post.quoteImages} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : []} urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} isMobile={this.state.isMobile} />

                                                                        <br />

                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='col-lg-3 col-md-4'>
                                                            {
                                                                this.state.thirdColumnPosts.map((post, index) =>
                                                                    <div key={index} onClick={() => this.setImage(post.quoteImages)}>

                                                                        <QuoteCardIndex categories={post.quoteCategories != null ? post.quoteCategories.map((category, index) => { return category.category.name }) : []} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} contentCreator={post.contentCreator} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} images={post.quoteImages} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : []} urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} isMobile={this.state.isMobile} />
                                                                        <br />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='col-lg-3 col-md-4'>
                                                            {
                                                                this.state.fourthColumnPosts.map((post, index) =>
                                                                    <div key={index} onClick={() => this.setImage(post.quoteImages)}>

                                                                        <QuoteCardIndex categories={post.quoteCategories != null ? post.quoteCategories.map((category, index) => { return category.category.name }) : []} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} contentCreator={post.contentCreator} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} images={post.quoteImages} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : []} urls={post.urLs != undefined ? JSON.parse(post.urLs) : []} isMobile={this.state.isMobile} />
                                                                        <br />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.totalPosts != this.state.posts.length ? (
                                                            <div className='row' align='center'>
                                                                <div className='col-md-12' align='center'>
                                                                    <a className="btn btn-default" onClick={() => {
                                                                        this.paginate();
                                                                    }} align='center' style={{ color: 'white' }}>Load More</a>
                                                                </div>
                                                            </div>
                                                        ) : (<></>)
                                                    }
                                                </>
                                        }
                                    </section>
                                </div>
                            </div>
                        </></>
                }


                <SimpleModal toggle={this.toggle} modal={this.state.modal} title={'Image'}>
                    <img style={{ width: '100%' }} src={s3HostURL + s3Folder + this.state.currentImage} />
                </SimpleModal>
            </div>

        )
    }
}