import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import {
  Redirect,
} from "react-router-dom";
import { getData, postData } from "../../services/fetch";
import { setToken, removeToken, getToken, setRole, removeRole, getRole, setLoginProvider, removeLoginProvider } from "../../services/storage";
import GoogleLogin from "react-google-login";
import { apiurl, googleClientId } from "../../services/common";
import { gapi } from 'gapi-script';
import queryString from 'query-string';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        username: '',
        password: '',
      },
      error: '',
      path: '/admin/index',
      redirectToIndex: false,
    }

    this.updateData = this.updateData.bind(this);
    this.login = this.login.bind(this);
  }

  async componentDidMount() {
    gapi.load('client:auth2', this.initClient);
    this.twitterLogin();
    var token = await getToken();
    var role = await getRole();
    if (role == "User") {
      this.state.path = "/user/index"
      this.setState({ path: this.state.path });
    }
    if (token) {
      this.setState({ redirectToIndex: true })
    }
  }

  initClient = () => {
    gapi.client.init({
      clientId: googleClientId,
      scope: ''
    });
  };


  updateData = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((state) => ({
      user: Object.assign({}, state.user, { [name]: value })
    }));
  }

  login = async (e) => {
    e.preventDefault();
    var response = await postData('/api/Authentication/Login', this.state.user);
    if (response.ok) {
      var data = response.data;
      if (data.statusCode == 0) { // token is return
        setToken(data.token);
        setRole(data.role);
        setLoginProvider(data.externalLoginProvider)
        if (data.role == "User") {
          this.state.path = "/user/index"
          this.setState({ path: this.state.path });
        }
        this.setState({ error: '', redirectToIndex: true });
      } else { // Invalid Login Attempt
        this.setState({ error: data });
        removeToken();
        removeRole();
        removeLoginProvider();
      }
    }
  }
  googleResponse = async (response) => {
    console.log(response)
    // const tokenBlob = new Blob([JSON.stringify({ tokenId: response.tokenId }, null, 2)], { type: 'application/json' });
    var model = { tokenId: response.tokenId };
    var response = await postData('/api/Authentication/googleLogin', model);
    if (response.ok) {
      var data = response.data;
      if (data.statusCode == 0) { // token is return
        setToken(data.token);
        setRole(data.role);
        setLoginProvider(data.externalLoginProvider);
        if (data.role == "User") {
          this.state.path = "/user/index"
          this.setState({ path: this.state.path });
        }
        this.setState({ error: '', redirectToIndex: true });
      } else { // Invalid Login Attempt
        this.setState({ error: data });
        removeToken();
        removeRole();
        removeLoginProvider();
      }
    }
  };

  twitterAuthenticate = async () => {
    var response = await getData(apiurl + '/api/Authentication/TwitterAuthenticate');
    if (response.ok) {
      var data = response.data;
      window.location.href = 'https://api.twitter.com/oauth/authenticate?oauth_token=' + data.oauth_token;
    }
  }

  twitterLogin = async () => {
    const { oauth_token, oauth_verifier } = queryString.parse(window.location.search);
    if (oauth_token && oauth_verifier) {
      var model = { oauth_token, oauth_verifier };
      var response = await postData('/api/Authentication/TwitterLogin', model);
      if (response.ok) {
        var data = response.data;
        if (data.statusCode == 0) { // token is return
          setToken(data.token);
          setRole(data.role);
          setLoginProvider(data.externalLoginProvider);
          if (data.role == "User") {
            this.state.path = "/user/index"
            this.setState({ path: this.state.path });
          }
          this.setState({ error: '', redirectToIndex: true });
        } else { // Invalid Login Attempt
          this.setState({ error: data });
          removeToken();
          removeRole();
          removeLoginProvider();
        }
      }
    }

  }

  render() {
    let { from } = this.props.location.state || { from: { pathname: this.state.path } };
    if (this.state.redirectToIndex) return <Redirect to={from} />
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <GoogleLogin
                  clientId={googleClientId}
                  buttonText="Google Login"
                  onSuccess={this.googleResponse}
                  onFailure={this.googleResponse}
                  render={renderProps => (
                    <Button onClick={renderProps.onClick}><img src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" width="25" height="25" /> Google Login</Button>
                  )}
                />
              </div>
              <div className="text-center text-muted mb-4">
                <Button onClick={this.twitterAuthenticate}><img src="https://www.iconpacks.net/icons/2/free-twitter-logo-icon-2429-thumb.png" width="25" height="25" /> Twitter Login</Button>
              </div>
              <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <div className="text-center text-danger mb-4">
                <small>{this.state.error}</small>
              </div>
              <Form role="form" onSubmit={this.login}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="text" name="username" onChange={this.updateData} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" name="password" onChange={this.updateData} />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Sign in
                  </Button>
                  <a href="/auth/register"><h3>Don't have an account? Sign Up</h3></a>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6"></Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/admin/forgotpassword"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            {/* <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col> */}
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
