import React, { Component } from 'react';
import '../assets/css/photo-upload-custom.css';
import DragSortableList from 'react-drag-sortable'
import { arrayRemoveByIndex } from '../services/common';


export default class Thumbnail extends Component {
    constructor(props) {
        super(props);
        this.onRemove = this.onRemove.bind(this);
    }

    onRemove() {
        this.props.onRemove(this.props.index);
    }

    render() {
        return (
            <div className="photo_container ui-sortable">
                <div className="photo_holder ui-sortable-handle">
                    <img
                        className="photo_frame photos"
                        alt="..."
                        src={this.props.customPath ? this.props.image : '/img/items/small/' + this.props.image}
                        onClick={this.props.onClick}
                    />
                    {
                        this.props.enableRemove ? (
                            <button type="button" className="close photo_remove_btn" onClick={this.onRemove}>
                                <span aria-hidden="true">×</span>
                            </button>
                        ) : (<></>)
                    }
                </div>
            </div>
        )
    }
}

export class ThumbnailContainer extends Component {
    render() {
        return (
            this.props.images.map((image, i) => (
                <Thumbnail
                    key={i}
                    customPath={!image.id}
                    image={image.name}
                />
            ))
        )
    }
}

export class SortableThumbnailContainer extends Component {
    constructor(props) {
        super(props);
        this.onSort = this.onSort.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onSort(sortedList, dropEvent) {
        this.props.onSortItems(sortedList.map(x => x.content.props.imgObj));
    }

    onRemove(index) {
        this.props.onSortItems(arrayRemoveByIndex(this.props.images, index));
    }

    render() {
        const thisprops = this;
        return (
            <DragSortableList
                items={this.props.images.map(function (image, i) {
                    return {
                        content: (
                            <Thumbnail
                                key={i}
                                customPath={!image.id}
                                image={image.name}
                                imgObj={image}
                                enableRemove={true}
                                onRemove={thisprops.onRemove}
                                index={i}
                            />
                        )
                    }
                })}
                onSort={this.onSort}
                type="horizontal"
            />
        )
    }
}