import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, MemoryRouter, HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import UserLayout from "layouts/User.jsx";
import store from "./services/store";
// import "./assets/css/font.css";
// import "./assets/fonts/CooperBlackStd.otf"
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';

//Initialize GA4
ReactGA.initialize("G-F1W9EV6ZLE");
function render(){
  ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route path="/user" render={props => <UserLayout {...props} store={store} />} />
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <Redirect from="" to="/user/index" />
        <Redirect from="/admin" to="/admin/index" />
      </Switch>
    </BrowserRouter>,
    document.getElementById("root")
  );
}

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);

store.subscribe(render);
render();


