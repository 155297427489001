import React from "react";
import { Link, Redirect } from "react-router-dom";
import SimpleModal, { ConfirmModal } from "../../components/Tables/Modal";
import { FolderCard, TwitterCardIndex } from "../../components/TwitterCard";
import { apiurl, s3HostURL } from "../../services/common";
import { getData, postData } from "../../services/fetch";
import CreatableSelect from 'react-select/creatable';
import { isInRole } from "../../services/storage";
import Switch from "react-switch";

export default class FolderPosts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            folder: { coverPhoto: '' },
            posts: [],
            folderName: "",
            user: { id: 'random' },
            firstColumnPosts: [],
            secondColumnPosts: [],
            thirdColumnPosts: [],
            allCategories: [],
            categoryList: [],
            descOrder: true,
            keywords: [],
            selectedUnPopularCategories: [],
            selectedUnbookmarkId: '',
            unbookmarkModal: false,
            folderColor: ""
        }

    }

    componentDidMount = async () => {
        let params = new URLSearchParams(this.props.location.search);
        let color = params.get('color');
        this.state.folderColor = color;
        this.setState({ folderColor: this.state.folderColor });
        console.log(this.state.folderColor)
        await this.fetchUserData();
        await this.fetchFolder();
        await this.fetchAllCategories();
        await this.fetchPosts();
        await this.fetchSavedPostIds();
        this.diversifyPostColumns();
    }

    changeSortOrder = async () => {
        await this.setState({ descOrder: !this.state.descOrder });
        await this.fetchPosts();
    }

    fetchUserData = async () => {
        var url = apiurl + '/api/user/getUserData';
        var response = await getData(url);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.state.user = response.data.data;
                this.setState({ user: this.state.user });
            }
        }
    }

    // fetchPosts = async () => {
    //     let params = new URLSearchParams(this.props.location.search);
    //     var id = params.get('id');
    //     var name = params.get('name')
    //     this.setState({ folderName: name });
    //     var url = apiurl + '/api/User/GetPostsByFolder?folderId=' + id;
    //     var response = await getData(url);
    //     if (response.ok) {
    //         this.state.posts = response.data.data;
    //         this.setState({ posts: this.state.posts });
    //     }
    // }

    fetchPosts = async (search, page = 1, per_page = 30) => {
        var model = {};
        let params = new URLSearchParams(this.props.location.search);
        var id = params.get('id');
        var name = params.get('name')
        this.setState({ folderName: name });
        model.page = page;
        model.per_page = per_page;
        model.search = this.state.keywords;
        model.folderId = id;
        model.isDescending = this.state.descOrder;
        var searchCategories = [];
        this.state.selectedUnPopularCategories.map((cat, index) => searchCategories.push(cat))
        // this.state.selectedCategories.map((cat, index) => searchCategories.push(cat));
        model.categoryIds = searchCategories;
        var url = '/api/User/SearchPosts';
        var response = await postData(url, model);
        if (response.ok) {
            this.state.posts = response.data.data;
            this.setState({ posts: this.state.posts });
            this.diversifyPostColumns();
        }
    }

    fetchFolder = async () => {
        let params = new URLSearchParams(this.props.location.search);
        var id = params.get('id');
        var url = apiurl + '/api/User/GetFolderInfo?folderId=' + id;
        var response = await getData(url);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.state.folder = response.data.data;
                this.setState({ folder: this.state.folder });
            }
        }
    }

    changeFolderStatus = async () => {
        let params = new URLSearchParams(this.props.location.search);
        var id = params.get('id');
        var url = '/api/user/UpdateFolderStatus?folderId=' + id;
        var response = await postData(url, {});
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.state.folder = response.data.data;
                this.setState({ folder: this.state.folder });
            }
        }
    }

    fetchSavedPostIds = async () => {
        var url = apiurl + '/api/User/GetAllUserSavedPostIds'
        var response = await getData(url);
        if (response.ok) {
            this.state.savedPostIds = response.data.data;
            this.state.posts.map((post, i) => {
                post.isBookMarked = this.state.savedPostIds.map((savePost, n) => { return savePost.postID }).includes(post.id);
                if (post.isBookMarked) {
                    post.bookMarkId = this.state.savedPostIds.find(x => x.postID == post.id).id;
                    post.bookMarkDate = this.state.savedPostIds.find(x => x.postID == post.id).createdDate;
                }
            })
            this.setState({ posts: this.state.posts });
            this.setState({ savedPostIds: this.state.savedPostIds });
        }
    }

    unbookmark = async (id) => {
        var url = '/api/User/UnSavePost?id=' + this.state.selectedUnbookmarkId;
        var response = await postData(url, {});
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.toggle();
            }
        }
    }

    toggle = async () => {
        this.setState({ unbookmarkModal: false })
        await this.fetchPosts();
        await this.fetchSavedPostIds();
    }

    diversifyPostColumns = () => {
        var posts = this.state.posts;
        var firstColumnPosts = [];
        var secondColumnPosts = [];
        var thirdColumnPosts = [];
        posts.map((post, index) => {
            if (index % 3 == 0) {
                firstColumnPosts.push(post);
            } else if (index % 3 == 1) {
                secondColumnPosts.push(post);
            } else {
                thirdColumnPosts.push(post);
            }
        })
        this.setState({ firstColumnPosts, secondColumnPosts, thirdColumnPosts });
    }

    fetchAllCategories = async () => {
        var url = apiurl + '/api/Setting/GetAllCategories'
        var response = await getData(url);
        if (response.ok) {
            this.state.allCategories = response.data;
            this.setState({ allCategories: this.state.allCategories });
            var categoryList = [];
            response.data.map((category, index) => {
                categoryList.push({ label: category.name, value: category.id });
            })
            this.setState({ categoryList });
        }
    }

    updateSearch = async (e) => {
        var categories = [];
        var keywords = [];
        var lastSearch = {};
        console.log(e);
        if (e != null) {
            e.map((selected, index) => {
                if (selected.value == selected.label) {
                    keywords.push(selected.value);
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: false }
                    }
                } else {
                    categories.push(selected.value)
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: true }
                    }
                }
            })
        }

        // if ((categories.length + keywords.length) > this.state.selectedUnPopularCategories.length) {
        //     console.log("triggered")
        //     this.updateRecentSearches(lastSearch)
        // }

        await this.setState({ keywords, selectedUnPopularCategories: categories });
        await this.fetchPosts()

    }


    uploadImage = async (e) => {
        var base64image = await this.convertBase64(e.target.files[0]);
        // console.log(base64image);
        var type = base64image.startsWith("data:image") ? 0 : 1
        this.state.folder.coverPhoto = base64image;
        this.setState({ folder: this.state.folder })
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    updateFolder = async (e) => {
        var folder = { id: this.state.folder.id, name: this.state.folder.name, coverPhoto: this.state.folder.coverPhoto }
        var url = '/api/User/EditFolder';
        var response = await postData(url, folder);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                // this.toggle();
                window.location.reload();
            }
        }
    }

    selectCustomStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '40px',
            borderRadius: '10px',
            textAlign: 'center',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            textAlign: 'center',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'center',
        }),
        option: (provided, state) => ({
            ...provided,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            align: 'center',
            color: 'black',
            fontSize: '13px',
            marginLeft: '5px'
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            display: 'none'
        }),
    };



    render() {
        // if (!isInRole('user')) return <Redirect to={{
        //     pathname: "/user/index",
        //     state: { from: this.props.location }
        // }} />
        if (this.state.folder.userID == this.state.user.id) {
            return (<>
                <div className="container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1">

                            </div>
                            <div className="col-md-10">
                                <b style={{ fontSize: 11 }}><a href="/user/folder" style={{ color: 'black' }}>My Bookmarks</a> &nbsp; 	<i className="fa fa-chevron-right"></i>  &nbsp; {this.state.folderName}</b>
                                <br /><br />
                                <div className="row" style={{ backgroundColor: "#333333", padding: 15, borderRadius: 10 }}>
                                    <div className="col-md-3">
                                        <input type="file" ref={fileInput => this.fileInput = fileInput} onChange={(e) => this.uploadImage(e)} hidden></input>
                                        <div onClick={() => this.fileInput.click()} style={{ height: 190, backgroundImage: this.state.folder.coverPhoto != "" ? this.state.folder.coverPhoto.startsWith("data:image") ? 'url("' + this.state.folder.coverPhoto + '")' : 'url("' + s3HostURL + "folder-images/" + this.state.folder.coverPhoto + '")' : 'url("https://media.listedsimply.com/wp-content/uploads/2022/11/19174841/istockphoto-1248723171-612x612-1.jpeg")', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: 10 }}></div>
                                    </div>
                                    <div className="col-md-5" style={{ marginTop: 120 }}>
                                        <h1 style={{ color: '#999999' }}>{this.state.folderName}</h1>
                                        <label style={{ fontSize: 12, color: "#777777" }}>Recomended Playlist . 12.Nov.2023 . 14 Posts</label>
                                    </div>
                                    <div className="col-md-4" align='right' style={{ marginTop: 140 }}>
                                        <button className="btn btn" onClick={() => this.updateFolder()}>Update</button>
                                    </div>

                                </div>

                                <br /><br />
                                <div className="row">
                                    <div className="col-lg-4 col-10">
                                        <CreatableSelect
                                            closeMenuOnSelect={false}
                                            isMulti
                                            options={this.state.categoryList}
                                            onChange={(e) => this.updateSearch(e)}
                                            placeholder={<div><><i class="fa fa-search"></i> Search in your bookmarks</></div>}
                                            styles={this.selectCustomStyle}
                                        />
                                    </div>
                                    <div className="col-lg-1 col-2" style={{ padding: 3, cursor: 'pointer' }} onClick={() => this.changeSortOrder()}>
                                        <b style={{ fontSize: 20 }}><i className={this.state.descOrder ? "fa fa-sort-amount-desc" : "fa fa-sort-amount-asc"}></i></b>
                                    </div>
                                    <div className="col-lg-7" align='right' style={{ marginTop: -27 }}>
                                        <label>Publish</label><br /> <Switch checked={this.state.folder.isPublic} onChange={() => this.changeFolderStatus()} />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        {
                                            this.state.firstColumnPosts.map((post, index) =>
                                                <div key={index}>
                                                    <TwitterCardIndex bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} contentCreator={post.contentCreator} contentSourceLink={post.contentSourceLink} bookmarkCount={post.bookmarkCount} images={post.postImages} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={true} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                                    <br />
                                                    {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        {
                                            this.state.secondColumnPosts.map((post, index) =>
                                                <div key={index}>
                                                    <TwitterCardIndex bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} contentCreator={post.contentCreator} contentSourceLink={post.contentSourceLink} bookmarkCount={post.bookmarkCount} images={post.postImages} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={true} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                                    <br />
                                                    {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        {
                                            this.state.thirdColumnPosts.map((post, index) =>
                                                <div key={index}>
                                                    <TwitterCardIndex bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} contentCreator={post.contentCreator} contentSourceLink={post.contentSourceLink} bookmarkCount={post.bookmarkCount} images={post.postImages} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={true} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                                    <br />
                                                    {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmModal
                    toggle={this.toggle}
                    modal={this.state.unbookmarkModal}
                    title={'UnBookmark Confirmation'}
                    confirmText={'Are you sure you want to unbookmark post?'}
                    buttonText={'Unbookmark'}
                    onConfirm={this.unbookmark}
                />
            </>)
        } else {
            return (<>
                <div className="container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1">

                            </div>
                            <div className="col-md-10">

                                <div className="row" style={{ backgroundColor: "#333333", padding: 15, borderRadius: 10 }}>
                                    <div className="col-md-3">

                                        <div style={{ height: 190, backgroundImage: this.state.folder.coverPhoto != "" ? this.state.folder.coverPhoto.startsWith("data:image") ? 'url("' + this.state.folder.coverPhoto + '")' : 'url("' + s3HostURL + "folder-images/" + this.state.folder.coverPhoto + '")' : '', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: 10, backgroundColor: "#"+this.state.folderColor, paddingTop: 50 }} align='center'>
                                            {(this.state.folder.coverPhoto != "" && this.state.folder.coverPhoto != null) ? <></> : <label style={{ color: 'white', fontWeight: 'bold', fontSize: 50 }}>{(this.state.folder.name != null && this.state.folder.name != "") ? this.state.folder.name.charAt(0) : ""}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-5" style={{ marginTop: 120 }}>
                                        <h1 style={{ color: '#999999' }}>{this.state.folderName}</h1>
                                        <label style={{ fontSize: 12, color: "#777777" }}>Recomended Playlist . 12.Nov.2023 . 14 Posts</label>
                                    </div>
                                </div>

                                <br />
                                <div className="row">
                                    <div className="col-lg-4 col-10">
                                        <CreatableSelect
                                            closeMenuOnSelect={false}
                                            isMulti
                                            options={this.state.categoryList}
                                            onChange={(e) => this.updateSearch(e)}
                                            placeholder={<div><><i class="fa fa-search"></i> {"Search in " + this.state.folder.name}</></div>}
                                            styles={this.selectCustomStyle}
                                        />
                                    </div>
                                    <div className="col-lg-1 col-2" style={{ padding: 3, cursor: 'pointer' }} onClick={() => this.changeSortOrder()}>
                                        <b style={{ fontSize: 20 }}><i className={this.state.descOrder ? "fa fa-sort-amount-desc" : "fa fa-sort-amount-asc"}></i></b>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        {
                                            this.state.firstColumnPosts.map((post, index) =>
                                                <div key={index}>
                                                    <TwitterCardIndex bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} contentCreator={post.contentCreator} contentSourceLink={post.contentSourceLink} bookmarkCount={post.bookmarkCount} images={post.postImages} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={true} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                                    <br />
                                                    {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        {
                                            this.state.secondColumnPosts.map((post, index) =>
                                                <div key={index}>
                                                    <TwitterCardIndex bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} contentCreator={post.contentCreator} contentSourceLink={post.contentSourceLink} bookmarkCount={post.bookmarkCount} images={post.postImages} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={true} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                                    <br />
                                                    {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        {
                                            this.state.thirdColumnPosts.map((post, index) =>
                                                <div key={index}>
                                                    <TwitterCardIndex bookmarkDate={post.bookMarkDate} contentCreatedDate={post.contentCreatedDate} contentCreator={post.contentCreator} contentSourceLink={post.contentSourceLink} bookmarkCount={post.bookmarkCount} images={post.postImages} categories={post.postCategories.map((category, index) => { return category.category.name })} isBookMarked={true} unbookmark={() => this.setState({ selectedUnbookmarkId: post.bookMarkId, unbookmarkModal: true })} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} profileURL={post.contentCreatorProfileURL} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={JSON.parse(post.mentions)} />
                                                    <br />
                                                    {/* <button className='btn btn-primary' onClick={() => { this.setState({ selectedPostId: post.id, modal: true }) }}>Save</button> */}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmModal
                    toggle={this.toggle}
                    modal={this.state.unbookmarkModal}
                    title={'UnBookmark Confirmation'}
                    confirmText={'Are you sure you want to unbookmark post?'}
                    buttonText={'Unbookmark'}
                    onConfirm={this.unbookmark}
                />
            </>)
        }

    }
}
