import React from "react";

// reactstrap components
import {
  Col,
  Container, Row,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { getData } from 'services/fetch';
import MaterialTable from "material-table";
import DetailPanel from "../../components/Tables/DetailPanel";
import SimpleModal, { ConfirmModal } from "../../components/Tables/Modal.jsx";
import {
  Redirect,
} from "react-router-dom";
import { defaultModel } from "../../components/DefaultModel";
import { deleteData, postData } from "../../services/fetch";
import { getDateForInput } from "../../services/date";
import { apiurl } from "../../services/common";
import TweetForm from "./TweetForm";
import CreatableSelect from 'react-select/creatable';

class Tweet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: null,
      modal: false,
      model: defaultModel.post,
      action: '',
      redirectToLogin: false,
      disableEditButton: true,
      selectedIds: [],
      selectedId: '',
      hideModal: false,
      unHideModal: false,
      sorts: [],
      sortBys: [{ label: 'Tweet Date', value: 0 }, { label: 'List Date', value: 1 }],
      descOrder: true
    };

    this.tableRef = React.createRef();
    this.delete = this.delete.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: false,
      deleteModal: false,
      hideModal: false,
      unHideModal: false,
      model: defaultModel.chain
    });
  }

  refreshTable = () => {
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  delete = async () => {
    var response = await deleteData('/api/Post/DeleteManyPosts', this.state.selectedIds);
    if (response.ok) {
      this.toggle();
      this.refreshTable();
    }
  }

  hide = async () => {
    var response = await postData('/api/Post/HidePost', this.state.selectedId);
    if (response.ok) {
      this.toggle();
      this.refreshTable();
    }
  }

  selectSortCustomStyle = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '40px',
      width: '250px',
      borderRadius: '10px',
      textAlign: 'center',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      width: '250px',
      padding: '0 6px',
      textAlign: 'center',
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      textAlign: 'center',
    }),
    option: (provided, state) => ({
      ...provided,
      width: '250px'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      textAlign: 'center',
      align: 'center',
      color: 'black',
      fontSize: '14px',
      marginLeft: this.state.isMobile || this.state.isTablet ? '10px' : '10px'
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      display: 'none'
    }),
  };

  changeSortOrder = async () => {
    await this.setState({ descOrder: !this.state.descOrder });
    await this.refreshTable();

  }

  updateSort = async (e) => {
    var sorts = []
    if (e != null) {
      e.map((selected, index) => {
        sorts.push(selected.value);
      })

    }
    this.state.sorts = sorts;
    await this.setState({ sorts: this.state.sorts });
    await this.refreshTable();
  }


  render() {
    const thisprops = this;
    if (this.state.redirectToLogin) return <Redirect to={{
      pathname: "/auth/login",
      state: { from: this.props.location }
    }} />
    return (
      <>
        <Header />
        {/* Page content */}

        <Container className="mt--9" fluid>
          <Row>
            <Col md={9}></Col>
            <Col md={3}>
              <Row>
                <CreatableSelect
                  closeMenuOnSelect={false}
                  isMulti
                  options={this.state.sortBys}
                  onChange={(e) => this.updateSort(e)}
                  placeholder={<div><>Sort By</></div>}
                  styles={this.selectSortCustomStyle}
                />
                <div style={{ padding: 3, cursor: 'pointer' }} onClick={() => this.changeSortOrder()}>
                  <b style={{ fontSize: 20 }}><i style={{ color: 'white' }} className={this.state.descOrder ? "fa fa-sort-amount-desc" : "fa fa-sort-amount-asc"}></i></b>
                </div>
              </Row>

            </Col>
          </Row>
          <br /><br />
          <MaterialTable
            title="Tweets"
            tableRef={this.tableRef}
            columns={[
              { title: 'Title', field: 'title', width: "30%" },
              { title: 'Username', field: 'contentCreatorUsername' },
              { title: 'Tweet Date', field: 'contentCreatedDate' },
              { title: 'List Date', field: 'createdDate' },
              { title: 'Source Link', field: 'contentSourceLink' }
            ]}
            data={async function (query) {
              if(query.orderBy){
                console.log(query.orderBy.field + " " + query.orderDirection)
                if(query.orderBy.field=='contentCreatedDate'){
                  thisprops.state.sorts = [0,1];
                  thisprops.setState({sorts: thisprops.state.sorts});
                }else{
                  thisprops.state.sorts = [1,0];
                  thisprops.setState({sorts: thisprops.state.sorts});
                }
                thisprops.state.descOrder = query.orderDirection=='desc'
                thisprops.setState({descOrder: thisprops.state.descOrder})
              }
              let url = apiurl + '/api/Post?'
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              url += '&search=' + query.search
              url += '&isTweet=true'
              url += '&isDescending=' + thisprops.state.descOrder
              url += '&sorts=' + JSON.stringify(thisprops.state.sorts)
              var result = await getData(url);
              if (result.ok) {
                var data = result.data;
                return {
                  data: data.data,
                  page: data.page - 1,
                  totalCount: data.total
                }
              } else if (result.status === 401) {
                thisprops.setState({ redirectToLogin: true });
              } else {
                return {
                  data: [],
                  page: 1,
                  totalCount: 0
                }
              }
            }}
            options={{
              search: true,
              sorting:true,
              // selection: true,
              debounceInterval: 500,
              actionsColumnIndex: 0,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100],
              tableLayout: "fixed"
            }}
            onSelectionChange={(rows) => {
              this.setState(state => ({
                disableEditButton: rows.length > 1,
                selectedIds: rows.map(row => row.id)
              }));
            }}
            
            onRowClick={async (evt, data) => {
              this.setState({ modal: true, model: data, action: 'edit' });
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: this.refreshTable
              },
              {
                icon: 'add_circle',
                tooltip: 'Add Post',
                isFreeAction: true,
                onClick: () => { this.setState({ action: 'create', modal: true }); },
              },
              // {
              //   tooltip: 'Edit A Selected Post',
              //   icon: 'edit',
              //   disabled: this.state.disableEditButton,
              //   onClick: async (evt, data) => {
              //     this.setState({ modal: true, model: data[0], action: 'edit' });
              //   }
              // },
              {
                tooltip: 'Remove All Selected Quotes',
                icon: 'delete',
                iconProps: { color: 'error' },
                onClick: (evt, data) => this.setState({ deleteModal: true, selectedIds: [data.id] })
              },
              ,
              rowData => ({
                tooltip: rowData.isHidden ? 'Show Post' : 'Hide Post',
                icon: () => rowData.isHidden ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>,
                iconProps: { color: 'default' },
                onClick: (evt, data) => rowData.isHidden ? this.setState({ unHideModal: true, selectedId: data.id }) : this.setState({ hideModal: true, selectedId: data.id })
              })
            ]}
          />

          <SimpleModal toggle={this.toggle} modal={this.state.modal} title={'Quote'}>
            <TweetForm
              model={this.state.model}
              action={this.state.action}
              toggle={this.toggle}
              refreshTable={this.refreshTable}
            />
          </SimpleModal>
          <ConfirmModal
            toggle={this.toggle}
            modal={this.state.deleteModal}
            title={'Delete Confirmation'}
            confirmText={'Are you sure you want to delete all selected posts?'}
            buttonText={'Delete'}
            onConfirm={this.delete}
          />
          <ConfirmModal
            toggle={this.toggle}
            modal={this.state.hideModal}
            title={'Hide Confirmation'}
            confirmText={'Are you sure you want to hide selected post?'}
            buttonText={'Hide'}
            onConfirm={this.hide}
          />
          <ConfirmModal
            toggle={this.toggle}
            modal={this.state.unHideModal}
            title={'Unhide Confirmation'}
            confirmText={'Are you sure you want to unhide selected post?'}
            buttonText={'Unhide'}
            onConfirm={this.hide}
          />
        </Container>
      </>
    );
  }
}

export default Tweet;
