import React, { Component } from 'react'
import { getData, postData } from '../../services/fetch';
import NumberFormat from 'react-number-format';
import '../../components/Shop/styles.css';
import { apiurl, arraySum, timeout } from '../../services/common';
import { defaultModel } from '../../components/DefaultModel';
import { connect } from 'react-redux';
import { inherits } from 'util';
import { OFontSizeChangeCard, QuoteCardIndex, RFontSizeChangeCard, TagCard, TagCardSmall, TweetCardIndex, TwitterCardIndex } from '../../components/TwitterCard';
import { Link } from "react-router-dom";
import debounce from "debounce-promise";
import SimpleModal, { FolderSaveModal } from '../../components/Tables/Modal';
import { SavePost } from '../Folder/Folder';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getTab, isInRole } from '../../services/storage';
import InfiniteScroll from 'react-infinite-scroll-component';

export default class Tweets extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            perPage: 10,
            totalPosts: 0,
            posts: [],
            keywords: [],
            selectedCategories: [],
            selectedUnPopularCategories: [],
            popularCategories: [],
            allCategories: [],
            categoryList: [],
            searchedCategories: [],
            savedPostIds: [],
            search: "",
            modal: false,
            selectedPostId: "",
            firstColumnPosts: [],
            secondColumnPosts: [],
            thirdColumnPosts: [],
            fourthColumnPosts: [],
            recentSearches: [],
            width: window.innerWidth,
            height: window.innerHeight,
            isMobile: false,
            isTablet: false,
            tab: 'thread',
            titleChanged: false,
            floatingTitles: [],
            floating: false,
            floatingSec: false,
            floatingThird: false,
            floatingFourth: false,
            floatingFifth: false,
            floatingSixth: false,
            floatingFalse: false
        }
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
        // await this.getCurrentTab();

        // window.addEventListener('storage', async function (e) {
        //     if (e.storageArea === this.localStorage) {
        //         await this.getCurrentTab()
        //     } else {

        //     }
        //     // else, event is caused by an update to localStorage, ignore it
        // });

        await this.fetchPosts();
        await this.fetchCategories();
        await this.fetchSavedPostIds();
        await this.fetchAllCategories();
        await this.fetchRecentSearches();
        await this.fetchAllFloatingTitles();
        this.moveElements();

    }

    // getCurrentTab = async () => {
    //     var tab = this.props.store.getState().tab;
    //     console.log(tab)
    //     this.setState({ tab: tab });
    //     if (tab == 'thread') {
    //         this.setState({ isThreads: true })
    //     } else if (tab == 'quote') {
    //         this.setState({ isThreads: false })
    //     }

    // }

    handleResize = () => {
        this.setState({
            isMobile: window.innerWidth <= 768, isTablet: window.innerWidth <= 992 && window.innerWidth > 768
        });
    }

    toggle = async () => {
        this.setState({
            modal: false
        })
        await this.fetchSavedPostIds();
    }

    fetchPosts = async (page = 1, per_page = 10) => {
        var model = {};
        model.page = page;
        model.per_page = per_page;
        model.search = this.state.keywords;
        model.isTweet = true;
        var searchCategories = [];
        this.state.selectedUnPopularCategories.map((cat, index) => searchCategories.push(cat))
        this.state.selectedCategories.map((cat, index) => searchCategories.push(cat));
        model.categoryIds = searchCategories;
        var url = '/api/Post/SearchPosts';
        console.log(url)
        var response = await postData(url, model);
        if (response.ok) {
            if (page == 1) {
                this.state.posts = response.data.data;
                this.setState({ totalPosts: response.data.total })
            } else {
                var posts = this.state.posts;
                response.data.data.map((post, i) => {
                    posts.push(post);
                })
                this.state.posts = posts;
            }
            this.setState({ posts: this.state.posts });
            console.log(this.state.posts.length);
            await this.diversifyPostColumns();
        }
    }

    paginate = async () => {
        this.state.page = this.state.page + 1;
        this.setState({ page: this.state.page });
        this.fetchPosts(this.state.page, this.state.perPage)
    }

    diversifyPostColumns = async () => {
        var posts = this.state.posts;
        var firstColumnPosts = [];
        var secondColumnPosts = [];
        var thirdColumnPosts = [];
        var fourthColumnPosts = [];
        posts.map(async (post, index) => {
            // var embedCode = await this.getEmbedPost(post.contentSourceLink);
            // post.embedCode = embedCode;
            if (index % 4 == 0) {
                firstColumnPosts.push(post);
            } else if (index % 4 == 1) {
                secondColumnPosts.push(post);
            } else if (index % 4 == 2) {
                thirdColumnPosts.push(post);
            } else {
                fourthColumnPosts.push(post);
            }
        })
        this.setState({ firstColumnPosts, secondColumnPosts, thirdColumnPosts, fourthColumnPosts });
    }

    fetchCategories = async () => {
        var url = apiurl + '/api/Setting/GetPopularCategories'
        var response = await getData(url);
        if (response.ok) {
            this.state.popularCategories = response.data;
            this.setState({ popularCategories: this.state.popularCategories });
        }
    }

    fetchAllCategories = async () => {
        var url = apiurl + '/api/Setting/GetAllCategories'
        var response = await getData(url);
        if (response.ok) {
            this.state.allCategories = response.data;
            this.setState({ allCategories: this.state.allCategories });
            var categoryList = [];
            response.data.map((category, index) => {
                categoryList.push({ label: category.name, value: category.id });
            })
            this.setState({ categoryList });
        }
    }

    fetchAllFloatingTitles = async () => {
        var url = apiurl + '/api/Setting/GetAllFloatingTitles'
        var response = await getData(url);
        if (response.ok) {
            this.state.floatingTitles = response.data;
            this.setState({ floatingTitles: this.state.floatingTitles });
        }
    }

    updateRecentSearches = async (recentSearch) => {
        var url = '/api/User/UpdateRecentSearches'
        var response = await postData(url, recentSearch);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.state.recentSearches = response.data.data;
                this.setState({ recentSearches: this.state.recentSearches });
            }
        }
    }

    fetchRecentSearches = async () => {
        var url = apiurl + '/api/user/GetRecentSearches'
        var response = await getData(url);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.state.recentSearches = response.data.data;
                this.setState({ recentSearches: this.state.recentSearches });
            }
        }
    }

    updateSearch = async (e) => {
        var categories = [];
        var keywords = [];
        var lastSearch = {};
        console.log(e);
        if (e != null) {
            e.map((selected, index) => {
                if (selected.value == selected.label) {
                    keywords.push(selected.value);
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: false }
                    }
                } else {
                    categories.push(selected.value)
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: true }
                    }
                }
            })
        }

        if ((categories.length + keywords.length) > this.state.selectedUnPopularCategories.length && isInRole('user')) {
            console.log("triggered")
            this.updateRecentSearches(lastSearch)
        }

        await this.setState({ keywords, selectedUnPopularCategories: categories });
        await this.fetchPosts()

    }

    fetchSavedPostIds = async () => {
        var url = apiurl + '/api/User/GetAllUserSavedPostIds'
        var response = await getData(url);
        if (response.ok) {
            this.state.savedPostIds = response.data.data;
            this.setState({ savedPostIds: this.state.savedPostIds });
        }
    }

    moveElements = () => {
        let parentThis = this;
        setInterval(parentThis.elementChangeState, 1000)
        setTimeout(setInterval(parentThis.elementChangeStateSec, 1000), 500)
        setTimeout(setInterval(parentThis.elementChangeStateThird, 700), 1000)
        setTimeout(setInterval(parentThis.elementChangeStateFourth, 800), 300)
        setTimeout(setInterval(parentThis.elementChangeStateFifth, 800), 400)
        setTimeout(setInterval(parentThis.elementChangeStateSixth, 900), 500)

    }

    elementChangeState = () => {
        this.setState({ floating: !this.state.floating });
    }

    elementChangeStateSec = () => {
        this.setState({ floatingSec: !this.state.floatingSec });
    }

    elementChangeStateThird = () => {
        this.setState({ floatingThird: !this.state.floatingThird });
    }

    elementChangeStateFourth = () => {
        this.setState({ floatingFourth: !this.state.floatingFourth });
    }

    elementChangeStateFifth = () => {
        this.setState({ floatingFifth: !this.state.floatingFifth });
    }

    elementChangeStateSixth = () => {
        this.setState({ floatingSixth: !this.state.floatingSixth });
    }

    fetchSearchCategories = async (input) => {

        var url = apiurl + '/api/Setting/SearchCategories?search=' + input
        var response = await getData(url);
        if (response.ok) {
            console.log(response.data)
            var newSearchedCategories = [];
            var isSearchExisted = response.data.findIndex(x => x.name.toLowerCase() == input.toLowerCase())
            if (isSearchExisted == -1) {
                newSearchedCategories.push({ id: "0", name: input })
            }
            response.data.map((cat, index) => { newSearchedCategories.push({ id: cat.id, name: cat.name, image: cat.image }) });
            this.state.searchedCategories = newSearchedCategories;
            this.setState({ searchedCategories: this.state.searchedCategories });
        }
    }

    debounceSearchCategories = debounce(this.fetchSearchCategories, timeout);

    selectUnselectCategory = (id) => {
        var index = this.state.selectedCategories.findIndex(x => x == id);
        if (index == -1) {
            this.state.selectedCategories.push(id);

        } else {
            this.state.selectedCategories.splice(index, 1);
        }
        this.setState({ selectedCategories: this.state.selectedCategories });
    }

    selectUnselectUnPopularCategory = (category) => {
        if (category.id == "0") {
            var index = this.state.keywords.findIndex(x => x.toLowerCase() == category.name.toLowerCase());
            if (index == -1) {
                this.state.keywords.push(category.name);
            }
            this.setState({ keywords: this.state.keywords });
        } else {
            var index = this.state.selectedUnPopularCategories.findIndex(x => x.id == category.id);
            if (index == -1) {
                this.state.selectedUnPopularCategories.push(category.id);

            } else {
                this.state.selectedUnPopularCategories.splice(index, 1);
            }
            this.setState({ selectedUnPopularCategories: this.state.selectedUnPopularCategories });
        }
    }

    removeKeywords = (key) => {
        var index = this.state.keywords.findIndex(x => x.toLowerCase() == key.toLowerCase());
        if (index == -1) {
            this.state.keywords.push(key);
        } else {
            this.state.keywords.splice(index, 1);
        }
        this.setState({ keywords: this.state.keywords });
    }

    bookmark = (id) => {
        this.setState({ selectedPostId: id, modal: true })
    }

    selectCustomStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '60px',
            borderRadius: '10px',
            textAlign: 'center',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            textAlign: 'center',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'center',
        }),
        option: (provided, state) => ({
            ...provided,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            align: 'center',
            color: 'black',
            fontSize: '18px',
            marginLeft: this.state.isMobile || this.state.isTablet ? '10px' : '150px'
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            display: 'none'
        }),
    };

    getEmbedPost = async (url) => {
        var url = "https://publish.twitter.com/oembed?url=" + url;
        var response = await getData(url);
        if (response.ok) {
            return response.data.html
        }
    }



    render() {
        var testCategories = ['Ethereum', 'Binance', 'Crypto']
        return (
            <div className="container">
                {
                    <>
                        <div className="row">
                            {/* <div className="col-xl-3 col-lg-4 col-md-5">
                        <Categories onSelected={(id, name) => { setcategoryVal(id); setcategoryName(name) }} />
                    </div> */}
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                {/* <div className="sidebar-categories mb-5">
                            <div className="head">
                            {categoryName}
                        </div>
                        </div> */}
                                <section className="lattest-product-area pb-40 category-list">
                                    <div className='row'>
                                        <div className='col-md-3'></div>
                                        <div className='col-md-6'>
                                            {
                                                this.state.isMobile ? <></> :
                                                    <div className='row'>
                                                        <div className='col-md-1'></div>
                                                        <div className='col-md-5'><p style={{ fontWeight: 'bold', transition: "3s", opacity: this.state.titleChanged ? 1 : 0, transform: this.state.floating ? 'translateY(10px)' : 'translateY(-10px)', color: 'black' }} >{this.state.floatingTitles.length > 0 ? this.state.floatingTitles.find(x => x.position == "center-left").title : ""}</p> </div>
                                                        <div className='col-md-5'><p style={{ fontWeight: 'bold', transition: "3s", opacity: this.state.titleChanged ? 1 : 0, transform: this.state.floatingSec ? 'translateY(-10px)' : 'translateY(10px)', color: 'black' }} >{this.state.floatingTitles.length > 0 ? this.state.floatingTitles.find(x => x.position == "center-right").title : ""}</p> </div>

                                                    </div>
                                            }

                                        </div>
                                        <div className='col-md-3'></div>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        {this.state.isMobile ? <></> :
                                            <div className='col-md-3'>
                                                <p style={{ fontWeight: 'bold', transition: "3s", opacity: this.state.titleChanged ? 1 : 0, transform: this.state.floatingThird ? 'translateY(10px)' : 'translateY(-10px)', color: 'black' }} >{this.state.floatingTitles.length > 0 ? this.state.floatingTitles.find(x => x.position == "left-top").title : ""}</p>
                                                <br />
                                                <p style={{ fontWeight: 'bold', transition: "3s", opacity: this.state.titleChanged ? 1 : 0, transform: this.state.floatingFourth ? 'translateY(-10px)' : 'translateY(10px)', color: 'black' }} >{this.state.floatingTitles.length > 0 ? this.state.floatingTitles.find(x => x.position == "left-bottom").title : ""}</p>
                                            </div>
                                        }

                                        <div className='col-md-6'>
                                            <div align="center" onMouseEnter={() => this.setState({ titleChanged: true })} onMouseLeave={() => this.setState({ titleChanged: false })} style={{ height: 150 }}>
                                                <b style={{ fontSize: 50, color: 'black' }} className="title-font">{this.state.titleChanged ? "thread" : "threadoor"}</b>
                                                {
                                                    this.state.titleChanged ? <>
                                                        <OFontSizeChangeCard />
                                                        <OFontSizeChangeCard />
                                                        <OFontSizeChangeCard />
                                                        <OFontSizeChangeCard />
                                                        <RFontSizeChangeCard />
                                                        <RFontSizeChangeCard />
                                                        <RFontSizeChangeCard />
                                                    </>
                                                        : <></>
                                                }
                                            </div>
                                            <CreatableSelect
                                                closeMenuOnSelect={false}
                                                isMulti
                                                options={this.state.categoryList}
                                                onChange={(e) => this.updateSearch(e)}
                                                placeholder={<div><><i class="fa fa-search"></i> What are you looking for?</></div>}
                                                styles={this.selectCustomStyle}
                                            />
                                            <br />
                                            <div className='container-fluid'>
                                                <div className='row'>
                                                    <b style={{ fontSize: 14, marginTop: 7 }}>Recent Searches &nbsp;</b>
                                                    {
                                                        this.state.recentSearches.slice(0, 5).map((search, index) => {
                                                            var category = null;
                                                            if (search.isCategory) {
                                                                category = this.state.allCategories.find(x => x.id == search.value);
                                                                return <div onClick={() => { this.selectUnselectUnPopularCategory(category); this.fetchPosts() }} style={{ cursor: 'pointer', marginBottom: 5 }}><TagCardSmall selected={false} icon={category != null ? category.image : null} tag={category != null ? category.name : null}></TagCardSmall></div>
                                                            } else {
                                                                return <div onClick={() => { this.removeKeywords(search.value); this.fetchPosts() }} style={{ cursor: 'pointer', marginBottom: 5 }}><TagCardSmall selected={false} tag={search.value}></TagCardSmall></div>
                                                            }
                                                        }

                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {/* <input type='text' className='form-control' placeholder='Search' onChange={(e) => this.debounceSearchCategories(e.target.value)} /> */}
                                        </div>
                                        {
                                            this.state.isMobile ? <></> :
                                                <div className='col-md-3'>
                                                    <p style={{ fontWeight: 'bold', transition: "3s", opacity: this.state.titleChanged ? 1 : 0, transform: this.state.floatingFifth ? 'translateY(-10px)' : 'translateY(10px)', color: 'black' }} >{this.state.floatingTitles.length > 0 ? this.state.floatingTitles.find(x => x.position == "right-top").title : ""}</p>
                                                    <br />
                                                    <p style={{ fontWeight: 'bold', transition: "3s", opacity: this.state.titleChanged ? 1 : 0, transform: this.state.floatingSixth ? 'translateY(10px)' : 'translateY(-10px)', color: 'black' }} >{this.state.floatingTitles.length > 0 ? this.state.floatingTitles.find(x => x.position == "right-bottom").title : ""}</p>
                                                </div>
                                        }

                                    </div>
                                    <br /><br />
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <b style={{ fontSize: 14, marginTop: 7 }}>Popular Tags &nbsp;</b>
                                            {
                                                this.state.popularCategories.map((category, index) =>
                                                    <div onClick={() => { this.selectUnselectCategory(category.id); this.fetchPosts() }} style={{ cursor: 'pointer' }}><TagCardSmall selected={this.state.selectedCategories.includes(category.id)} icon={category.image} tag={category.name}></TagCardSmall></div>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <br />

                                    {
                                        this.state.isMobile ? <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                                <InfiniteScroll
                                                    dataLength={this.state.posts.length} //This is important field to render the next data
                                                    next={this.paginate}
                                                    hasMore={this.state.totalPosts != this.state.posts.length}
                                                    loader={<h4>Loading...</h4>}
                                                    endMessage={
                                                        <p style={{ textAlign: 'center' }}>
                                                            <b>You are up to date.</b>
                                                        </p>
                                                    }
                                                // below props only if you need pull down functionality

                                                >
                                                    {
                                                        this.state.posts.map((post, index) =>
                                                            <div key={index}>

                                                                <TweetCardIndex categories={post.postCategories != null ? post.postCategories.map((category, index) => { return category.category.name }) : []} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} images={post.postImages} contentSourceLink={post.contentSourceLink} profileURL={post.contentCreatorProfileURL} contentCreator={post.contentCreator} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : null} />

                                                                <br />

                                                            </div>
                                                        )
                                                    }
                                                </InfiniteScroll>
                                            </div>
                                        </div> :
                                            <>
                                                <div className='row'>
                                                    <div className='col-lg-3 col-md-4'>
                                                        {
                                                            this.state.firstColumnPosts.map((post, index) =>
                                                            <div key={index}>

                                                            <TweetCardIndex categories={post.postCategories != null ? post.postCategories.map((category, index) => { return category.category.name }) : []} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} images={post.postImages} contentSourceLink={post.contentSourceLink} profileURL={post.contentCreatorProfileURL} contentCreator={post.contentCreator} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : null} />

                                                            <br />

                                                        </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='col-lg-3 col-md-4'>
                                                        {
                                                            this.state.secondColumnPosts.map((post, index) =>
                                                                <div key={index}>

                                                                    <TweetCardIndex categories={post.postCategories != null ? post.postCategories.map((category, index) => { return category.category.name }) : []} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} images={post.postImages} contentSourceLink={post.contentSourceLink} profileURL={post.contentCreatorProfileURL} contentCreator={post.contentCreator} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : null} />

                                                                    <br />

                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='col-lg-3 col-md-4'>
                                                        {
                                                            this.state.thirdColumnPosts.map((post, index) =>
                                                                <div key={index}>

                                                                    <TweetCardIndex categories={post.postCategories != null ? post.postCategories.map((category, index) => { return category.category.name }) : []} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} images={post.postImages} contentSourceLink={post.contentSourceLink} profileURL={post.contentCreatorProfileURL} contentCreator={post.contentCreator} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : null} />
                                                                    <br />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='col-lg-3 col-md-4'>
                                                        {
                                                            this.state.fourthColumnPosts.map((post, index) =>
                                                                <div key={index}>

                                                                    <TweetCardIndex categories={post.postCategories != null ? post.postCategories.map((category, index) => { return category.category.name }) : []} isBookMarked={this.state.savedPostIds.includes(post.id)} bookmark={() => this.bookmark(post.id)} id={post.id} likeCount={post.likeCount} commentCount={post.commentCount} images={post.postImages} contentSourceLink={post.contentSourceLink} profileURL={post.contentCreatorProfileURL} contentCreator={post.contentCreator} name={post.contentCreatorName} username={post.contentCreatorUsername} content={post.title ? post.title : post.content} viewCount={post.viewCount} mentions={post.mentions != undefined ? JSON.parse(post.mentions) : null} />
                                                                    <br />
                                                                </div>
                                                            )
                                                        }
                                                    </div>

                                                </div>
                                                {
                                                    this.state.totalPosts != this.state.posts.length ? (
                                                        <div className='row' align='center'>
                                                            <div className='col-md-12' align='center'>
                                                                <a className="btn btn-default" onClick={() => {
                                                                    this.paginate();
                                                                }} align='center' style={{ color: 'white' }}>Load More</a>
                                                            </div>
                                                        </div>
                                                    ) : (<></>)
                                                }
                                            </>
                                    }



                                </section>
                            </div>
                        </div>
                        <FolderSaveModal toggle={this.toggle} modal={this.state.modal} title={'Bookmark To'}>
                            <SavePost toggle={this.toggle} postId={this.state.selectedPostId}></SavePost>
                        </FolderSaveModal>
                    </>

                }


            </div>

        )
    }
}