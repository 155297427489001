import React, { Component } from 'react';
import {
    Button,
    Form,
    Row,
} from "reactstrap";
import ColInput from '../../components/Form/Form.jsx';
import { getData, postData, putData } from '../../services/fetch.js';
import { MessageModal, UpdatePasswordModal } from '../../components/Tables/Modal.jsx';
import { defaultModel } from '../../components/DefaultModel.js';
import { apiurl, s3HostURL } from '../../services/common.js';
import ProfileIcon from '../../assets/img/icons/profile.png'
import { isInRole } from '../../services/storage.js';
import { Redirect } from 'react-router-dom';

export default class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model ? this.props.model : defaultModel.user,
            messageModal: false,
            message: '',
            name: "",
            updatePasswordModal: false
        }
        this.updateModel = this.updateModel.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount = async () => {
        await this.getUserData();
    }

    toggle = async () => {
        this.setState({ updatePasswordModal: false })
        // window.location.reload();
    }

    getUserData = async () => {
        var url = apiurl + '/api/user/getUserData'
        var result = await getData(url);
        if (result.ok) {
            var data = result.data;
            console.log(data);
            if (data.statusCode == 0) {
                this.state.model = data.data;
                this.state.name = data.data.name
                this.setState({ model: this.state.model, name: this.state.name })
            }
        }
    }

    updateModel = (e) => {
        let { name, value } = e.target;
        var key = name;
        this.setState(state => ({
            model: Object.assign({}, state.model, { [key]: value })
        }));
    }

    save = async (e) => {
        e.preventDefault();
        // this.state.model.chainAddress = "";
        this.setState({ model: this.state.model });
        var response = { ok: false, data: 'Something went wrong' };
        response = await postData('/api/User/UpdateUserInfo', this.state.model);
        if (response.ok) {
            // alert("Info Updated Successfully");
            // this.getUserData();
            window.location.reload();
        } else {
            this.setState({ messageModal: true, message: response.data });
        }
    }

    uploadImage = async (e) => {
        var base64image = await this.convertBase64(e.target.files[0]);
        // console.log(base64image);
        var type = base64image.startsWith("data:image") ? 0 : 1
        this.state.model.imageURL = base64image;
        this.setState({ model: this.state.model })
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }


    render() {
        if (!isInRole('user')) return <Redirect to={{
            pathname: "/user/index",
            state: { from: this.props.location }
          }} />
        const user = this.state.model
        return (
            <div>
                <MessageModal
                    toggle={this.toggle}
                    modal={this.state.messageModal}
                    title={'Error'}
                    message={this.state.message}
                />
                <div className='container' style={{ paddingTop: 50 }} >
                    <div className='row'>
                        <div className='col-md-3'></div>
                        <div className='col-md-6'>
                            <h2>My Profile</h2>
                            <hr />
                            {user.imageURL != null && user.imageURL != '' ? <>
                                <img src={user.imageURL.startsWith("data:image") ? user.imageURL : s3HostURL + "profile-images/" + user.imageURL} style={{ width: 100, height: 100, borderRadius: '50%' }} />
                            </> :
                                <>
                                    <img src={ProfileIcon} style={{ width: 100, height: 100, borderRadius: 50 }} />
                                </>}
                            <br /><br />
                            <input className='btn btn-default' type='file' onChange={(e) => this.uploadImage(e)} />
                            <hr />
                            <label>Username</label>
                            <input type='text' className='form-control' value={user.email} name='email' id='email' onChange={(e) => this.updateModel(e)} disabled />
                            <br />
                            <div className='row'>
                                <div className='col-md-8'>
                                    <label>Password</label>
                                    <input type='password' className='form-control' value={user.externalLoginProvider!=null?"":".........."} disabled />
                                </div>
                                <div className='col-md-4'>

                                    <button className='btn' style={{ marginTop: 35 }} onClick={() => this.setState({ updatePasswordModal: true })} disabled={user.externalLoginProvider!=null} >Update Password</button>
                                </div>
                            </div>
                            <br />
                            <label>Name</label>
                            <input type='text' className='form-control' value={user.name} name='name' id='name' onChange={(e) => this.updateModel(e)} />

                            <br /><br />
                            <div align='right'>
                                {/* <button className='btn' onClick={()=>this.setState({model: defaultModel.user})}>Cancel</button> */}
                                <button className='btn btn-default' onClick={(e) => this.save(e)}>Save</button>
                            </div>
                        </div>

                    </div>




                    {/* <Button color="primary" onClick={(e) => this.save(e)}>Save</Button> */}
                </div>
                <UpdatePasswordModal toggle={this.toggle} modal={this.state.updatePasswordModal} title={'Update Password'}>
                    <UpdatePassword toggle={this.toggle}></UpdatePassword>
                </UpdatePasswordModal>
            </div>
        )
    }
}


class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {oldPassword:'', newPassword:'', confirmPassword:''},
            messageModal: false,
            errorModal : false,
            message: ''
        }
    }

    toggle = () => {
        this.setState({ messageModal: false });
        this.props.toggle();
    }

    errorToggle = () => {
        this.setState({errorModal: false});
    }

    updateModel = (e) => {
        let { name, value } = e.target;
        var key = name;
        this.setState(state => ({
            model: Object.assign({}, state.model, { [key]: value })
        }));
    }

    save = async (e) => {
        e.preventDefault();
        // this.state.model.userID = this.props.userId
        this.setState({ model: this.state.model });
        var response = { ok: false, data: 'Something went wrong' };
        response = await postData('/api/Authentication/UpdatePassword', this.state.model);
        if (response.ok) {
            if (response.data.statusCode == 0) {
                this.setState({ messageModal: true, message: "Password Updated Successfully" });
            } else {
                this.setState({ errorModal: true, message: response.data.message });
            }
        } else {
            this.setState({ errorModal: true, message: response.data });
        }
    }

    render() {
        return (
            <div className='container'>
                <div className='row'></div>
                <div className='col-md-4'></div>
                <div classname='col-md-4'>
                    <label>Old Password</label>
                    <input type='password' className='form-control' id='oldPassword' name='oldPassword' onChange={(e) => this.updateModel(e)} />
                    <br />
                    <label>New Password</label>
                    <input type='password' className='form-control' id='newPassword' name='newPassword' onChange={(e) => this.updateModel(e)} />
                    <br />
                    <label>Confirm Password</label>
                    <input type='password' className='form-control' id='confirmPassword' name='confirmPassword' onChange={(e) => this.updateModel(e)} />

                    <br /><br />
                    <div align='right'>
                        <button className='btn' onClick={() => this.props.toggle()} >Cancel</button>
                        <button className='btn btn-default' onClick={(e) => this.save(e)}>Save</button>
                    </div>
                </div>
                <MessageModal toggle={this.toggle} modal={this.state.messageModal} title='Update Password' message={this.state.message} />
                <MessageModal toggle={this.errorToggle} modal={this.state.errorModal} title='Update Password' message={this.state.message} />
            </div>
        )
    }
}