import React from "react";

// reactstrap components
import {
  UncontrolledTooltip,
  Container,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { getData } from 'services/fetch';
import MaterialTable from "material-table";
import DetailPanel from "../../components/Tables/DetailPanel";
import SimpleModal, { ConfirmModal } from "../../components/Tables/Modal.jsx";
import DebtForm from "./DebtForm";
import {
  Redirect,
} from "react-router-dom";
import { defaultModel } from "../../components/DefaultModel";
import { deleteData, postData } from "../../services/fetch";
import { getDateForInput, getDateForDisplay, months, getYears } from "../../services/date";
import { base64ToArrayBuffer, saveByteArray } from "../../services/pdf";
import { arraySum, sort } from "../../services/common";
import { setPreferences, getPreferences, isInRole } from "../../services/storage";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import exportExcel, { excelType } from "../../services/excel";
import { TablePagination } from "@material-ui/core";
import DebtHistory from "./DebtHistory";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  }
}));

const tab = {
  color: 'white'
}

export default function Debts() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <Header />
      {/* Page content */}
      <Container className="mt--9" fluid>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab style={tab} label="Reminder" />
            <Tab style={tab} label="Unpaid" />
            <Tab style={tab} label="Paid" />
          </Tabs>
        </AppBar>
        {value === 0 && <DebtTabs title='Reminder' url='/api/debts/GetReminder?' hidePagination={true} />}
        {value === 1 && <DebtTabs title='Unpaid' url='/api/debts?type=1' />}
        {value === 2 && <DebtTabs title='Paid' url='/api/debts?type=2' paid={true} />}
      </Container>
    </div>
  );
}

export class DebtTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: null,
      modal: false,
      model: defaultModel.debt,
      action: '',
      redirectToLogin: false,
      selectedIds: [],
      idToDelete: '',
      receiptType: getPreferences('receipt') ? getPreferences('receipt') : 'slip',
      reportMonth: new Date().getMonth() + 1,
      reportYear: new Date().getFullYear(),
      qtyEditableID: '',
    };

    this.tableRef = React.createRef();
    this.delete = this.delete.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: false,
      deleteModal: false,
      model: defaultModel.debt,
      idToDelete: '',
    });
  }

  refreshTable = () => {
    console.log(this.tableRef);
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  delete = async () => {
    var response = await deleteData('/api/debts/' + this.state.idToDelete);
    if (response.ok) {
      this.toggle();
      this.refreshTable();
    }
  }

  render() {
    const thisprops = this;
    const years = getYears();
    if (this.state.redirectToLogin) return <Redirect to={{
      pathname: "/auth/login",
      state: { from: this.props.location }
    }} />
    return (
      <>
        <MaterialTable
          title={this.props.title}
          tableRef={this.tableRef}
          components={{
            Pagination: props => (
              thisprops.props.hidePagination ?
                (<></>) : (<TablePagination {...props} />)
            ),
          }}
          columns={
            this.props.paid ? [{ title: 'Customer', field: 'customerName' }] :
              [
                { title: 'Customer', field: 'customerName', render: rowData => <span>{rowData.customerName}</span> },
                { title: 'Debt', field: 'amount', render: rowData => <span>{rowData.amount}</span> },
                {
                  title: 'Due Date',
                  field: 'dueDate',
                  render: rowData => {
                    var date = rowData.dueDate ? getDateForInput(rowData.dueDate) : '';
                    return (
                      this.state.qtyEditableID == rowData.customerID ?
                        (
                          <div className='qtyEditable' style={{ width: 300 }}>
                            <input type='date' className='form-control qtyEditable' defaultValue={date} onChange={e => date = e.target.value} style={{ width: 200, display: 'inline' }} />
                            <i className='ni ni-check-bold text-blue qtyEditable' style={{ float: 'right' }}
                              onClick={async () => {
                                var response = await getData('/api/Debts/SetDueDate?customerid=' + rowData.customerID + '&date=' + date);
                                if (response.ok) {
                                  this.setState({ qtyEditableID: '' });
                                  this.refreshTable();
                                } else {
                                  alert(response.data);
                                }
                              }}
                              style={{ border: '1px solid', borderRadius: 50, padding: 5, marginLeft: 2 }}></i>
                          </div>
                        ) :
                        (
                          <div style={{ width: 300 }}>
                            <span>{rowData.dueDate}</span>
                            <i className='ni ni-time-alarm text-blue qtyEditable' style={{ float: 'right' }}
                              onClick={() => {
                                this.setState({ qtyEditableID: rowData.customerID });
                              }}></i>
                          </div>
                        )
                    )
                  }
                },
              ]
          }
          data={async function (query) {
            let url = thisprops.props.url;
            url += '&search=' + query.search
            if (!thisprops.props.hidePagination) {
              url += '&per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
            }
            var result = await getData(url);
            if (result.ok) {
              var data = result.data;
              data.data = data.data.map(x => Object.assign({}, x, { date: getDateForInput(x.date) }));
              return {
                data: data.data,
                page: data.page - 1,
                totalCount: data.total
              }
            } else if (result.status === 401) {
              thisprops.setState({ redirectToLogin: true });
            } else {
              return {
                data: [],
                page: 1,
                totalCount: 0
              }
            }
          }}
          detailPanel={rowData => {
            return (
              <DetailPanel
                data={[
                  {
                    label: 'History', full: true, value:
                      <DebtHistory
                        customerID={rowData.customerID}
                        customerName={rowData.customerName}
                      />
                  },
                ]}
              />
            )
          }}
          onRowClick={(event, rowData, togglePanel) => { if (!event.target.className.includes('qtyEditable')) { togglePanel(); this.setState({ qtyEditableID: '' }); } }}
          options={{
            search: true,
            selection: false,
            debounceInterval: 500
          }}
          onSelectionChange={(rows) => {
            this.setState(state => ({
              selectedIds: rows.map(row => row.id)
            }));
          }}
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: this.refreshTable
            },
            {
              icon: 'add_circle',
              tooltip: 'Add Debt',
              isFreeAction: true,
              onClick: () => { this.setState({ action: 'create', modal: true }); },
            },
            // {
            //   tooltip: 'Edit',
            //   icon: 'edit',
            //   onClick: async (evt, data) => {
            //     this.setState({ modal: true, model: JSON.parse(JSON.stringify(data)), action: 'edit' });
            //   }
            // },
            // {
            //   tooltip: 'Remove',
            //   icon: 'delete',
            //   onClick: (evt, data) => this.setState({ deleteModal: true, idToDelete: data.id })
            // }
          ]}
        />

        <SimpleModal toggle={this.toggle} modal={this.state.modal} title={'Debt'}>
          <DebtForm
            model={this.state.model}
            action={this.state.action}
            toggle={this.toggle}
            refreshTable={this.refreshTable}
          />
        </SimpleModal>
        <ConfirmModal
          toggle={this.toggle}
          modal={this.state.deleteModal}
          title={'Delete Confirmation'}
          confirmText={'Are you sure you want to delete this debt?'}
          buttonText={'Delete'}
          onConfirm={this.delete}
        />
      </>
    );
  }
}
