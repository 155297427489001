import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
import {
  Redirect,
} from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";
import { getData } from "../services/fetch";
import { isInRole } from "../services/storage";
import twitterLogo from '../assets/img/icons/twitterLogo.jpg'


class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logo: 'argon-react.png',
    }

    // this.getLogo = this.getLogo.bind(this);
  }
  async componentDidMount() {
    // await this.getLogo();
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // getLogo = async () => {
  //   var response = await getData('/api/Shops/GetCurrentShopLogo');
  //   if (response.ok) {
  //     this.setState({ logo: response.data });
  //   }
  // }
  render() {
    if (!isInRole('admin') && !isInRole('staff')) {
      if (isInRole('user')) {
        return <Redirect to={{
          pathname: "/user/index",
          state: { from: this.props.location }
        }} />
      }
      return <Redirect to={{
        pathname: "/auth/login",
        state: { from: this.props.location }
      }} />
    }
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: twitterLogo,
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
