import { createStore } from 'redux'

const initialState = {
    search: '',
    tab:'thread'
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_SEARCH':
            return Object.assign({}, state, { search: action.value });
        case 'UPDATE_TAB' :
            return Object.assign({}, state, { tab: action.payload.tab });
        default:
            return state;
    }
}

const store = createStore(reducer);

export default store;