import React, { Component } from 'react';
import {
  Button,
  Form,
  Row,
} from "reactstrap";
import ColInput from '../../components/Form/Form.jsx';
import { postData, putData, getData } from '../../services/fetch.js';
import { MessageModal } from '../../components/Tables/Modal.jsx';
import { defaultModel } from '../../components/DefaultModel.js';
import { debtStatus } from '../../services/enum.js';
import { timeout } from '../../services/common.js';
import debounce from "debounce-promise";
import { getDateForInput } from '../../services/date.js';

export default class DebtForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dueDate: '',
      model: this.props.model ? this.props.model : defaultModel.debt,
      messageModal: false,
      message: '',
    }
    this.updateModel = this.updateModel.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.getDueDate(this.state.model.customerID);
  }

  getDueDate = async (customerID) => {
    if (!!customerID) {
      var response = await getData('/api/customers/' + customerID);
      if (response.ok) {
        this.setState({ dueDate: response.data.debtDueDate ? getDateForInput(response.data.debtDueDate) : '' });
      }
    } else {
      this.setState({ dueDate: '' });
    }
  }

  updateModel = (e) => {
    let { name, value } = e.target;
    var key = name;
    this.setState(state => ({
      model: Object.assign({}, state.model, { [key]: value })
    }));
  }

  save = async (e) => {
    e.preventDefault();
    var response = { ok: false, data: 'Something went wrong' };
    var debtdto = {
      debt: this.state.model,
      debtDueDate: this.state.dueDate
    };
    if (this.props.action === 'create') {
      response = await postData('/api/debts', debtdto);
    } else if (this.props.action === 'edit') {
      response = await putData('/api/debts/' + this.state.model.id, debtdto);
    }
    if (response.ok) {
      this.props.toggle();
      this.props.refreshTable();
    } else {
      this.setState({ messageModal: true, message: response.data });
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      messageModal: !prevState.messageModal
    }));
  }

  getCustomers = async inputValue => {
    var response = await getData("/api/Customers/GetCustomerSelectList?search=" + inputValue);
    return response.data;
  };

  render() {
    const debouncedLoadCustomers = debounce(this.getCustomers, timeout, { leading: true });
    return (
      <div>
        <MessageModal
          toggle={this.toggle}
          modal={this.state.messageModal}
          title={'Error'}
          message={this.state.message}
        />
        <Form onSubmit={this.save}>
          <h6 className="heading-small text-muted mb-4">
            Debt information
          </h6>
          <div className="pl-lg-4">
            <Row>
              <ColInput
                col={12}
                label={'Date'}
                defaultValue={this.state.model.date}
                id={'date'}
                type={'date'}
                onChange={this.updateModel}
                required={true}
              />
              <ColInput
                col={12}
                label={'Customer'}
                defaultValue={this.state.model.customerID}
                id={'customerID'}
                type={'AsyncSelect'}
                defaultValue={this.state.model.customer ? { value: this.state.model.customer.id, label: this.state.model.customer.name } : {}}
                loadOptions={inputValue => debouncedLoadCustomers(inputValue)}
                onChange={(e) => {
                  this.getDueDate(e.value);
                  this.setState({
                    model: { ...this.state.model, ["customerID"]: !!e ? e.value : '00000000-0000-0000-0000-000000000000' }
                  })
                }}
                required={true}
              />
              <ColInput
                col={12}
                label={'Amount'}
                defaultValue={this.state.model.amount}
                id={'amount'}
                type={'number'}
                onChange={this.updateModel}
                required={true}
              />
              <ColInput
                col={12}
                label={'Status'}
                defaultValue={this.state.model.status.toString()}
                id={'status'}
                type={'select'}
                onChange={this.updateModel}
                options={[{ label: 'Give', value: debtStatus.give }, { label: 'Collect', value: debtStatus.collect }]}
                required={true}
                disableDefaultOption={true}
              />
              <ColInput
                col={12}
                label={'Due Date'}
                value={this.state.dueDate}
                id={'date'}
                type={'date'}
                onChange={this.updateModel}
              />
            </Row>
          </div>
          <hr className="my-4" />
          {/* Description */}
          {/* <h6 className="heading-small text-muted mb-4">About him/her</h6>
          <div className="pl-lg-4">
            <Row>
              <ColInput
                col={12}
                label={'Remark'}
                defaultValue={this.state.model.remark}
                id={'remark'}
                type={'textarea'}
                rows={4}
                onChange={this.updateModel}
              />
            </Row>
          </div> */}
          <div className="pl-lg-4 text-right">
            <Button color="primary" type="submit">Save</Button>
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          </div>
        </Form>
      </div>
    )
  }
}
