import React, { Component } from "react"
import { OFontSizeChangeCard, ProfileCardIndex, RFontSizeChangeCard, TagCardSmall } from "../../components/TwitterCard";
import { apiurl } from "../../services/common";
import { getData, postData } from "../../services/fetch";
import CreatableSelect from 'react-select/creatable';

export default class TwitterUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            categoryList: [],
            popularCategories: [],
            selectedUnPopularCategories: [],
            selectedCategories: [],
            keywords: [],
            sort: 0,
            sortBys: [{ label: 'Thread Count', value: 0 }, { label: 'Quote Count', value: 1 }, { label: 'View Count', value: 2 }, { label: 'Like Count', value: 3 }],
            descOrder: true,
            totalUsers: 0,
            page: 1,
            perPage: 21,
            titleChanged:false
        }
        this.fetchUsers();
        this.fetchAllCategories();
    }

    fetchUsers = async (page = 1, per_page = 21) => {
        var model = {};
        model.page = page;
        model.per_page = per_page;
        model.search = this.state.keywords;
        model.sort = this.state.sort;
        model.isDescending = this.state.descOrder;
        var searchCategories = [];
        this.state.selectedUnPopularCategories.map((cat, index) => searchCategories.push(cat))
        this.state.selectedCategories.map((cat, index) => searchCategories.push(cat));
        model.categoryIds = searchCategories;
        var url = '/api/ContentCreator/SearchContentCreators';
        console.log(url)
        var response = await postData(url, model);
        if (response.ok) {
            if (page == 1) {
                this.state.users = response.data.data;
                this.setState({ totalUsers: response.data.total })
                this.setState({ users: this.state.users });
            }
            else {
                var users = this.state.users;
                response.data.data.map((user, i) => {
                    users.push(user);
                })
                this.state.users = users;
                this.setState({ users: this.state.users })
            }
        }
    }

    fetchAllCategories = async () => {
        var url = apiurl + '/api/Setting/GetAllTagCategories'
        var response = await getData(url);
        if (response.ok) {
            this.state.allCategories = response.data;
            this.setState({ allCategories: this.state.allCategories });
            var categoryList = [];
            response.data.map((category, index) => {
                categoryList.push({ label: category.name, value: category.id });
            })
            this.setState({ categoryList });
        }
    }

    updateSearch = async (e) => {
        var categories = [];
        var keywords = [];
        var lastSearch = {};
        console.log(e);
        if (e != null) {
            e.map((selected, index) => {
                if (selected.value == selected.label) {
                    keywords.push(selected.value);
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: false }
                    }
                } else {
                    categories.push(selected.value)
                    if (e.length - 1 == index) {
                        lastSearch = { value: selected.value, isCategory: true }
                    }
                }
            })
        }

        if ((categories.length + keywords.length) > this.state.selectedUnPopularCategories.length) {
            console.log("triggered")
            // this.updateRecentSearches(lastSearch)
        }

        await this.setState({ keywords, selectedUnPopularCategories: categories });
        await this.fetchUsers()

    }

    selectCustomStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '60px',
            borderRadius: '10px',
            textAlign: 'center',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            textAlign: 'center',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'center',
        }),
        option: (provided, state) => ({
            ...provided,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            align: 'center',
            color: 'black',
            fontSize: '18px',
            marginLeft: this.state.isMobile || this.state.isTablet ? '10px' : '150px'
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            display: 'none'
        }),
    };

    selectSortCustomStyle = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '40px',
            borderRadius: '10px',
            textAlign: 'center',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            textAlign: 'center',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'center',
        }),
        option: (provided, state) => ({
            ...provided,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            align: 'center',
            color: 'black',
            fontSize: '14px',
            marginLeft: this.state.isMobile || this.state.isTablet ? '10px' : '10px'
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            display: 'none'
        }),
    };

    updateSort = async (e) => {
        var sort = 0
        if (e != null) {
            sort = e.value;

        }
        this.state.sort = sort;
        await this.setState({ sort: this.state.sort });
        await this.fetchUsers();
    }

    changeSortOrder = async () => {
        await this.setState({ descOrder: !this.state.descOrder });
        await this.fetchUsers();

    }

    paginate = async () => {
        this.state.page = this.state.page + 1;
        this.setState({ page: this.state.page });
        this.fetchUsers(this.state.page, this.state.perPage)
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className='row'>
                        <div className='col-md-3'></div>
                        <div className='col-md-6'>
                            <div align="center" onMouseEnter={() => this.setState({ titleChanged: true })} onMouseLeave={() => this.setState({ titleChanged: false })} style={{ height: 150 }}>
                                <b style={{ fontSize: 50, color: 'black' }} className="title-font">{this.state.titleChanged ? "thread" : "threadoor"}</b>
                                {
                                    this.state.titleChanged ? <>
                                        <OFontSizeChangeCard />
                                        <OFontSizeChangeCard />
                                        <OFontSizeChangeCard />
                                        <OFontSizeChangeCard />
                                        <RFontSizeChangeCard />
                                        <RFontSizeChangeCard />
                                        <RFontSizeChangeCard />
                                    </>
                                        : <></>
                                }
                            </div>
                            <CreatableSelect
                                closeMenuOnSelect={false}
                                isMulti
                                options={this.state.categoryList}
                                onChange={(e) => this.updateSearch(e)}
                                placeholder={<div><><i class="fa fa-search"></i> Who are you looking for?</></div>}
                                styles={this.selectCustomStyle}
                            />
                            <br />

                        </div>
                        <div className='col-md-3'></div>
                    </div>
                    <br /><br />
                    {/* <div className='container-fluid'>
                        <div className='row'>
                            <b style={{ fontSize: 14, marginTop: 7 }}>Popular Tags &nbsp;</b>
                            {
                                this.state.popularCategories.map((category, index) =>
                                    <div onClick={() => { this.selectUnselectCategory(category.id); this.fetchPosts() }} style={{ cursor: 'pointer' }}><TagCardSmall selected={this.state.selectedCategories.includes(category.id)} icon={category.image} tag={category.name}></TagCardSmall></div>
                                )
                            }
                        </div>
                    </div> */}
                    <div className='row'>
                        <div className='col-md-8'>
                            {/* <div className='row'>
                                <b style={{ fontSize: 14, marginTop: 7 }}>Popular Categories &nbsp;</b>
                                {
                                    this.state.popularCategories.map((category, index) =>
                                        <div onClick={() => { this.selectUnselectCategory(category.id); this.fetchPosts() }} style={{ cursor: 'pointer' }}><TagCardSmall selected={this.state.selectedCategories.includes(category.id)} icon={category.image} tag={category.name}></TagCardSmall></div>
                                    )
                                }
                            </div> */}
                        </div>

                        <div className='col-md-3'>
                            <CreatableSelect
                                closeMenuOnSelect={true}
                                options={this.state.sortBys}
                                onChange={(e) => this.updateSort(e)}
                                placeholder={<div><>Sort By</></div>}
                                styles={this.selectSortCustomStyle}
                            />
                        </div>
                        <div className="col-lg-1 col-1" style={{ padding: 3, cursor: 'pointer' }} onClick={() => this.changeSortOrder()}>
                            <b style={{ fontSize: 20 }}><i className={this.state.descOrder ? "fa fa-sort-amount-desc" : "fa fa-sort-amount-asc"}></i></b>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        {
                            this.state.users.map((user, i) =>
                                <div className="col-lg-4 col-md-4">
                                    <ProfileCardIndex bookmarkCount={user.bookmarkCount} userId={user.contentCreatorID} username={user.contentCreatorUsername} profileURL={user.contentCreatorProfileURL} threadCount={user.threadCount} quoteCount={user.quoteCount} viewCount={user.viewCount} likeCount={user.likeCount} categories={user.contentCreatorCategories} />
                                </div>
                            )
                        }


                    </div>
                    <br />
                    {
                        this.state.totalUsers != this.state.users.length ? (
                            <div className='row' align='center'>
                                <div className='col-md-12' align='center'>
                                    <a className="btn btn-default" onClick={() => {
                                        this.paginate();
                                    }} align='center' style={{ color: 'white' }}>Load More</a>
                                </div>
                            </div>
                        ) : (<></>)
                    }
                </div>
            </>
        )
    }
}