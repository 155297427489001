import React, { Component } from 'react';
import {
    Button,
    Form,
    Row,
} from "reactstrap";
import ColInput from '../../components/Form/Form.jsx';
import { postData, putData } from '../../services/fetch.js';
import { MessageModal } from '../../components/Tables/Modal.jsx';
import { defaultModel } from '../../components/DefaultModel.js';
import Select from 'react-select';
import { ProfileCardIndex } from '../../components/TwitterCard.js';
import { apiurl } from '../../services/common.js';

export default class CreatorForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model ? this.props.model : defaultModel.creator,
            messageModal: false,
            message: '',
            categoryList : [],
            selectedCategories : [],
            selectedCategoryItems : []
        }
        this.updateModel = this.updateModel.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount = () => {
        this.getCategoryData();
        
        if (this.props.action == "edit") {
            this.getCreator();
        }
    }

    updateModel = (e) => {
        let { name, value } = e.target;
        var key = name;
        this.setState(state => ({
            model: Object.assign({}, state.model, { [key]: value })
        }));
    }

    save = async (e) => {
        e.preventDefault();
        var request = {creatorID : this.state.model.id, categoryIDs : this.state.selectedCategories}
        var response = { ok: false, data: 'Something went wrong' };
        response = await postData('/api/ContentCreator/UpdateCreatorTags', request);
        if (response.ok) {
            this.props.toggle();
            this.props.refreshTable();
        } else {
            this.setState({ messageModal: true, message: response.data });
        }
    }

    getCategoryData = () => {
        fetch(apiurl + "/api/Setting/GetAllTagCategories", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache'
            },
        }).then(async (response) => {
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                var data = [];
                if (response.status !== 204) {
                    var promise = response.json();
                    await promise.then(x => data = x);
                }
                var categoryList = [];
                data.map((category, i) => {
                    categoryList.push({ value: category.id, label: category.name });
                    console.log(category)
                })
                this.state.categoryList = categoryList;
                this.setState({ categoryList: this.state.categoryList });
            }
        })
    }

    getCreator() {
        fetch(apiurl + "/api/ContentCreator/" + this.state.model.id, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache'
            },
        }).then(async (response) => {
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                var data = [];
                if (response.status !== 204) {
                    var promise = response.json();
                    await promise.then(x => data = x);
                }
                this.state.model = data;
                var selectedCategoryItems = [];
                data.contentCreatorCategories.map((category, i) => {
                    selectedCategoryItems.push({ value: category.category.id, label: category.category.name, postId: this.state.postId });
                })
                this.state.selectedCategoryItems = selectedCategoryItems;
                this.setState({ model: this.state.model, selectedCategoryItems: this.state.selectedCategoryItems });
                console.log(this.state.model);
            }
        })
    }

    changeSelectedCategories = (e) => {
        var selectedCategories = [];
        var selectedCategoryItems = [];
        if (e != null) {
            e.map((item, i) => {
                selectedCategories.push(item.value);
                selectedCategoryItems.push(item);
            })
        }

        this.state.selectedCategories = selectedCategories;
        this.state.selectedCategoryItems = selectedCategoryItems;
        this.setState({ selectedCategories: this.state.selectedCategories, selectedCategoryItems: this.state.selectedCategoryItems });
    }

    toggle = () => {
        this.setState(prevState => ({
            messageModal: !prevState.messageModal
        }));
    }

    render() {
        var user = this.state.model;
        return (
            <div>
                <MessageModal
                    toggle={this.toggle}
                    modal={this.state.messageModal}
                    title={'Error'}
                    message={this.state.message}
                />
                <Form onSubmit={this.save}>
                    <h6 className="heading-small text-muted mb-4">
                        Creator information
                    </h6>
                    <div className="pl-lg-4">
                        <div className='row'>
                            <div className='col-md-6'>
                                <ProfileCardIndex userId={user.contentCreatorID} username={user.contentCreatorUsername} profileURL={user.contentCreatorProfileURL} threadCount={user.threadCount} viewCount={user.viewCount} likeCount={user.likeCount} categories={user.contentCreatorCategories} />
                            </div>
                            <div className='col-md-6'>
                            <label className='form-control-label'>Tags</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={this.state.categoryList}
                                    onChange={(e) => this.changeSelectedCategories(e)}
                                    value={this.state.selectedCategoryItems}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="pl-lg-4 text-right">
                        <Button color="primary" type="submit">Save</Button>
                        <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                    </div>
                </Form>
            </div>
        )
    }
}
