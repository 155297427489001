export function debounce(func, wait, immediate = false) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export function sort(array, field) {
	return array.sort(function (a, b) { return (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0); })
}

export function arrayRemove(arr, value, field = 'id') {
	return arr.filter(function (ele) {
		return ele[field] != value;
	});
}

export function arrayRemoveByIndex(arr, i) {
	return arr.filter(function (value, index) {
		return i != index;
	});
}

export function arraySum(arr, field) {
	return arr.length > 0 ? arr.sum(field) : 0;
}

Array.prototype.sum = function (prop) {
	var total = 0
	for (var i = 0, _len = this.length; i < _len; i++) {
		total += this[i][prop]
	}
	return total
}

export const timeout = 500;
// export const apiurl = 'https://ec2-52-91-253-29.compute-1.amazonaws.com'
export const apiurl = 'https://twitter-api.threadoor.com'
// export const apiurl = 'https://twitter-api-prod.threadoor.com'
// export const apiurl = 'https://localhost:7186';
export const twitterbearer = 'AAAAAAAAAAAAAAAAAAAAAApEegEAAAAAsomLG4gT10P%2FMCec4aoIsKAezt8%3D8Jng6Rz4KfYGigqKpwXLgzuUl1PfCOJjA10s2CxYlDr2uKvKWO';
export const s3HostURL = "https://twitter-portfolio.s3.ap-southeast-1.amazonaws.com/"
// export const s3HostURL = "https://twitter-portfolio-prod.s3.ap-southeast-1.amazonaws.com/"
export const s3Folder = "twitter-images/"
export const googleClientId = "192664173359-tq01enpma86qnsu6kbic3kebh2e32vje.apps.googleusercontent.com"