import React, { Component } from 'react';
import {
    Button,
    Form,
    Row,
    Col
} from "reactstrap";
import { apiurl, s3Folder, s3HostURL } from '../services/common';
import { Link } from "react-router-dom";
import ColInput from './Form/Form';
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceHolderImage from "../assets/img/icons/placeholder.jpg"
import { postData } from '../services/fetch';
import moment from 'moment';

export default class TwitterCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newImage: ""
        }
    }

    changeContent = (e) => {
        e.preventDefault();
        this.props.changeContent(e);
    }

    addImage = (e) => {
        e.preventDefault();
        if (this.props.isThread) {
            this.props.addImage(this.props.threadNumber, this.state.newImage);
        } else {
            this.props.addImage(this.state.newImage);
        }

    }

    removeImage = (e, i) => {
        e.preventDefault();
        if (this.props.isThread) {
            this.props.removeImage(this.props.threadNumber, i);
        } else {
            this.props.removeImage(i);
        }
    }

    uploadImage = async (e) => {
        var base64image = await this.convertBase64(e.target.files[0]);
        console.log(base64image);
        var type = base64image.startsWith("data:image") ? 0 : 1
        if (this.props.isThread) {
            this.props.addImage(this.props.threadNumber, base64image, type);
        } else {
            this.props.addImage(base64image, type);
        }
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }


    render() {
        var content = this.props.content;
        if (this.props.mentions != null && this.props.mentions.length > 0) {
            this.props.mentions.map((mention, i) => {
                content = content.replace("@" + mention.username, "<a href=https://twitter.com/" + mention.username + " target=\"_blank\">@" + mention.username + "</a>")
            })

        }
        if (this.props.urls != null && this.props.urls.length > 0) {
            this.props.urls.map((url, i) => {
                if (url.media_key != null) {
                    content = content.replace(url.url, "");
                } else {
                    if (this.props.images != null && this.props.images.length > 0) {
                        content = content.replace(url.url, "<a href=" + url.url + " target=\"_blank\">" + url.display_url + "</a>")
                    } else {
                        if (url.end == this.props.content.length) {
                            content = content.replace(url.url, "");
                        } else {
                            content = content.replace(url.url, "<a href=" + url.url + " target=\"_blank\">" + url.display_url + "</a>")
                        }
                    }
                }
            })
        }
        return (
            <div className="container-fluid" style={this.props.isError ? { borderStyle: 'solid', borderColor: 'red', padding: 10, borderRadius: 20 } : {}}>
                <Row>
                    <Col md={1}>
                        {this.props.profileURL ? <img src={this.props.profileURL.startsWith("https") ? this.props.profileURL : s3HostURL + "twitter-profile-images/" + this.props.profileURL} width="50" height="50" style={{ borderRadius: 50 }} /> : <></>}

                    </Col>
                    <Col md={11}><b>{this.props.username}</b></Col>
                </Row>
                <Row>
                    <Col md={1}></Col>
                    {
                        this.props.editable ? <>
                            <ColInput
                                col={11}
                                value={content}
                                id={'Title'}
                                type={'textarea'}
                                height={200}
                                rows={5}
                                onChange={(e) => this.changeContent(e)}
                            />
                        </> :

                            <Col md={11} style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: content }}></Col>
                    }

                </Row>
                <Row>
                    {this.props.images != null ? this.props.images.map((image, i) =>
                        <>
                            <br />
                            <div style={{ width: 450, borderStyle: 'solid', borderRadius: 40, borderWidth: 'thin' }} align='center'>
                                <Row><Col md={10}></Col><Col md={2}><btn className='btn' onClick={(e) => this.removeImage(e, i)}>x</btn></Col></Row>
                                {image.type == 0 ? image.imageURL.startsWith("https") || image.imageURL.startsWith("data:image") ? <Col><img style={{ maxWidth: 400 }} src={image.imageURL} /></Col>
                                    : <Col><img style={{ maxWidth: 400 }} src={s3HostURL + s3Folder + image.imageURL} /></Col>
                                    : image.imageURL.startsWith("https") || image.imageURL.startsWith("data:video") ? <Col><video style={{ maxWidth: 400 }} controls={true} src={image.imageURL}></video></Col>
                                        : <Col><video style={{ maxWidth: 400 }} controls={true} src={s3HostURL + s3Folder + image.imageURL} /></Col>}
                                <br />
                            </div>
                        </>

                    ) : <></>}

                </Row>
                <br />
                <div style={{ padding: 40, borderStyle: 'solid', borderWidth: 'thin', borderRadius: 20, borderColor: 'grey' }}>
                    <Row>
                        <Col md={6}>
                            <Row>
                                <input type='text' className='form-control' style={{ width: 300 }} placeholder="Image URL" value={this.state.newImage} onChange={(e) => this.setState({ newImage: e.target.value })} />
                            </Row>
                            <br />
                            <Row>
                                <button type='cancel' className='btn btn-primary' onClick={(e) => this.addImage(e)}>Add Photo</button>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <input type='file' className='btn btn-priamry' text="Upload Photo" onChange={(e) => this.uploadImage(e)} />
                        </Col>
                    </Row>

                </div>

                <br />
            </div>
        )
    }
}

export class TwitterCardDetail extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var content = this.props.content;
        if (this.props.mentions != null && this.props.mentions.length > 0) {
            this.props.mentions.map((mention, i) => {
                content = content.replace("@" + mention.username, "<a href=https://twitter.com/" + mention.username + " target=\"_blank\">@" + mention.username + "</a>")
            })

        }
        if (this.props.urls != null && this.props.urls.length > 0) {
            this.props.urls.map((url, i) => {
                if (url.media_key != null) {
                    content = content.replace(url.url, "");
                } else {
                    if (this.props.images != null && this.props.images.length > 0) {
                        content = content.replace(url.url, "<a href=" + url.url + " target=\"_blank\">" + url.display_url + "</a>")
                    } else {
                        if (url.end == this.props.content.length) {
                            content = content.replace(url.url, "");
                        } else {
                            content = content.replace(url.url, "<a href=" + url.url + " target=\"_blank\">" + url.display_url + "</a>")
                        }
                    }
                }
            })
        }
        return (
            <div className='container'>
                <div style={{ borderRadius: 10, backgroundColor: 'white' }}  >
                    <p style={{ whiteSpace: 'pre-line', color: 'black' }} dangerouslySetInnerHTML={{ __html: content }}></p>
                    <br />
                    <Row>
                        {this.props.images != null ? this.props.images.map((image, i) =>
                            <>
                                <br />
                                {image.type == 0 ? image.imageURL.startsWith("https") ? <Col><LazyLoadImage style={{ maxWidth: '100%' }} src={image.imageURL} alt="Image Alt" placeholderSrc={PlaceHolderImage} /></Col>
                                    : <Col><LazyLoadImage style={{ maxWidth: '100%' }} src={s3HostURL + s3Folder + image.imageURL} alt="Image Alt" placeholderSrc={PlaceHolderImage} /></Col>
                                    : image.imageURL.startsWith("https") ? <Col><video style={{ maxWidth: '100%' }} src={image.imageURL} alt="Image Alt" controls={true} /></Col>
                                        : <Col><video style={{ maxWidth: '100%' }} src={s3HostURL + s3Folder + image.imageURL} alt="Image Alt" controls={true} autoPlay={true} /></Col>}

                            </>

                        ) : <></>}
                    </Row>
                    {/* <br /><br />
                    {
                        post.postThreads.map((item, i) =>
                            <p>{item.content}</p>

                        )
                    } */}
                </div>
            </div>
        )
    }
}

export class TwitterCardIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewCount: props.viewCount
        }
    }

    viewPost = async () => {
        window.open(this.props.contentSourceLink.replace(this.props.username, this.props.contentCreator));
        var viewedPostIdsString = localStorage.getItem("viewedPostIds")
        var viewedPostIds = [];
        var isToIncreaseCount = true;
        if (viewedPostIdsString != null && viewedPostIdsString != undefined) {
            viewedPostIds = JSON.parse(viewedPostIdsString);
            var post = viewedPostIds.find(x => x.id == this.props.id);
            if (post != null & post != undefined) {
                var now = new Date();
                if (now.getTime() > post.expiry) {

                } else {
                    isToIncreaseCount = false;
                }
            }
        }
        if (isToIncreaseCount) {
            var currentDate = new Date();
            var expiryDate = new Date();
            expiryDate.setHours(currentDate.getHours + 8);
            var postObj = { id: this.props.id, expiry: currentDate.getTime()+28800000 }
            viewedPostIds.push(postObj);
            localStorage.setItem("viewedPostIds", JSON.stringify(viewedPostIds));

            var url = '/api/Post/UpdateViewCount/' + this.props.id
            var response = await postData(url);
            if (response.ok) {
                this.state.viewCount++;
                this.setState({ viewCount: this.state.viewCount });
            }
        }

    }

    render() {
        var content = this.props.content;
        if (this.props.mentions != null && this.props.mentions.length > 0) {
            this.props.mentions.map((mention, i) => {
                content = content.replace("@" + mention.username, "<a href=https://twitter.com/" + mention.username + " target=\"_blank\">@" + mention.username + "</a>")
            })

        }
        if (this.props.urls != undefined && this.props.urls != null && this.props.urls.length > 0) {
            this.props.urls.map((url, i) => {
                content = content.replace(url.url, "");
            })
        }
        var contentSplits = content.split(" ");
        // console.log(contentSplits)
        contentSplits.map((split, index) => {
            if (split.startsWith("https")) {
                content = content.replace(split, "");
            }
        })
        return (
            <div className="container-fluid" style={{ borderStyle: "solid", borderColor: "white", borderWidth: 0.5, borderRadius: 20, padding: 10, backgroundColor: 'white', color: 'black', opacity:this.props.status==1 || this.props.status==3?0.5:1 }}>
                <Link to={{ pathname: "/User/twitterUser", search: "?id=" + this.props.contentCreator }}>
                    <div className='row' style={{ cursor: 'pointer' }}>
                        <div className='col-md-3 col-2'>
                            <img src={this.props.profileURL.startsWith("https") ? this.props.profileURL : s3HostURL + "twitter-profile-images/" + this.props.profileURL} width="50" height="50" style={{ borderRadius: 50 }} />
                        </div>
                        <div className='col-md-9 col-9' style={{ paddingTop: 15 }}><b style={{ color: 'black' }}>{this.props.username.length > 10 ? "@" + this.props.username.slice(0, 10) + "..." : "@" + this.props.username}</b></div>
                    </div>
                </Link>

                <br />
                <div style={{ cursor: 'pointer' }} onClick={() => this.viewPost()}>
                    {/* <a href={this.props.contentSourceLink.replace(this.props.username, this.props.contentCreator)} target='_blank'> */}
                    <Row>
                        <Col md={12} style={{ whiteSpace: 'pre-line', color: 'black' }} dangerouslySetInnerHTML={{ __html: content }}></Col>
                    </Row>
                    <Row>
                        {this.props.images != null ? this.props.images.map((image, i) =>
                            i == 0 ?
                                <>
                                    <br />
                                    {image.type == 0 ? image.imageURL.startsWith("https") || image.imageURL.startsWith("data:image") ? <Col><img style={{ maxWidth: '100%' }} src={image.imageURL} /></Col>
                                        : <Col><img style={{ maxWidth: '100%' }} src={s3HostURL + s3Folder + image.imageURL} /></Col>
                                        : image.imageURL.startsWith("https") || image.imageURL.startsWith("data:video") ? <Col><video style={{ maxWidth: '100%' }} controls={true} src={image.imageURL}></video></Col>
                                            : <Col><video style={{ maxWidth: '100%' }} controls={true} src={s3HostURL + s3Folder + image.imageURL} /></Col>}

                                </> : <></>



                        ) : <></>}
                    </Row>
                    <br />
                    <Row>
                        {/* <Col md={5}></Col> */}
                        <Col md={12} style={{ fontSize: 12 }} align='left'>
                            <i className='fa fa-edit'></i> {moment(this.props.contentCreatedDate).startOf('minute').fromNow() }
                        </Col>
                    </Row>
                    {this.props.isBookMarked ?
                        <Row>
                            {/* <Col md={5}></Col> */}
                            <Col md={12} style={{ fontSize: 12 }} align='left'>
                                <i className='fa fa-bookmark-o'></i> {moment(this.props.bookmarkDate).startOf('minute').fromNow()}
                            </Col>
                        </Row> :
                        <></>}
                    <br />
                    <Row>
                        <Col md={12}>
                            <div className='container-fluid'>
                                <div style={{ color: 'black', fontSize: 11, fontWeight: 'bold', paddingTop: 5 }} className='row'>
                                    {this.props.categories.map((category, index) =>
                                        <span style={{ marginRight: 5, marginBottom: 5 }}><div style={{ borderStyle: 'solid', borderColor: 'grey', borderRadius: 10, borderWidth: 0.8, padding: 4, display: 'inline-block' }}>{category}</div></span>
                                    )}
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
                {/* </a> */}

                <div className='row'>
                    <div className='col-lg-9 col-md-9 col-sm-4 col-9'>
                        <span style={{ fontSize: 14, padding: 7, fontWeight: 'bold' }}>
                            <i class="fa fa-eye" aria-hidden="true"></i> {this.state.viewCount}
                        </span>
                        <span style={{ fontSize: 14, padding: 7, fontWeight: 'bold' }}>
                            <i class="fa fa-bookmark" aria-hidden="true"></i> {this.props.bookmarkCount}
                        </span>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-5 col-3'>
                        <span style={{ fontSize: 17, padding: 7, fontWeight: 'bold', cursor: 'pointer' }} onClick={this.props.isBookMarked ? () => this.props.unbookmark() : () => this.props.bookmark()}>
                            {this.props.isBookMarked ? <i class="fa fa-bookmark" aria-hidden="true"></i> : <i class="fa fa-bookmark-o" aria-hidden="true"></i>}
                        </span>
                    </div>

                </div>
                {/* <br />
                <Row>
                    <Col>
                        <b>Total View : {this.props.viewCount}</b>
                    </Col>
                </Row> */}
            </div>
        )
    }
}

export class QuoteCardIndex extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var content = this.props.content;
        if (this.props.mentions != null && this.props.mentions.length > 0) {
            this.props.mentions.map((mention, i) => {
                content = content.replace("@" + mention.username, "<a href=https://twitter.com/" + mention.username + " target=\"_blank\">@" + mention.username + "</a>")
            })

        }
        if (this.props.urls != null && this.props.urls.length > 0) {
            this.props.urls.map((url, i) => {
                if (url.media_key != null) {
                    content = content.replace(url.url, "");
                } else {
                    if (this.props.images != null && this.props.images.length > 0) {
                        content = content.replace(url.url, "<a href=" + url.url + " target=\"_blank\">" + url.display_url + "</a>")
                    } else {
                        if (url.end == this.props.content.length) {
                            content = content.replace(url.url, "");
                        } else {
                            content = content.replace(url.url, "<a href=" + url.url + " target=\"_blank\">" + url.display_url + "</a>")
                        }
                    }
                }
            })
        }
        return (
            <div className="container-fluid" style={{ borderStyle: "solid", borderColor: "white", borderWidth: 0.5, borderRadius: 20, padding: 10, backgroundColor: 'white', color: 'black', fontStyle: 'Oswald', paddingLeft: 17 }}>
                <Row>
                    <Col md={12} style={{ whiteSpace: 'pre-line', color: 'black' }} dangerouslySetInnerHTML={{ __html: content }} align='left'></Col>
                </Row>
                <br />
                <Row>
                    {this.props.images != null ? this.props.images.map((image, i) =>
                        i == 0 ?
                            <>
                                <br />
                                {image.imageURL.startsWith("https") ? <Col><img style={{ maxWidth: '100%' }} src={image.imageURL} /></Col>
                                    : <Col><img style={{ maxWidth: '100%' }} src={s3HostURL + s3Folder + image.imageURL} /></Col>}

                            </> : <></>



                    ) : <></>}
                </Row>
                <br />
                <Row>
                    {
                        this.props.images != null && this.props.images.length > 0 ? <></> : this.props.urLs != null ? JSON.parse(this.props.urLs).map((url, i) =>
                            <a href={url.url} target="_blank">
                                <div className='container-fluid'>
                                    <EmbeddedCard isMobile={this.props.isMobile} displayURL={url.display_url} title={url.title} description={url.description} image={url.images != null ? url.images[0].url : null} ></EmbeddedCard>
                                </div></a>

                        ) : <></>
                    }
                </Row>
                <Link to={{ pathname: "/User/twitterUser", search: "?id=" + this.props.contentCreator }}>
                    <div className='row' style={{ cursor: 'pointer' }}>
                        <div className='col-lg-4 col-2' style={{ height: '100%,', verticalAlign: 'middle' }}>
                            <img src={this.props.profileURL.startsWith("https") ? this.props.profileURL : s3HostURL + "twitter-profile-images/" + this.props.profileURL} width="70" height="70" style={{ borderRadius: 50, display: 'inline-block', verticalAlign: 'middle' }} />
                        </div>
                        <div className='col-lg-8' style={{ paddingTop: 15 }}>

                            <b style={{ color: 'black' }}>{this.props.username.length > 10 ? "@" + this.props.username.slice(0, 10) + "..." : "@" + this.props.username}</b><br /><br />
                            <div style={{ color: 'black', fontSize: 11, fontWeight: 'bold', paddingTop: 5 }} className='row'>
                                {this.props.categories.map((category, index) =>
                                    <span style={{ marginLeft: 15, marginBottom: 5 }}><div style={{ borderStyle: 'solid', borderColor: 'grey', borderRadius: 10, borderWidth: 0.8, padding: 4, display: 'inline-block' }}>{category}</div></span>
                                )}
                            </div>
                        </div>


                        <Link to={{ pathname: "/User/Product", search: "?id=" + this.props.id }}>

                        </Link>
                        {/* <div className='col-md-9 col-9'><b style={{ color: 'white' }}>{this.props.name ? this.props.name : this.props.username}</b><br /><p style={{ fontSize: 12, color: 'white' }}>{"@" + this.props.username}</p></div> */}
                    </div>
                </Link>


                {/* <br />
                <Row>
                    <Col>
                        <b>Total View : {this.props.viewCount}</b>
                    </Col>
                </Row> */}
            </div>
        )
    }
}

export class TweetCardIndex extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var content = this.props.content;
        if (this.props.mentions != null && this.props.mentions.length > 0) {
            this.props.mentions.map((mention, i) => {
                content = content.replace("@" + mention.username, "<a href=https://twitter.com/" + mention.username + " target=\"_blank\">@" + mention.username + "</a>")
            })

        }
        return (
            <div className="container-fluid" style={{ borderStyle: "solid", borderColor: "white", borderWidth: 0.5, borderRadius: 20, padding: 10, backgroundColor: 'white', color: 'black' }}>
                <Link to={{ pathname: "/user/twitterUser", search: "?id=" + this.props.contentCreator }}>
                    <div className='row' style={{ cursor: 'pointer' }}>
                        <div className='col-md-3 col-2'>
                            <img src={this.props.profileURL.startsWith("https") ? this.props.profileURL : s3HostURL + "twitter-profile-images/" + this.props.profileURL} width="50" height="50" style={{ borderRadius: 50 }} />
                        </div>
                        <div className='col-md-9 col-9' style={{ paddingTop: 15 }}><b style={{ color: 'black' }}>{this.props.username.length > 10 ? "@" + this.props.username.slice(0, 10) + "..." : "@" + this.props.username}</b></div>
                    </div>
                </Link>

                <br />
                <Link to={{ pathname: "/user/Post", search: "?id=" + this.props.id }}>
                    <Row>
                        <Col md={12} style={{ whiteSpace: 'pre-line', color: 'black' }} dangerouslySetInnerHTML={{ __html: content }}></Col>
                    </Row>
                    <Row>
                        {this.props.images != null ? this.props.images.map((image, i) =>
                            i == 0 ?
                                <>
                                    <br />
                                    {image.imageURL.startsWith("https") ? <Col><img style={{ maxWidth: '100%' }} src={image.imageURL} /></Col>
                                        : <Col><img style={{ maxWidth: '100%' }} src={s3HostURL + s3Folder + image.imageURL} /></Col>}

                                </> : <></>



                        ) : <></>}
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className='container-fluid'>
                                <div style={{ color: 'black', fontSize: 11, fontWeight: 'bold', paddingTop: 5 }} className='row'>
                                    {this.props.categories.map((category, index) =>
                                        <span style={{ marginRight: 5, marginBottom: 5 }}><div style={{ borderStyle: 'solid', borderColor: 'grey', borderRadius: 10, borderWidth: 0.8, padding: 4, display: 'inline-block' }}>{category}</div></span>
                                    )}
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Link>

                <div className='row'>
                    <div className='col-lg-9 col-md-9 col-sm-4 col-9'>
                        <span style={{ fontSize: 14, padding: 7, fontWeight: 'bold' }}>
                            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i> {this.props.likeCount}
                        </span>
                        <span style={{ fontSize: 14, padding: 7, fontWeight: 'bold' }}>
                            <i class="fa fa-commenting-o" aria-hidden="true"></i> {this.props.commentCount}
                        </span>
                        <span style={{ fontSize: 14, padding: 7, fontWeight: 'bold' }}>
                            <i class="fa fa-eye" aria-hidden="true"></i> {this.props.viewCount}
                        </span>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-5 col-3'>
                        <span style={{ fontSize: 17, padding: 7, fontWeight: 'bold', cursor: 'pointer' }} onClick={this.props.isBookMarked ? () => console.log("") : () => this.props.bookmark()}>
                            {this.props.isBookMarked ? <i class="fa fa-bookmark" aria-hidden="true"></i> : <i class="fa fa-bookmark-o" aria-hidden="true"></i>}
                        </span>
                    </div>

                </div>
                {/* <br />
                <Row>
                    <Col>
                        <b>Total View : {this.props.viewCount}</b>
                    </Col>
                </Row> */}
            </div>
        )
    }
}


export class CommentCard extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-md-3'>
                        <img src={this.props.profileURL.startsWith("https") ? this.props.profileURL : s3HostURL + "twitter-profile-images/" + this.props.profileURL} width="50" height="50" style={{ borderRadius: 50 }} />
                    </div>
                    <div className='col-md-9'><b style={{ color: 'black' }}>{this.props.username}</b><br /><p style={{ fontSize: 12, color: 'black' }}>{"@" + this.props.username}</p></div>
                </div>
                <br />
                <Row>
                    <Col md={3}></Col>
                    <Col md={9}>{this.props.comment}</Col>
                </Row>

            </div>
        )
    }
}

export class TagCard extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            this.props.selected ?
                <div style={{ borderRadius: 10, borderStyle: 'solid', borderWidth: 0.8, borderColor: 'grey', padding: 5, marginRight: 5 }}>
                    <Row>
                        <Col md={2}><img src={this.props.icon.startsWith("https") ? this.props.icon : s3HostURL + "category-images/" + this.props.icon} width="30" height="30" /></Col>
                        <Col style={{ marginTop: 3, marginLeft: 5 }}>{this.props.tag}</Col>
                    </Row>
                </div> :
                <div style={{ padding: 5, marginRight: 5 }}>
                    <Row>
                        <Col md={2}><img src={this.props.icon.startsWith("https") ? this.props.icon : s3HostURL + "category-images/" + this.props.icon} width="30" height="30" /></Col>
                        <Col style={{ marginTop: 3, marginLeft: 5 }}><b>{this.props.tag}</b></Col>
                    </Row>
                </div>
        )
    }
}

export class TagCardSmall extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            this.props.selected ?
                <div style={{ borderRadius: 10, borderStyle: 'solid', borderWidth: 0.8, borderColor: 'grey', padding: 5, paddingLeft: 20, paddingRight: 20, marginRight: 5, height: 35 }}>
                    <Row>
                        {this.props.icon != undefined && this.props.icon != null && this.props.icon != "" ? <div style={{ width: 18 }}><img src={this.props.icon.startsWith("https") ? this.props.icon : s3HostURL + "category-images/" + this.props.icon} width="15" height="15" /></div> : <></>}

                        <div><p style={{ fontSize: 14 }}>{this.props.tag}</p></div>
                    </Row>
                </div> :
                <div style={{ borderRadius: 10, borderWidth: 0.8, borderColor: 'grey', padding: 5, paddingLeft: 20, paddingRight: 20, marginRight: 5, height: 35 }}>
                    <div className='row'>
                        {this.props.icon != undefined && this.props.icon != null && this.props.icon != "" ? <div style={{ width: 18 }}><img src={this.props.icon.startsWith("https") ? this.props.icon : s3HostURL + "category-images/" + this.props.icon} width="15" height="15" /></div> : <></>}
                        <div><p style={{ fontSize: 14 }}>{this.props.tag}</p></div>
                    </div>
                </div>
        )
    }
}

export class FolderCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<>
            <div className="col-md-2"><i style={{ fontSize: 20 }} className="fa fa-folder"></i><br /><b>{this.props.name}</b></div></>)
    }
}

export class EmbeddedCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<>
            <div style={{ borderStyle: 'solid', borderWidth: 0.5, borderColor: '#C5C5C5', borderRadius: 10 }}>
                {
                    this.props.image != null ? <img src={this.props.image.startsWith("https") ? this.props.image : s3HostURL + s3Folder + this.props.image} style={{ maxWidth: '100%', borderTopLeftRadius: 10, borderTopRightRadius: 10 }} /> : <></>
                }

                <p style={{ color: 'grey', margin: 10, wordWrap: 'break-word' }}>{this.props.displayURL}</p>
                <p style={{ color: 'black', margin: 10 }}>{this.props.title}</p>
                <p style={{ color: 'grey', margin: 10 }}>{this.props.description}</p>
            </div>
        </>)
    }
}

export class OFontSizeChangeCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sizeChanged: false
        }
    }
    render() {
        return (<>
            <b style={{ fontSize: this.state.sizeChanged ? 70 : 50, color: 'black', textTransform: this.state.sizeChanged ? 'uppercase' : 'lowercase', transition: "1s" }} className="title-font" onMouseEnter={() => this.setState({ sizeChanged: true })} onMouseLeave={() => this.setState({ sizeChanged: false })} >o</b>
        </>
        )
    }
}

export class RFontSizeChangeCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sizeChanged: false
        }
    }
    render() {
        return (<>
            <b style={{ fontSize: this.state.sizeChanged ? 70 : 50, color: 'black', textTransform: this.state.sizeChanged ? 'uppercase' : 'lowercase', transition: "1s" }} className="title-font" onMouseEnter={() => this.setState({ sizeChanged: true })} onMouseLeave={() => this.setState({ sizeChanged: false })}>r</b>
        </>
        )
    }
}

export class EmbeddedCardAddForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayURL: '',
            url: '',
            title: '',
            description: '',
            imageURL: ''
        }

    }

    addEmbeddedURL = (e) => {
        e.preventDefault();
        var url = { display_url: this.state.displayURL, url: this.state.url, title: this.state.title, description: this.state.description, images: [{ url: this.state.imageURL }] };
        if (this.props.isThread) {
            this.props.addEmbeddedURL(this.props.threadNumber, url);
        } else {
            this.props.addEmbeddedURL(url);
        }
    }

    render() {
        return (
            <div style={{ padding: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: 20, borderColor: 'grey' }}>
                <div className='row'>
                    <div className='col-md-4'><input type='text' placeholder='Display URL' className='form-control' value={this.state.displayURL} onChange={(e) => this.setState({ displayURL: e.target.value })} /></div>
                    <div className='col-md-4'><input type='text' placeholder='URL' className='form-control' value={this.state.url} onChange={(e) => this.setState({ url: e.target.value })} /></div>
                    <div className='col-md-4'><input type='text' placeholder='Title' className='form-control' value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} /></div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-md-4'><input type='text' placeholder='Description' className='form-control' value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} /></div>
                    <div className='col-md-4'><input type='text' placeholder='Image URL' className='form-control' value={this.state.imageURL} onChange={(e) => this.setState({ imageURL: e.target.value })} /></div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-md-4'><button className='btn btn-primary' onClick={(e) => this.addEmbeddedURL(e)}>Add Embedded URL</button></div>
                </div>
            </div>
        )
    }
}

export class ProfileCardIndex extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="container-fluid profile-box-shadow" style={{ borderStyle: "solid", borderRadius: 10, borderColor: "white", borderWidth: 0.5, backgroundColor: 'white', color: 'black', marginBottom: 20 }}>
                    <div className='row'>
                        <div className='col-md-4' style={{ paddingTop: 10 }}>
                            <img width={80} height={80} src={this.props.profileURL.startsWith("https") ? this.props.profileURL : s3HostURL + "twitter-profile-images/" + this.props.profileURL} style={{ borderRadius: 50 }} />
                            <br />
                            <a align='center' style={{ fontSize: 10 }} href={'https://twitter.com/' + this.props.username} target="_blank">View On Twitter <i className='fa fa-link'></i></a>
                        </div>
                        <div className='col-md-8'>
                            <br />
                            <h3>{"@" + this.props.username}</h3>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <div className='row' style={{ padding: 10 }}>
                                        {
                                            this.props.categories ? this.props.categories.map((category, i) =>
                                                <span style={{ marginLeft: 15, marginBottom: 5 }}><div style={{ borderStyle: 'solid', borderColor: 'grey', borderRadius: 10, borderWidth: 0.8, padding: 4, display: 'inline-block', fontSize: 9 }}>{category.category.name}</div></span>
                                            ) : <></>
                                        }

                                    </div>
                                </div>
                                <div className='col-md-4'>

                                </div>

                            </div>

                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-md-3'>
                            <h4 align='center'>{this.props.threadCount}</h4>
                            <p align='center' style={{ fontSize: 13 }}>Threads</p>
                        </div>
                        <div className='col-md-3'>
                            <h4 align='center'>{this.props.quoteCount}</h4>
                            <p align='center' style={{ fontSize: 13 }}>Quotes</p>
                        </div>
                        <div className='col-md-3'>
                            <h4 align='center'>{this.props.viewCount}</h4>
                            <p align='center' style={{ fontSize: 13 }}>Views</p>
                        </div>
                        <div className='col-md-3'>
                            <h4 align='center'>{this.props.bookmarkCount}</h4>
                            <p align='center' style={{ fontSize: 13 }}>Bookmarks</p>
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-md-12' align='center'>
                            <a className='btn btn-default' href={'/user/twitterUser?id=' + this.props.userId}>View Profile</a>
                        </div>
                        <div className='col-md-6'>

                        </div>
                        <br />
                    </div>
                </div>
            </>
        )
    }
}