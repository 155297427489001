import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardTitle,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";
import {
  Redirect,
} from "react-router-dom";
import Header from "components/Headers/Header.jsx";
import { getToken, isInRole, getRole } from "../services/storage";
import { defaultModel } from "../components/DefaultModel";
import { getData } from "../services/fetch";
import ColInput from "../components/Form/Form";
import exportExcel, { excelType } from "../services/excel";
import { getDateForInput } from "../services/date";

class Index extends React.Component {
  state = {
    activeNav: 1,
    saleValueChartData: "data1",
    redirectToLogin: false,
    redirectToStaff :false,
    chartExample1: chartExample1,
    chartExample2: chartExample2,
    data: defaultModel.dashboard,
    financeStartDate: getDateForInput(new Date()),
    financeEndDate: getDateForInput(new Date()),
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      saleValueChartData:
        this.state.saleValueChartData === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  async componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    var token = await getToken();
    if (!token) {
      this.setState({ redirectToLogin: true });
    }
  }


  async componentDidMount() {
    var role = getRole()[0]
    if(role=="staff"){
      this.setState({redirectToStaff:true})
    }
    var response = await getData('/api/Dashboard');
    if (response.ok) {
      this.setState({ data: response.data });
    }
  }


  render() {
    const thisprops = this;
    if (this.state.redirectToLogin) {
      return <Redirect to={{
        pathname: "/auth/login",
        state: { from: this.props.location }
      }} />
    }
    if(this.state.redirectToStaff){
      return <Redirect to={{
        pathname: "/admin/orders",
        state: { from: this.props.location }
      }} />
    }
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-8 bg-gradient-info">
          {/* <Container fluid>
            <div className="header-body">
              {
                isInRole('admin') ? (
                  <>
                    <Row>
                      <Col lg="6" xl="3">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Daily Sale
                          </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {this.state.data.dailySale}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                  <i className="fas fa-chart-bar" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="6" xl="3">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Monthly Sale
                          </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {this.state.data.monthlySale}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                  <i className="fas fa-chart-pie" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="6" xl="3">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Daily Expense
                          </CardTitle>
                                <span className="h2 font-weight-bold mb-0">{this.state.data.dailyExpense}</span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                  <i className="fas fa-money-check-alt" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="6" xl="3">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Monthly Expense
                          </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {this.state.data.monthlyExpense}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fas fa-percent" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg="6" xl="3">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Daily Profit
                          </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {this.state.data.dailyProfit}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                  <i className="fas fa-chart-pie" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="6" xl="3">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Monthly Profit
                          </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {this.state.data.monthlyProfit}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                  <i className="fas fa-chart-pie" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="6" xl="3">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Total Profit
                          </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {this.state.data.totalProfit}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                  <i className="fas fa-chart-pie" />
                                </div>
                              </Col>
                            </Row>

                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <ColInput
                        col={3}
                        label={'Start Date'}
                        labelStyle={labelStyle}
                        defaultValue={this.state.financeStartDate}
                        value={this.state.financeStartDate}
                        id={'startdate'}
                        type={'date'}
                        onChange={e => this.setState({ financeStartDate: e.target.value })}
                      />
                      <ColInput
                        col={3}
                        label={'End Date'}
                        labelStyle={labelStyle}
                        defaultValue={this.state.financeEndDate}
                        value={this.state.financeEndDate}
                        id={'enddate'}
                        type={'date'}
                        onChange={e => this.setState({ financeEndDate: e.target.value })}
                      />
                      <Col lg={3}>
                        <label style={labelStyle} className="form-control-label" for="">Download Finance Report</label><br />
                        <a className="btn" style={{ background: 'white' }}
                          onClick={() => {
                            exportExcel(excelType.finance, { startdate: this.state.financeStartDate, enddate: this.state.financeEndDate })
                          }}>
                          <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true">save_alt</span>
                        </a>
                      </Col>
                    </Row>
                  </>
                ) : (<></>)
              }
            </div>
          </Container> */}
        </div>

        {/* Page content */}
        {/* <Container className="mt--7" fluid>
          <Row>
            {
              isInRole('admin') ? (
                <Col className="mb-5 mb-xl-0" xl="8">
                  <Card className="bg-gradient-default shadow">
                    <CardHeader className="bg-transparent">
                      <Row className="align-items-center">
                        <div className="col">
                          <h6 className="text-uppercase text-light ls-1 mb-1">
                            Overview
                      </h6>
                          <h2 className="text-white mb-0">Sales value</h2>
                        </div>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div className="chart">
                        <Line
                          data={this.state.data.salesValue}
                          options={chartExample1.options}
                          getDatasetAtEvent={e => console.log(e)}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ) : (<></>)
            }
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Total orders</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={this.state.data.totalOrder}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container> */}
      </>
    );
  }
}

export default Index;

const labelStyle = {
  background: 'white',
  padding: 5,
  borderRadius: 2
}
